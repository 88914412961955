

.TGiftPage .GiftItem {
    border-bottom: 10px solid #F8F8F8;
    position: relative;
}

.TGiftPage .GiftItem::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    display: block;
    width: 100%;
    height: 4px;
    background-color: #EDEDED;
}

.TGiftPage .GiftItem .PageTit2 {
    font-weight: var(--Semibold);
    color: var(--ColorTypeB);
    font-size: 28px;
}

.TGiftPage .GiftItem .PageTit2 img {
    margin-right: 0;
    margin-left: 0.75rem;
    width: 28px;
}

.TGiftPage .GiftItem .row {
    gap: 0 30px
}

.TGiftPage .GiftItem .row .prd-info {
    font-size: 22px;
    margin-bottom: 0;
    color: var(--ColorTypeC);
    font-weight: var(--Medium);
}

.TGiftPage .GiftItem .row .prd-sub {
    font-size: 22px;
    font-weight: var(--Medium);
    color: var(--ColorTypeB);
    margin-bottom: 0.5rem;
    text-align: right;
    word-break: break-word;
}

.TGiftPage .GiftBox .infobox {
    background-color: #F7F7F7;
    padding: 2rem;
    margin-bottom: 3rem;
}

.TGiftPage .GiftBox .infobox .tit {
    font-size: 24px;
    font-weight: var(--Medium);
    color: var(--ColorTypeC);
    display: flex;
    align-items: center;
    gap: 0 0.5rem;
    margin-bottom: 0.75rem;
}

.TGiftPage .GiftBox .infobox .tit img {
    width: 24px;
}

.TGiftPage .GiftBox .infobox .info-txt {
    font-size: 18px;
    color: var(--ColorTypeC);
    text-indent: -11px;
    padding-left: 11px;
    word-break: keep-all;
    margin-bottom: 0.5rem;
}

.TGiftPage .GiftBox .recipient {
    margin-bottom: 4rem;
}

.TGiftPage .GiftBox .recipient .tit {
    font-size: 22px;
    color: var(--ColorTypeB);
    font-weight: var(--Medium);
}

.TGiftPage .GiftBox .recipient .Gift-Inputbox .form-control {
    padding: 1.5rem;
    height: 65px;
    font-size: 17px;
}

.demo .TGiftPage .Gift-Confirm .btn {
    height: 70px;
    font-size: 22px;
}

.demo .TGiftPage .Gift-Confirm .btn.btn-default:focus {
    background-color:var(--ColorTypeB);
    color: #fff;
    border-color: var(--ColorTypeB);
    box-shadow: none;
}

/* 모바일 반응형 */

@media screen and (max-width: 599px) {

    .demo .TGiftPage .GiftBox.px-4-5 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important;
    }

    .demo .TGiftPage .GiftItem.px-4-5 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important;
    }   

    .TGiftPage .GiftItem .PageTit2 {
        font-size: 20px;
    }

    .TGiftPage .GiftItem .PageTit2 img {
        margin-left: 0.5rem;
        width: 17px;
    }

    .TGiftPage .GiftItem .row {
        gap: 0 15px
    }

    .TGiftPage .GiftItem .row .prd-info {
        font-size: 16px;
    }

    .TGiftPage .GiftItem .row .prd-sub {
        font-size: 16px;
    }

    .TGiftPage .GiftBox .infobox {
        padding: 1.5rem;
        margin-bottom: 2rem;
    }

    .TGiftPage .GiftBox .infobox .tit {
        font-size: 19px;
        gap: 0 0.4rem;
        margin-bottom: 0.5rem;
    }

    .TGiftPage .GiftBox .infobox .tit img {
        width: 20px;
    }

    .TGiftPage .GiftBox .infobox .info-txt {
        font-size: 14px;
        margin-bottom: 0.3rem;
    }

    .TGiftPage .GiftBox .recipient {
        margin-bottom: 3rem;
    }

    .TGiftPage .GiftBox .recipient .tit {
        font-size: 18px;
        margin-bottom: 0.75rem;
    }
    
    .TGiftPage .GiftBox .recipient .Gift-Inputbox .form-control {
        padding: 1.25rem;
        height: 50px;
        font-size: 14px;
    }
    
    .demo .TGiftPage .Gift-Confirm .btn {
        height: 55px;
        font-size: 16px;
    }

}