.TPrdOrderPage {
  position: relative;
}

.TPrdOrderPage::before {
  content: "";
  width: 100%;
  height: 100%;
  min-height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #f7f7f7;
  z-index: -1;
}

.TPrdOrderPage .PrdOrderBox .PrdOrder-Item {
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
  background-color: #fff;
  padding: 1.25rem 2.5rem;
  border-radius: 1.25rem;
  min-height: 7rem;
  box-shadow: 3px 3px 3px 1px rgb(0 0 0 / 10%);
}

.TPrdOrderPage .PrdOrderBox .PrdOrder-Item .prd-infobox {
  width: 75%;
}

.TPrdOrderPage .PrdOrderBox .PrdOrder-Item .prd-infobox .prd-tit {
  font-weight: var(--Medium);
  color: var(--ColorTypeB);
  font-size: 28px;
  margin-bottom: 0;
  word-break: break-word;
}

.TPrdOrderPage .PrdOrderBox .PrdOrder-Item .prd-infobox .prd-desc {
  font-weight: var(--Regular);
  color: var(--ColorTypeC);
  font-size: 20px;
  margin-bottom: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.TPrdOrderPage .PrdOrderBox .PrdOrder-Item .prd-iconbox {
  width: 40px;
}

.TPrdOrderPage .PrdOrder-Btnbox {
  margin-top: 4rem;
}

.TPrdOrderPage .PrdOrder-Btnbox .btn-default {
  height: 70px;
  font-size: 22px;
}

/* 모바일 반응형 */
@media screen and (max-width: 599px) {
  .demo .TPrdOrderPage .PrdOrderBox.px-4-5 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .TPrdOrderPage .PrdOrderBox .PrdOrder-Item {
    padding: 1.25rem 1.5rem;
    min-height: 6rem;
  }

  .TPrdOrderPage .PrdOrderBox .PrdOrder-Item .prd-infobox {
    width: 80%;
  }

  .TPrdOrderPage .PrdOrderBox .PrdOrder-Item .prd-infobox .prd-tit {
    font-size: 22px;
  }

  .TPrdOrderPage .PrdOrderBox .PrdOrder-Item .prd-infobox .prd-desc {
    font-size: 16px;
  }

  .TPrdOrderPage .PrdOrderBox .PrdOrder-Item .prd-iconbox {
    width: 30px;
  }

  .TPrdOrderPage .PrdOrder-Btnbox .btn-default {
    height: 55px;
    font-size: 16px;
  }
}

.TPrdOrderPage .PrdOrderBox .PrdOrder-Itembox {
  min-height: 60vh;
}

.TPrdOrderPage .PrdOrderBox .PrdOrder-Item {
  padding: 1.5rem 2rem;
  padding-right: 1.5rem;
  border-radius: 0.75rem;
  min-height: auto;
  box-shadow: 1px 2px 1px 1px rgb(0 0 0 / 10%);
  margin-bottom: 1.5rem;
}

.TPrdOrderPage .PrdOrderBox .PrdOrder-Item .prd-infobox {
  width: calc(95% - 40px);
}

.TPrdOrderPage .PrdOrderBox .PrdOrder-Item .prd-iconbox {
  width: 35px;
}

@media screen and (max-width: 599px) {
  .TPrdOrderPage .PrdOrderBox .PrdOrder-Item {
    padding: 1.25rem 1.5rem;
    padding-right: 1rem;
    min-height: auto;
    margin-bottom: 1.25rem;
  }

  .TPrdOrderPage .PrdOrderBox .PrdOrder-Item .prd-iconbox {
    width: 25px;
  }
}
