.TEventManagementPage {
  position: relative;
}

.TEventManagementPage::before {
  content: "";
  width: 100%;
  height: 100%;
  min-height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #f7f7f7;
  z-index: -1;
}

.TEventManagementPage .Event-Top {
  justify-content: space-between;
  align-items: center;
}

.TEventManagementPage .Event-Top .tit {
  font-size: 35px;
  color: var(--ColorTypeB);
  font-weight: var(--Medium);
}

.TEventManagementPage .Event-Top .btnbox {
  display: flex;
  gap: 0 0.75rem;
}

.TEventManagementPage .Event-Top .btnbox.delete {
  display: none;
}

.TEventManagementPage .Event-Top .btnbox .btn {
  white-space: nowrap;
  border-width: 2px;
  border-radius: 10px;
  padding: 0.15rem 0.75rem;
  font-size: 17px;
}

.TEventManagementPage .EventItem {
  width: 100%;
  background-color: #fff;
  margin-bottom: 2rem;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 10%);
  border-radius: 1.25rem;
  overflow: hidden;
}

.TEventManagementPage .EventItem.done {
  background-color: #eeeeee;
}

.TEventManagementPage .EventItem .line {
  width: 20px;
  background-color: #ddd;
}

.TEventManagementPage .EventItem.d-day .line {
  background-color: #5938e1;
}

.TEventManagementPage .EventItem.done .line {
  background-color: #606060;
}

.TEventManagementPage .EventItem.in-progress .line {
  background-color: var(--ColorTypeA);
}

.TEventManagementPage .EventItem .info-box {
  padding: 1.5rem 2rem;
  width: calc(100% - 20px);
  position: relative;
}

.TEventManagementPage .EventItem .info-box .event-name {
  font-size: 25px;
  letter-spacing: -0.75px;
  color: var(--ColorTypeB);
  font-weight: var(--Semibold);
  margin-bottom: 0.5rem;
  width: calc(100% - 70px);
  overflow: hidden;
  word-break: break-word;
}

.TEventManagementPage .EventItem .info-box .event-period {
  font-size: 17px;
  letter-spacing: -0.55px;
  color: #999999;
}

.TEventManagementPage .EventItem .info-box .event-period .mob-320 {
  display: none;
}

.TEventManagementPage .EventItem .info-box .btn-box {
  gap: 0 0.5rem;
}

.TEventManagementPage .EventItem .info-box .btn-box .btn {
  width: auto;
  border-radius: 40px;
  padding: 0.25rem 1rem;
  font-size: 15px;
}

.TEventManagementPage .EventItem .info-box .btn-box .btn.disabled {
  background-color: var(--ColorTypeD);
  color: #fff;
  font-weight: 400;
}

.TEventManagementPage .EventItem .info-box .state-box {
  position: absolute;
  top: 1.5rem;
  right: 2rem;
  font-size: 18px;
  font-weight: var(--Medium);
}

.TEventManagementPage .EventItem.d-day .info-box .state-box {
  color: #5938e1;
}

.TEventManagementPage .EventItem.d-day .info-box .state-box::before {
  content: "";
  background: url(https://www.flexdaycdn.net/public/images/ticket/calendar2.png)
    no-repeat center/100%;
  width: 15px;
  height: 17px;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 0.1rem;
}

.TEventManagementPage .EventItem.in-progress .info-box .state-box {
  color: var(--ColorTypeA);
}

.TEventManagementPage .EventItem.done .info-box .state-box {
  color: var(--ColorTypeC);
}

/* 이벤트 삭제 */

.TEventManagementPage.deleting .Event-Top .btnbox.default {
  display: none;
}

.TEventManagementPage.deleting .Event-Top .btnbox.delete {
  display: flex;
}

.TEventManagementPage.deleting .EventItem.done {
  background-color: #fff;
}

.TEventManagementPage.deleting .EventItem .info-box .state-box {
  display: none;
}

.TEventManagementPage .EventItem .delete-box {
  position: absolute;
  right: 2.5rem;
  top: 50%;
  transform: translateY(-50%);
  display: none;
}

.TEventManagementPage.deleting .EventItem .delete-box {
  display: block;
}

.TEventManagementPage.deleting .EventItem .delete-box .form-check-input {
  background-image: none;
  border: 1px solid var(--ColorTypeB);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  flex: none;
  box-shadow: none;
}

.TEventManagementPage.deleting
  .EventItem.in-progress
  .delete-box
  .form-check-input {
  pointer-events: none;
  border-color: var(--ColorTypeD);
  background-color: #eeeeee;
}

.TEventManagementPage.deleting
  .EventItem
  .delete-box
  .form-check-input:checked {
  background-image: url(https://www.flexdaycdn.net/public/images/ticket/check-black.png);
  background-color: var(--ColorTypeB);
  border-color: var(--ColorTypeB);
}

.demoModal .modal-event-detail .modal-header {
  position: relative;
  padding: 2rem 0 !important;
}

.demoModal .modal-event-detail .modal-header .modal-title {
  font-size: 22px;
  text-align: center;
  color: var(--ColorTypeB);
}

.demoModal .modal-event-detail .modal-header .btn {
  max-width: 20px;
  position: absolute;
  right: 3rem;
  top: 50%;
  transform: translateY(-50%);
}

.demoModal .modal-event-detail .detail-box {
  margin-bottom: 3rem;
}

.demoModal .modal-event-detail .detail-item {
  gap: 0 1rem;
  font-size: 18px;
  margin-bottom: 0.5rem;
}

.demoModal .modal-event-detail .detail-item:last-child {
  margin-bottom: 0;
}

.demoModal .modal-event-detail .detail-item .tit {
  width: 20%;
  color: var(--ColorTypeC);
  font-weight: var(--Medium);
}

.demoModal .modal-event-detail .detail-item .contents {
  width: 80%;
  color: var(--ColorTypeB);
  font-weight: var(--Medium);
  word-break: break-word;
}

/* 모바일 반응형 */
@media screen and (max-width: 599px) {
  .demo .TEventManagementPage .EventManagementBox.px-4-5 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .TEventManagementPage .Event-Top .tit {
    font-size: 27px;
  }

  .TEventManagementPage .Event-Top .btnbox {
    gap: 0 0.5rem;
  }

  .TEventManagementPage .Event-Top .btnbox .btn {
    border-radius: 12px;
    padding: 0.25rem 0.6rem;
  }

  .TEventManagementPage .EventItem {
    border-radius: 1rem;
  }

  .TEventManagementPage .EventItem .line {
    width: 15px;
  }

  .TEventManagementPage .EventItem .info-box {
    padding: 1rem 1.25rem;
  }

  .TEventManagementPage .EventItem .info-box .event-name {
    font-size: 20px;
    width: calc(100% - 60px);
  }

  .TEventManagementPage .EventItem .info-box .event-period {
    font-size: 13px;
  }

  .TEventManagementPage .EventItem .info-box .btn-box .btn {
    font-size: 13px;
  }

  .TEventManagementPage .EventItem .info-box .state-box {
    font-size: 14px;
    top: 1rem;
    right: 1.25rem;
  }

  /* 이벤트 삭제 */

  .TEventManagementPage .EventItem .delete-box {
    right: 1.25rem;
  }

  .TEventManagementPage.deleting .EventItem .delete-box .form-check-input {
    width: 25px;
    height: 25px;
  }

  .demoModal .modal-event-detail .modal-header {
    padding: 1.25rem 0 !important;
  }

  .demoModal .modal-event-detail .modal-header .modal-title {
    font-size: 18px;
  }

  .demoModal .modal-event-detail .modal-header .btn {
    max-width: 16px;
    right: 2rem;
  }

  .demoModal .modal-event-detail .detail-box {
    margin-bottom: 1rem;
  }

  .demoModal .modal-event-detail .detail-item {
    font-size: 15px;
    gap: 0 0.5rem;
  }

  .demoModal .modal-event-detail .detail-item .tit {
    width: 27%;
  }

  .demoModal .modal-event-detail .detail-item .contents {
    width: 73%;
  }
}

@media screen and (max-width: 320px) {
  .TEventManagementPage .EventItem .info-box .event-period .mob-320 {
    display: block;
  }

  .TEventManagementPage .EventItem .delete-box {
    right: 1rem;
  }

  .TEventManagementPage.deleting .EventItem .delete-box .form-check-input {
    width: 20px;
    height: 20px;
  }
}

.TEventManagementPage .Event-Top {
  align-items: flex-end;
  flex-direction: column;
}

.TEventManagementPage .Event-Top .tit {
  text-transform: uppercase;
  width: 100%;
}

.TEventManagementPage .Event-Top .btnbox .btn {
  border-radius: 40px;
}

.TEventManagementPage .Event-Top .btnbox .btn.btn-light {
  border-color: var(--ColorTypeB);
}

.TEventManagementPage .EventItem.d-day .line {
  background-color: #4d56ff;
}

.TEventManagementPage .EventItem.d-day .info-box .state-box {
  color: #4d56ff;
}

.TEventManagementPage .EventItem.d-day .info-box .state-box::before {
  background-image: url(https://www.flexdaycdn.net/public/images/ticket_ver2/calendar2.png);
}

.TEventManagementPage .EventItem .info-box .btn-box .btn.btn.btn-light {
  border-color: var(--ColorTypeB);
}

.TEventManagementPage
  .EventItem
  .info-box
  .btn-box
  .btn.btn.btn-light.disabled {
  border-color: var(--ColorTypeD);
}

.TEventManagementPage .modal-event-detail .modal-header .modal-title {
  padding-top: 2rem;
}

.TEventManagementPage .modal-event-detail .modal-header .btn {
  top: 2rem;
  transform: none;
}

@media screen and (max-width: 599px) {
  .TEventManagementPage .Event-Top {
    gap: 1rem 0;
  }

  .TEventManagementPage .Event-Top .btnbox .btn {
    font-size: 14px;
  }
}
