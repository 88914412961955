/* 23-01-10 추가 */

.TPayDetail2Page {
    position: relative;
    ;
}

.TPayDetail2Page::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    background: #f7f7f7;
    width: 100%;
    height: 100%;
    z-index: -1;
    min-height: 100vh;
}

.TPayDetail2Page::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    background: var(--ColorTypeA);
    width: 100%;
    height: 24%;
    z-index: -1;
}

.TPayDetail2Page .PayTitle p {
    font-weight: var(--Semibold);
    font-size: 30px;
    color: #FFFFFF;
}


.TPayDetail2Page .PayDetail {
    background-color: #fff;
    padding: 3rem 2rem 4rem;
    margin-bottom: 2.5rem;
    position: relative;
}

.TPayDetail2Page .PayDetail .clip_icon {
    position: absolute;
    top: -14px;
    right: 7%;
    max-width: 32px;
}

.TPayDetail2Page .PayDetail-Tit {
    font-weight: var(--Bold);
    font-size: 25px;
    color: var(--ColorTypeB);
    margin-bottom: 1.5rem;
}

.TPayDetail2Page .PayDetail-Item {
    font-weight: var(--Medium);
    font-size: 20px;
    color: var(--ColorTypeB);
}

.TPayDetail2Page .PayDetail-Item .prd {
    width: 75%;
    word-break: break-word;
}

.TPayDetail2Page .PayDetail-Item .prd .small {
    font-size: 15px;
}

.TPayDetail2Page .PayDetail-Item .used {
    width: 25%;
    text-align: right;
}

.TPayDetail2Page .PayDetail-Item .tit {
    width: 80px;
}

.TPayDetail2Page .PayDetail-Item .price {
    word-break: break-word;
    width: calc(100% - 80px);
}

.TPayDetail2Page .PayDetail-bar {
    margin: 3rem 0;
    width: 100%;
    height: 1px;
    background-color: var(--ColorTypeD);
}

.TPayDetail2Page .PayInfotxt {
    font-size: 18px;
    color: var(--ColorTypeC);
    margin-bottom: 3rem;
    text-indent: -12px;
    padding-left: 12px;
    word-break: keep-all;
}

.demo .TPayDetail2Page .PayDetail-Confirm .btn {
    height: 70px;
    font-size: 22px;
} 

/* 결제취소시 */

.TPayDetail2Page .PayDetailBox.cancel .PayDetail-Item .price.state {
    color: var(--ColorTypeA);
}

.TPayDetail2Page .PayDetailBox.cancel .PayDetail-Confirm .btn-default {
    background-color: var(--ColorTypeD);
    border-color: var(--ColorTypeD);
    pointer-events: none;
}


/* 모바일 반응형 */

@media screen and (max-width: 599px) {

    .TPayDetail2Page .PayTitle p {
        font-size: 22px;
    }

    .TPayDetail2Page .PayDetail {
        padding: 2.25rem 1.5rem 3rem;
        margin-bottom: 2rem;
    }

    .TPayDetail2Page .PayDetail .clip_icon {
        top: -8px;
        right: 10%;
        max-width: 20px;
    }

    .TPayDetail2Page .PayDetail-Tit {
        font-size: 20px;
        margin-bottom: 1.25rem;
    }

    .TPayDetail2Page .PayDetail-Item {
        font-size: 15px;
    }

    .TPayDetail2Page .PayDetail-Item .prd .small {
        font-size: 12px;
    }

    .TPayDetail2Page .PayInfotxt {
        font-size: 13px;
        margin-bottom: 1rem !important;
    }

    .TPayDetail2Page .PayDetail-bar {
        margin: 2.25rem 0;
    }

    .demo .TPayDetail2Page .PayDetail-Confirm .btn {
        height: 55px;
        font-size: 16px;
    } 


}

@media screen and (max-width: 320px) {}