
.TUseQRPage {
    position: relative;
}

.TUseQRPage::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    background: #f7f7f7;
    width: 100%;
    height: 100%;
    z-index: -1;
    min-height: 100vh;
}

.TUseQRPage .QRBox-inner {
    background-color: #fff;
    padding-top: 2rem;
    padding-bottom: 18rem;
    box-shadow: 0px 5px 8px 3px rgb(0 0 0 / 5%);
    position: relative;
}

.TUseQRPage .QRBox .infoitem-date {
    font-size: 22px;
    color: #231815;
    margin-bottom: 2.5rem;
}

.TUseQRPage .QRBox .infobox .infoitem {
    margin-bottom: 1.25rem;
}

.TUseQRPage .QRBox .infobox .infoitem .tit {
    font-size: 20px;
    color: var(--ColorTypeA);
}

.TUseQRPage .QRBox .infobox .infoitem .desc {
    font-weight: var(--Semibold);
    font-size: 27px;
    color: var(--ColorTypeB);
    word-break: break-word;
}

.TUseQRPage .QRBox .imgbox {
    margin: 1.25rem 0;
}

.TUseQRPage .QRBox .imgbox img.qr-img {
    display: block;
    max-width: 380px;
}

.TUseQRPage .QRBox .QRInfotxt .mob {
    display: none;
}

.TUseQRPage .QRBox .QRInfotxt {
    font-size: 20px;
    color: var(--ColorTypeC);
    position: relative;
    z-index: 5;
}

.TUseQRPage .QRBox .QRBox-bg {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    width: 104.8%;
}


/* 모바일 반응형 */

@media screen and (max-width: 599px) {

    .TUseQRPage .QRBox-inner {
        padding-bottom: 20vh;
    }

    .TUseQRPage .QRBox .infoitem-date {
        font-size: 16px;
        margin-bottom: 1.5rem;
    }

    .TUseQRPage .QRBox .infobox.px-4-5 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important;
    }
    
    .TUseQRPage .QRBox .infobox .infoitem .tit {
        font-size: 15px;
    }

    .TUseQRPage .QRBox .infobox .infoitem .desc {
        font-size: 20px;
    }

    .TUseQRPage .QRBox .imgbox {
        margin: 0;
    }

    .TUseQRPage .QRBox .imgbox img.qr-img {
        max-width: 245px;
    }

    .TUseQRPage .QRBox .QRInfotxt {
        font-size: 13px;
        word-break: keep-all;
    }


}

@media screen and (max-width: 320px) {

    .TUseQRPage .QRBox .imgbox img.qr-img {
        max-width: 200px;
    }

    .TUseQRPage .QRBox .QRInfotxt .mob {
        display: block;
    }

}