.TEventAddPage .EventAdd-tit {
  font-weight: var(--Medium);
  color: var(--ColorTypeB);
  font-size: 22px;
}

.TEventAddPage .EventAdd-tit > span {
  color: var(--ColorTypeA);
  font-weight: var(--Semibold);
}

.TEventAddPage .EventAdd-infoTxt {
  font-size: 16px;
  letter-spacing: -0.55px;
  color: var(--ColorTypeC);
}

.TEventAddPage .EventAdd-inputbox .form-control {
  padding: 1rem 1.5rem;
  font-size: 17px;
  line-height: 1;
}

.TEventAddPage .EventAdd-inputbox #range {
  align-items: center;
  gap: 0 1.5rem;
  font-size: 17px;
  color: var(--ColorTypeB);
}

.TEventAddPage .EventAdd-inputbox .datepicker-box {
  position: relative;
}

.TEventAddPage .EventAdd-inputbox .datepicker-box img {
  position: absolute;
  left: 1.5rem;
  top: 50%;
  transform: translateY(-50%);
  max-width: 20px;
}

.TEventAddPage .EventAdd-inputbox .form-control.input-datepicker {
  padding-left: calc(2.5rem + 20px);
  font-size: 18px;
}

.TEventAddPage .EventAdd-inputbox .form-control.input-datepicker::placeholder {
  font-size: 17px;
}

.TEventAddPage .EventAdd-inputbox.reward {
  font-size: 18px;
  font-weight: var(--Medium);
  color: var(--ColorTypeB);
  align-items: flex-start;
  position: relative;
  border-bottom: 1px solid #999999;
  padding: 1.5rem 0;
}

.TEventAddPage .EventAdd-inputbox.reward:last-child {
  border-bottom: 0;
}

.TEventAddPage .EventAdd-inputbox.reward .input-box1 {
  align-items: center;
  margin-right: 1.25rem;
}

.TEventAddPage .EventAdd-inputbox.reward .input-box1 > span.num {
  border: 2px solid var(--ColorTypeB);
  box-sizing: border-box;
  border-radius: 40px;
  width: 20px;
  height: 20px;
  font-size: 12px;
  font-weight: var(--Bold);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;
}

.TEventAddPage .EventAdd-inputbox.reward .input-box1 .form-control.input1 {
  width: 55px;
  height: 50px;
  padding: 1rem;
  text-align: center;
  text-align-last: center;
  -ms-text-align-last: center;
  -moz-text-align-last: center;
  margin-left: 0.75rem;
  margin-right: 0.5rem;
}

.TEventAddPage .EventAdd-inputbox.reward .input-box2 {
  align-items: center;
  width: 45%;
  flex-wrap: wrap;
}

.TEventAddPage .EventAdd-inputbox.reward .input-box2 .form-control.input2 {
  width: 80%;
  margin-right: 0.5rem;
  padding: 1rem;
  padding-right: 2rem;
  background: url(https://www.flexdaycdn.net/public/images/ticket/arrow_down_b.png)
    no-repeat calc(100% - 1rem) center / 1rem;
}

.TEventAddPage
  .EventAdd-inputbox.reward
  .input-box2
  .form-control.input2
  option:hover {
  background-color: #fff;
}

.TEventAddPage
  .EventAdd-inputbox.reward
  .input-box2
  .form-control.input2
  ~ .direct-input {
  width: 100%;
  margin-top: 0.5rem;
  padding: 1rem;
  display: none;
}

.TEventAddPage
  .EventAdd-inputbox.reward
  .input-box2
  .form-control.input2.show
  ~ .direct-input {
  display: block;
}

.TEventAddPage .EventAdd-inputbox.reward .btn-box {
  width: 30px;
  position: absolute;
  right: 0;
  top: 2.2rem;
}

.TEventAddPage .EventAdd-Btnbox .btn-light {
  font-size: 20px;
  border-radius: 50px;
  margin: 3rem 0 7rem;
}

.TEventAddPage .EventAdd-Btnbox .btn-default {
  height: 70px;
  font-size: 22px;
}

.TEventAddPage .EventAdd-inputbox .disabled {
  opacity: 0.3;
}

/* 모바일 반응형 */
@media screen and (max-width: 599px) {
  .TEventAddPage .EventAddBox.px-4-5 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .TEventAddPage .EventAdd-tit {
    font-size: 18px;
  }

  .TEventAddPage .EventAdd-infoTxt {
    font-size: 14px;
  }

  .TEventAddPage .EventAdd-inputbox .form-control {
    padding: 0.85rem 1.25rem;
    height: 50px;
    font-size: 14px;
  }

  .TEventAddPage .EventAdd-inputbox .datepicker-box img {
    left: 1rem;
    max-width: 17px;
  }

  .TEventAddPage .EventAdd-inputbox #range {
    gap: 0 0.5rem;
    font-size: 14px;
  }

  .TEventAddPage .EventAdd-inputbox .form-control.input-datepicker {
    padding-left: calc(1.5rem + 17px);
    padding-right: 0;
    font-size: 15px;
  }

  .TEventAddPage
    .EventAdd-inputbox
    .form-control.input-datepicker::placeholder {
    font-size: 14px;
  }

  .TEventAddPage .EventAdd-inputbox.reward {
    font-size: 15px;
    padding: 1.25rem 0;
  }

  .TEventAddPage .EventAdd-inputbox.reward .input-box1 {
    margin-right: 0.75rem;
  }

  .TEventAddPage .EventAdd-inputbox.reward .input-box1 > span.num {
    width: 17px;
    height: 17px;
    font-size: 10px;
  }

  .TEventAddPage .EventAdd-inputbox.reward .input-box1 .form-control.input1 {
    width: 45px;
    height: 50px;
    padding: 0.85rem 0.5rem;
    margin-left: 0.5rem;
    margin-right: 0.25rem;
  }

  .TEventAddPage .EventAdd-inputbox.reward .input-box2 .form-control.input2 {
    margin-right: 0.25rem;
    padding: 0.85rem;
    padding-right: 1.7rem;
    background: url(https://www.flexdaycdn.net/public/images/ticket/arrow_down_b.png)
      no-repeat calc(100% - 0.85rem) center / 0.85rem;
  }

  .TEventAddPage
    .EventAdd-inputbox.reward
    .input-box2
    .form-control.input2
    ~ .direct-input {
    padding: 0.85rem;
  }

  .TEventAddPage .EventAdd-inputbox.reward .btn-box {
    width: 25px;
    top: 2rem;
  }

  .TEventAddPage .EventAdd-Btnbox .btn-light {
    font-size: 16px;
    margin: 2rem 0 5rem;
  }

  .TEventAddPage .EventAdd-Btnbox .btn-default {
    height: 55px;
    font-size: 16px;
  }
}

@media screen and (max-width: 380px) {
  .TEventAddPage .EventAdd-inputbox.reward {
    flex-wrap: wrap;
  }

  .TEventAddPage .EventAdd-inputbox.reward .input-box2 {
    width: 100%;
    margin-top: 0.5rem;
    margin-left: calc(17px + 0.5rem);
  }
}

@media screen and (max-width: 320px) {
  .TEventAddPage .EventAdd-inputbox .datepicker-box img {
    display: none;
  }

  .TEventAddPage .EventAdd-inputbox .form-control.input-datepicker {
    text-align: center;
    text-align-last: center;
    -ms-text-align-last: center;
    -moz-text-align-last: center;
    padding-left: 0;
  }
}

/* ver2 */

.TEventAddPage .EventAdd-inputbox.reward .input-box2 {
  width: auto;
  flex: 1;
}

.TEventAddPage .EventAdd-inputbox.reward .input-box2 .form-control.input2 {
  width: 60%;
  max-width: 185px;
  text-overflow: ellipsis;
}

.TEventAddPage .EventAdd-Btnbox .btn-light {
  border-color: var(--ColorTypeB);
}

@media screen and (max-width: 599px) {
  .TEventAddPage .EventAdd-inputbox.reward {
    flex-direction: column;
  }

  .TEventAddPage .EventAdd-inputbox.reward .input-box1 .form-control.input1 {
    height: 40px;
  }

  .TEventAddPage .EventAdd-inputbox.reward .input-box2 {
    flex: auto;
    width: calc(100% - 17px - 0.5rem);
    margin-top: 0.5rem;
    margin-left: calc(17px + 0.5rem);
  }

  .TEventAddPage .EventAdd-inputbox.reward .input-box2 .form-control.input2 {
    width: 80%;
    max-width: 240px;
  }
}
