.TSettleHistorySubPage .HistorySub .Listitem {
    border-bottom: 1px solid var(--ColorTypeE);
}

.TSettleHistorySubPage .HistorySub .Listitem:last-child {
    border-bottom: 0px;
}

.TSettleHistorySubPage .HistorySub .Listitem .time {
    font-weight: var(--Regular);
    font-size: 20px;
    color: var(--ColorTypeC);
    margin-bottom: 0;
}

.TSettleHistorySubPage .HistorySub .Listitem img.logo {
    width: 85px;
    align-self: center;
}

.TSettleHistorySubPage .HistorySub .Listitem .txtbox {
    width: calc(100% - 115px);
}

.TSettleHistorySubPage .HistorySub .Listitem .txtbox .name {
    font-weight: var(--Medium);
    font-size: 22px;
    color: var(--ColorTypeC);
    word-break: break-word;
}

.TSettleHistorySubPage .HistorySub .Listitem .txtbox .t-num {
    font-weight: var(--Medium);
    font-size: 18px;
    color: var(--ColorTypeC);
}

.TSettleHistorySubPage .HistorySub .Listitem .txtbox .price {
    font-weight: var(--Medium);
    font-size: 25px;
    color: var(--ColorTypeB);
    word-break: break-word;
    margin-bottom: 0;
}

.TSettleHistorySubPage .HistorySub .Listitem .txtbox .price span.num {
    font-size: 32px;
}

.TSettleHistorySubPage .HistorySub .Listitem.cancel .txtbox .price {
    color: var(--ColorTypeA);
}



.TSettleHistorySubPage .HistorySub .Listitem .cancel-txt {
    font-weight: var(--Regular);
    font-size: 20px;
    color: var(--ColorTypeA);
    margin-bottom: 0;
}


/* 모바일 반응형 */

@media screen and (max-width: 599px) {
    
    .TSettleHistorySubPage .HistorySub .Listitem img.logo {
        width: 50px;
    }

    .TSettleHistorySubPage .HistorySub .Listitem .txtbox {
        width: calc(100% - 70px);
    }

    .TSettleHistorySubPage .HistorySub .Listitem .time {
        font-size: 15px;
    }

    .TSettleHistorySubPage .HistorySub .Listitem .txtbox .name {
        font-size: 17px;
    }

    .TSettleHistorySubPage .HistorySub .Listitem .txtbox .t-num {
        font-size: 13px;
    }

    .TSettleHistorySubPage .HistorySub .Listitem .txtbox .price {
        font-size: 20px;
    }

    .TSettleHistorySubPage .HistorySub .Listitem .txtbox .price span.num {
        font-size: 25px;
    }

    .TSettleHistorySubPage .HistorySub .Listitem .cancel-txt {
        font-size: 15px;
    }


    

}

@media screen and (max-width: 320px) {

    
}