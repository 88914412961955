.TPaymentConfirmPage .Payment-title img {
    max-width: 60px;
}

.TPaymentConfirmPage .PageTit2 {
    font-size: 30px;
    line-height: 1.3;
}

.TPaymentConfirmPage .PageSub2 {
    font-weight: var(--Medium);
    color: var(--ColorTypeC);
    font-size: 20px;
}

.TPaymentConfirmPage .PaymentResult {
    background-color: #f7f7f7;
    border-radius: 3px;
}

.TPaymentConfirmPage .PaymentResult-Tit {
    font-weight: var(--Medium);
    font-size: 25px;
    color: var(--ColorTypeB);
    border-bottom: 1px solid var(--ColorTypeC);
    margin-bottom: 30px;
}

.TPaymentConfirmPage .PaymentResult-Item .tit {
    font-weight: var(--Medium);
    font-size: 20px;
    color: var(--ColorTypeB);
}

.TPaymentConfirmPage .PaymentResult-Item .price {
    font-weight: var(--Medium);
    font-size: 20px;
    color: var(--ColorTypeB);
    word-break: break-word;
}

/* 모바일 반응형 */

@media screen and (max-width: 599px) {

    .TPaymentConfirmPage .Payment-title img {
        max-width: 40px;
    }

    .TPaymentConfirmPage .PageTit2 {
        font-size: 25px;
    }

    .TPaymentConfirmPage .PageSub2 {
        font-size: 15px;
    }

    .TPaymentConfirmPage .PaymentResult-Tit {
        font-size: 18px;
        margin-bottom: 20px;
    }

    .TPaymentConfirmPage .PaymentResult-Item .tit {
        font-size: 14px;
    }

    .TPaymentConfirmPage .PaymentResult-Item .price {
        font-size: 14px;
    }


}

@media screen and (max-width: 320px) {}


/* 23-01-09 수정 */

.TPaymentConfirmPage .Payment-title.my-5 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
}

.TPaymentConfirmPage .Payment-title img {
    max-width: 65px;
}

.TPaymentConfirmPage .PaymentResult-Item .tit {
    color: #999999;
}

.TPaymentConfirmPage .PaymentResult_Wrap {
    position: relative;
    overflow: hidden;
    padding-top: 6px;
}

.TPaymentConfirmPage .PaymentResult_Wrap::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 12px;
    background-color: #000000;
    border: 3px solid #232323;
    border-radius: 30px;
    z-index: -1;
}

.TPaymentConfirmPage .PaymentResult_Wrap .hidebox {
    overflow: hidden;
    position: relative;
}

.TPaymentConfirmPage .PaymentResult_Wrap .hidebox::after {
    content: "";
    width: calc(100% - 6px);
    height: 5px;
    position: absolute;
    left: 50%;
    top: -5px;
    transform: translateX(-50%);
    box-shadow: 0px 5px 10px rgb(0 0 0 / 30%);
}

.TPaymentConfirmPage .PaymentResult {
    background: url(https://www.flexdaycdn.net/public/images/ticket/payresult_bg4.png) no-repeat center / 100% 100%;
    border-radius: 0;
    padding-top: 4rem !important;
    padding-bottom: 5rem !important;
    width: calc(100% - 6px);
    margin: auto;
    -webkit-animation: slidedown 1s ease-out;
    animation: slidedown 1s ease-out;
}

@-webkit-keyframes slidedown {
    0% {
        -webkit-transform: translateY(-120%);
    }

    100% {
        -webkit-transform: translateY(0%);
    }
}

@keyframes slidedown {
    0% {
        transform: translateY(-120%);
    }

    100% {
        transform: translateY(0%);
    }
}

.TPaymentConfirmPage .PaymentResult-Tit {
    font-size: 27px;
    padding-bottom: 0 !important;
    border-bottom: 0;
}

.TPaymentConfirmPage .PaymentResult-Tit::after {
    content: "";
    display: block;
    background: url(https://www.flexdaycdn.net/public/images/ticket/payresult_line.png) repeat center /contain;
    width: 100%;
    height: 2px;
    margin-top: 20px;
}

.TPaymentConfirmPage .Payment-Confirm {
    display: flex;
    gap: 0 1rem;
}

.demo .TPaymentConfirmPage .Payment-Confirm .btn {
    height: 70px;
    font-size: 22px;
}

.TPaymentConfirmPage .PaymentResult-infotxt p {
    font-size: 20px;
    color: var(--ColorTypeC)
}

@media screen and (max-width: 599px) {

    .TPaymentConfirmPage .Payment-title img {
        max-width: 48px;
    }

    .TPaymentConfirmPage .PaymentResult {
        padding-top: 3rem !important;
        padding-bottom: 4rem !important;
    }

    .TPaymentConfirmPage .PaymentResult-Tit {
        font-size: 22px;
    }

    .demo .TPaymentConfirmPage .Payment-Confirm .btn {
        height: 55px;
        font-size: 16px;
    }

    .TPaymentConfirmPage .PaymentResult-infotxt p {
        font-size: 14px;
    }

}