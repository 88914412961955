/* 23-01-09 추가 */

.TSurveyResultPage {
  position: relative;
}

.TSurveyResultPage::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background: #f7f7f7;
  width: 100%;
  height: 100%;
  z-index: -1;
  min-height: 100vh;
}

.TSurveyResultPage .SurveyResult-Top {
  justify-content: space-between;
  align-items: center;
  gap: 0 1rem;
}

.TSurveyResultPage .SurveyResult-Top .inputbox {
  align-items: center;
  gap: 0 0.75rem;
  font-size: 17px;
  color: var(--ColorTypeB);
  width: 72%;
}

.TSurveyResultPage .SurveyResult-Top .inputbox .form-control {
  color: var(--ColorTypeB);
  border: 2px solid var(--ColorTypeB);
  padding: 0.75rem 1rem;
  border-radius: 5px;
  font-weight: var(--Medium);
  font-size: 18px;
  line-height: 1;
  width: 100%;
  height: auto;
  text-align: center;
  text-align-last: center;
  text-align: center;
  -ms-text-align-last: center;
  -moz-text-align-last: center;
}

.TSurveyResultPage .SurveyResult-Top .btn-default {
  width: auto;
  height: auto;
  border-radius: 30px;
  padding: 0.5rem 2.5rem;
}

.TSurveyResultPage .SurveyResult-Bottom .PrdTbl-downbox {
  margin-bottom: 0.25rem;
  display: flex;
  justify-content: flex-end;
}

.TSurveyResultPage .SurveyResult-Bottom .PrdTbl-down {
  display: flex;
  align-items: center;
  gap: 0 0.5rem;
  font-size: 18px;
  font-weight: var(--Semibold);
  color: var(--ColorTypeB);
}

.TSurveyResultPage .SurveyResult-Bottom .PrdTbl-down img {
  max-width: 28px;
  padding-bottom: 0.5rem;
}

.TSurveyResultPage .PrdTbl-wrap {
  overflow-x: scroll;
}

.TSurveyResultPage .PrdTbl-wrap::-webkit-scrollbar {
  display: none;
}

.TSurveyResultPage .PrdTbl {
  min-width: 100%;
  width: max-content;
  text-align: center;
  vertical-align: middle;
}

.TSurveyResultPage .PrdTbl thead th {
  background-color: #000000;
  color: #fff;
  font-weight: var(--Regular);
  box-shadow: none;
  border-right-width: 2px;
  border-color: var(--ColorTypeC);
  padding: 0.5rem 1.25rem;
  font-size: 18px;
}

.TSurveyResultPage .PrdTbl thead th:last-child {
  border-right: 0;
}

.TSurveyResultPage .PrdTbl tbody td {
  background-color: #fff;
  border-color: var(--ColorTypeD);
  border-right-width: 2px;
  border-bottom-width: 2px;
  box-shadow: none;
  font-size: 16px;
  line-height: 1.2;
  padding: 0.75rem 0.25rem;
  min-width: 100px;
  word-break: break-word;
}

.TSurveyResultPage .PrdTbl tbody td[data-txt="사용 현황"],
.TSurveyResultPage .PrdTbl tbody td[data-txt="평가(비율%)"] {
  max-width: 250px;
}

.TSurveyResultPage .PrdTbl tbody td:last-child {
  border-right: 0;
}

.TSurveyResultPage .DatepickerBox.ver1 .form-control.InputType.input-datepicker.prd-admin {
  padding: 0.75rem;
  padding-left: calc(1.25rem + 20px);
  border-width: 1px;
  background-color: #fff;
  background-position-x: 1rem;
  background-size: 18px;
}
/* 모바일 반응형 */

@media screen and (max-width: 599px) {
  .TSurveyResultPage .SurveyResult-Top {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
    gap: 1rem 0;
    flex-wrap: wrap;
  }

  .TSurveyResultPage .SurveyResult-Top .inputbox {
    width: 100%;
  }

  .TSurveyResultPage .SurveyResult-Top .inputbox .form-control {
    font-size: 16px;
    width: 100%;
    padding: 0.75rem 0;
  }

  .TSurveyResultPage .SurveyResult-Top .btn-default {
    font-size: 16px;
    width: 100%;
  }

  .TSurveyResultPage .SurveyResult-Bottom.ps-4-5 {
    padding-left: 1.5rem !important;
  }

  .TSurveyResultPage .SurveyResult-Bottom .PrdTbl-down {
    font-size: 16px;
    padding-right: 1.5rem;
  }

  .TSurveyResultPage .SurveyResult-Bottom .PrdTbl-down img {
    max-width: 24px;
    padding-bottom: 0.3rem;
  }

  .TSurveyResultPage .PrdTbl {
    width: max-content;
    margin-right: 1.5rem;
  }

  .TSurveyResultPage .PrdTbl thead th {
    font-size: 16px;
  }

  .TSurveyResultPage .PrdTbl tbody td {
    font-size: 14px;
  }

  .TSurveyResultPage .PrdTbl tbody td[data-txt="사용 현황"],
  .TSurveyResultPage .PrdTbl tbody td[data-txt="평가(비율%)"] {
    max-width: 200px;
  }

  .TSurveyResultPage .SurveyResult-Bottom.px-4-5 {
    padding-right: 0 !important;
    padding-left: 1.5rem !important;
  }

  .TSurveyResultPage .DatepickerBox.ver1 > .d-flex {
    gap: 0 0.75rem;
  }

  .TSurveyResultPage .DatepickerBox.ver1 .form-control.InputType.input-datepicker.prd-admin {
    background: url(https://www.flexdaycdn.net/public/images/ticket/calendar3.png) no-repeat 1rem center / 18px #fff;
  }
}

@media screen and (max-width: 320px) {
    
  .TSurveyResultPage .DatepickerBox.ver1 .form-control.InputType.input-datepicker.prd-admin {
    background-image: none;
  padding: 0.75rem 0.5rem;
  }
  
}