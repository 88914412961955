

.TRegisterPage .SignUp-title {
    margin: 3rem auto 2rem
}

.TRegisterPage .SignUp-title .tit {
    font-weight: var(--Medium);
    color: var(--ColorTypeB);
    font-size: 32px;
}

.TRegisterPage .SignUp-title .tit img {
    max-width: 32px;
    align-self: center;
}

.TRegisterPage .SignUp-title .sub {
    font-weight: var(--Regular);
    color: var(--ColorTypeC);
    font-size: 18px;
}

.TRegisterPage .SignUp-Infobox {
    padding-left: calc(0.75rem + 28px + 0.25rem);
}

.TRegisterPage .SignUp-checkbox-desc {
    font-weight: var(--Regular);
    font-size: 13px;
    color: var(--ColorTypeC);
    word-break: keep-all;
}

.TRegisterPage .SignUp-Infobox .form-check-group {
    gap: 0.5rem 0;
}

.TRegisterPage .SignUp-Infobox .form-check-group .form-check {
    width: 33.333%;
}

.TRegisterPage .SignUp-title .sub {
    color: #000000;
}

.TRegisterPage .ver2 .accordion-body {
    padding: 0rem 0.75rem 0;
}

.TRegisterPage .CheckLabel {
    font-size: 17px;
}

.TRegisterPage .SignUp-checkbox-desc {
    color: #999999;
    font-size: 17px;
}

.TRegisterPage .ver2 .accordion-button {
    font-size: 20px;
    background-color: #fff;
}

.TRegisterPage .ver2 .accordion-button::after {
    margin-right: 0.5rem;
}

.TRegisterPage .CheckInfoBtn {
    font-size: 16px;
}

.demo .TRegisterPage .SignUp-Confirm .btn {
    height: 70px;
    font-size: 22px;
}


/* 모바일 반응형 */
@media screen and (max-width: 599px) {

    .TRegisterPage .ver2 .accordion-body {
        padding: 0;
    }

    .TRegisterPage .SignUp-Infobox {
        padding-left: calc(28px + 0.25rem);
    }

    .TRegisterPage .CheckInfoBtn {
        right: 0.5rem;
    }

    .TRegisterPage .SignUp-title .tit {
        font-size: 25px;
    }

    .TRegisterPage .SignUp-title .tit img {
        max-width: 26px;
    }

    .TRegisterPage .SignUp-title .sub {
        font-size: 14px;
    }

    .TRegisterPage .ver2 .accordion-button {
        font-size: 16px;
    }

    .TRegisterPage .CheckLabel {
        font-size: 14px;
    }

    .TRegisterPage .CheckInfoBtn {
        font-size: 14px;
    }

    .TRegisterPage .SignUp-checkbox-desc {
        font-size: 13px;
    }

    .demo .TRegisterPage .SignUp-Confirm .btn {
        height: 55px;
        font-size: 16px;
    }    

}

@media screen and (max-width: 320px) {}