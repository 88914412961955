
.TPaymentList2Page .PaymentFilter {
    margin-bottom: 3rem;
}

.TPaymentList2Page .PaymentFilter .sort {
    display: inline-flex;
    align-items: center;
    gap: 0 0.6rem;
    cursor: pointer;
}

.TPaymentList2Page .PaymentFilter .sort .filter-txt {
    font-size: 25px;
    color: var(--ColorTypeB);
    font-weight: var(--Medium);
}

.TPaymentList2Page .PaymentFilter .sort .arrow {
    max-width: 20px;
}

.TPaymentList2Page .PrdList .Listitem:last-child {
    border-bottom: 0px;
}

.TPaymentList2Page .PrdList .Listitem>.d-flex {
    gap: 0 2rem;
}

.TPaymentList2Page .PrdList .Listitem img.logo {
    width: 85px;
    align-self: flex-start;
    padding-top: 0.25rem;
}

.TPaymentList2Page .PrdList .Listitem .txtbox {
    width: calc(70% - 85px);
}

.TPaymentList2Page .PrdList .Listitem .txtbox .date {
    font-size: 22px;
    color: var(--ColorTypeC);
    word-break: break-word;
}

.TPaymentList2Page .PrdList .Listitem .txtbox .price {
    font-weight: var(--Semibold);
    font-size: 32px;
    color: var(--ColorTypeB);
    word-break: break-word;
    margin-bottom: 0;
    position: relative;
}

.TPaymentList2Page .PrdList .Listitem.cancel .txtbox .price::after {
    content: "결제 취소됨";
    display: block;
    font-weight: 500;
    font-size: 22px;
    color: #CC0000;
}

.TPaymentList2Page .PrdList .Listitem.cancel .txtbox .price .num {
    position: relative;
}

.TPaymentList2Page .PrdList .Listitem.cancel .txtbox .price .num::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 120%;
    height: 2px;
    background-color: #CC0000;
}

.TPaymentList2Page .PrdList .Listitem .moreDatil {
    width: 30%;
}

.TPaymentList2Page .PrdList .Listitem .moreDatil .btn {
    width: 30%;
    font-size: 20px;
    color: var(--ColorTypeB);
    font-weight: var(--Medium);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0 0.6rem;
}

.TPaymentList2Page .PrdList .Listitem .moreDatil .btn img {
    width: 9px;
    filter: brightness(0);
}

.TPaymentList-modal .modal-prdfilter {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.TPaymentList-modal .modal-prdfilter .modal-content {
    border: none;
    margin-bottom: 3rem;
    border-radius: 2rem;

}

.TPaymentList-modal .modal-prdfilter .modal-bar {
    padding: 1rem 0
}

.TPaymentList-modal .modal-prdfilter .modal-bar .bar {
    width: 18%;
    height: 0.5rem;
    background-color: #CCCCCC;
    border-radius: 30px;
    margin: auto;
}

.TPaymentList-modal .modal-prdfilter .modal-header {
    padding: 1.5rem 3rem 0;
}

.TPaymentList-modal .modal-prdfilter .modal-header .modal-title {
    font-size: 30px;
}

.TPaymentList-modal .modal-prdfilter .modal-header .btn {
    max-width: 25px;
}

.TPaymentList-modal .modal-prdfilter .modal-body {
    padding: 0.5rem 3rem 0;
}

.TPaymentList-modal .modal-prdfilter .form-sort .form-radio-input {
    display: none;
}

.TPaymentList-modal .modal-prdfilter .form-sort .form-radio-input+.form-radio-label {
    display: flex;
    align-items: center;
    gap: 0 1.1rem;
    font-size: 25px;
    font-weight: var(--Medium);
    color: var(--ColorTypeB);
    margin-bottom: 1.5rem;
}

.TPaymentList-modal .modal-prdfilter .form-sort .form-radio-input:checked+.form-radio-label .check {
    display: block;
}

.TPaymentList-modal .modal-prdfilter .form-sort .form-radio-input+.form-radio-label .check {
    display: none;
    max-width: 25px;
}


/* 모바일 반응형 */

@media screen and (max-width: 599px) {

    .TPaymentList2Page .PaymentFilter {
        margin-bottom: 1.5rem;
    }

    .TPaymentList2Page .PaymentFilter .sort {
        gap: 0 0.35rem;
    }

    .TPaymentList2Page .PaymentFilter .sort .filter-txt {
        font-size: 16px;
    }

    .TPaymentList2Page .PaymentFilter .sort .arrow {
        max-width: 15px;
    }

    .TPaymentList2Page .PrdList .Listitem {
        padding-left: 1.5rem !important;
        padding-right: 1.5rem !important;
    }

    .TPaymentList2Page .PrdList .Listitem>.d-flex {
        gap: 0 1rem;
    }

    .TPaymentList2Page .PrdList .Listitem img.logo {
        width: 50px;
    }

    .TPaymentList2Page .PrdList .Listitem .txtbox {
        width: calc(70% - 50px);
    }

    .TPaymentList2Page .PrdList .Listitem .txtbox .date {
        font-size: 14px;
    }

    .TPaymentList2Page .PrdList .Listitem .txtbox .price {
        font-size: 21px;
    }
    
    .TPaymentList2Page .PrdList .Listitem.cancel .txtbox .price::after {
        font-size: 15px;;
    }
    .TPaymentList2Page .PrdList .Listitem .moreDatil .btn {
        font-size: 14px;
    }

    .TPaymentList2Page .PrdList .Listitem .moreDatil .btn img {
        width: 6px;
    }

    .TPaymentList-modal .modal-prdfilter .modal-content {
        margin-bottom: 2rem;
        border-radius: 1.5rem;
    }

    .TPaymentList-modal .modal-prdfilter .modal-bar {
        padding: 0.8rem 0 0.5rem;
    }

    .TPaymentList-modal .modal-prdfilter .modal-bar .bar {
        height: 0.3rem;
    }

    .TPaymentList-modal .modal-prdfilter .modal-header {
        padding: 0.5rem 2rem !important;
    }

    .TPaymentList-modal .modal-prdfilter .modal-header .modal-title {
        font-size: 20px;
    }

    .TPaymentList-modal .modal-prdfilter .modal-header .btn {
        max-width: 16px;
    }

    .TPaymentList-modal .modal-prdfilter .modal-body {
        padding: 0.25rem 2rem 1rem !important;
    }


    .TPaymentList-modal .modal-prdfilter .form-sort .form-radio-input + .form-radio-label {
        gap: 0 0.5rem;
        font-size: 16px;
        margin-bottom: 1rem;
    }

    .TPaymentList-modal .modal-prdfilter .form-sort .form-radio-input+.form-radio-label .check {
        max-width: 17px;
    }




}

@media screen and (max-width: 320px) {

    .TPaymentList2Page .PrdList .Listitem>.d-flex {
        gap: 0 0.75rem;
    }

}