
.TNonMembersPage .NonMemberBox .NonMemberBox-title {
    margin: 6rem 0;
}

.TNonMembersPage .NonMemberBox .NonMemberBox-title .tit-img {
    width: 85%;
    margin-bottom: 2rem;
    margin-right: -2rem;
}

.TNonMembersPage .NonMemberBox .NonMemberBox-title .tit {
    font-size: 25px;
    word-break: keep-all;
}

.TNonMembersPage .NonMemberBox .NonMemberBox-title .sub {
    color: var(--ColorTypeB);
    font-size: 22px;
}

.TNonMembersPage .NonMemberBox .NonMemberBox-title .sub .color {
    color: var(--ColorTypeA);
    font-weight: var(--Semibold);
}

.TNonMembersPage .NonMemberBox .NonMemberBox-Input .form-control {
    padding: 1.5rem;
    height: 65px;
    font-size: 17px;
}

.TNonMembersPage .NonMemberBox .NonMemberBox-Input .btn-default {
    height: 70px;
    font-size: 22px;
}

.TNonMembersPage .NonMemberBox .NonMemberBox-Input .btn-default:focus {
    color: #fff;
    background-color: var(--ColorTypeB);
    border-color: var(--ColorTypeB);
    box-shadow: none
}

.TNonMembersPage .NonMemberBox .mob {
    display: none;
}



/* 모바일 반응형 */

@media screen and (max-width: 599px) {

    .TNonMembersPage .NonMemberBox .NonMemberBox-title {
        margin: 5rem 0 4rem;
    }

    .TNonMembersPage .NonMemberBox .NonMemberBox-title .tit-img {
        width: 100%;
        margin-right: -0.5rem;
    }

    .TNonMembersPage .NonMemberBox .NonMemberBox-title .tit {
        font-size: 20px;
    }

    .TNonMembersPage .NonMemberBox .NonMemberBox-title .sub {
        font-size: 18px;
    }

    .TNonMembersPage .NonMemberBox .NonMemberBox-Input .form-control {
        padding: 1.25rem;
        height: 50px;
        font-size: 14px;
        margin-bottom: 0.75rem !important;
    }

    .TNonMembersPage .NonMemberBox .NonMemberBox-Input .btn-default {
        height: 50px;
        font-size: 16px;
    }

    .TNonMembersPage .NonMemberBox .mob {
        display: block;
    }

}

@media screen and (max-width: 320px) {

    .TNonMembersPage .NonMemberBox .NonMemberBox-title {
        margin: 4rem 0 3rem;
    }

    .TNonMembersPage .NonMemberBox .NonMemberBox-title .tit-img {
        margin-bottom: 0;
    }

    .TNonMembersPage .NonMemberBox .NonMemberBox-title .tit {
        font-size: 18px;
    }

    .TNonMembersPage .NonMemberBox .NonMemberBox-title .sub {
        font-size: 16px;
    }

    .TNonMembersPage .NonMemberBox .NonMemberBox-Input .form-control {
        margin-bottom: 0.5rem !important;
    }

}