.TSalePrdAddPage .SalePrdAdd-tit {
    font-weight: var(--Medium);
    color: var(--ColorTypeB);
    font-size: 22px;
}

.TSalePrdAddPage .SalePrdAdd-tit > span {
    color: var(--ColorTypeA);
    font-weight: var(--Semibold);
}

.TSalePrdAddPage .SalePrdAdd-radiobox {
    gap: 0 1rem;
}

.TSalePrdAddPage .SalePrdAdd-radiobox .radioBox {
    border: 1px solid var(--ColorTypeD);
    padding: 0.75rem 1.75rem;
    font-size: 17px;
    font-weight: var(--Regular);
    color: var(--ColorTypeB);
    border-radius: 3px;
    transition: all 0.4s ease;
}

.TSalePrdAddPage .SalePrdAdd-radiobox .radioBox:focus-within {
    border-color: var(--ColorTypeB);
}

.TSalePrdAddPage .SalePrdAdd-radiobox .radioBox .form-check-input {
    margin: 0;
    vertical-align: middle;
    margin-right: 0.25em;
    width: 16px;
    height: 16px;
}

.TSalePrdAddPage .SalePrdAdd-radiobox .radioBox .form-check-input:checked {
    background-color: #fff;
    border-color: var(--ColorTypeA);
    background-image: none;
    position: relative;
}

.TSalePrdAddPage
    .SalePrdAdd-radiobox
    .radioBox
    .form-check-input:checked::before {
    content: "";
    width: 10px;
    height: 10px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--ColorTypeA);
    border-radius: 50%;
}

.TSalePrdAddPage .SalePrdAdd-inputbox .form-control {
    padding: 1.25rem 1.5rem;
    height: 65px;
    font-size: 17px;
}

.demo .TSalePrdAddPage .form-control.InputType ~ .price-txt {
    height: 65px;
    line-height: 65px;
    font-weight: var(--Medium);
    margin-right: 1.5rem;
}

.TSalePrdAddPage .form-text.info-txt {
    font-size: 17px;
    padding: 0.25rem 1.5rem;
}

.TSalePrdAddPage .SalePrdAdd-inputbox textarea.form-control {
    -ms-overflow-style: none;
}

.demo .TSalePrdAddPage .SalePrdAdd-inputbox .Input-placeholder {
    display: none;
}

.demo
    .TSalePrdAddPage
    .SalePrdAdd-inputbox.Input-placeholderbox
    .Input-placeholder {
    display: block;
    padding: 1.25rem 1.5rem;
}

/* .TSalePrdAddPage .SalePrdAddBox {
    background: #fff;
    border: 1px solid var(--ColorTypeD);
    border-top: 0;
} */

.TSalePrdAddPage .SalePrdAdd-inputbox textarea.form-control.ph {
    height: auto;
    min-height: 7em;
}

.TSalePrdAddPage .SalePrdAdd-inputbox textarea.form-control::-webkit-scrollbar {
    display: none;
}

.TSalePrdAddPage .SalePrdAdd-subbox {
    display: none;
    margin-top: 1.5rem;
    width: 100%;
}

.TSalePrdAddPage .SalePrdAdd-subbox.active {
    display: block;
}

.TSalePrdAddPage .SalePrdAdd-subbox .subBox .tit {
    font-weight: var(--Medium);
    color: var(--ColorTypeB);
    font-size: 18px;
}

.TSalePrdAddPage .SalePrdAdd-subbox .subBox {
    margin-bottom: 1.5rem;
}

.TSalePrdAddPage .SalePrdAdd-subbox .subBox .inputbox {
    align-items: center;
    gap: 0 0.75rem;
    font-size: 17px;
    color: var(--ColorTypeB);
}

.TSalePrdAddPage .SalePrdAdd-subbox .subBox .inputbox .form-control {
    padding: 0.75rem 1rem;
    height: auto;
    text-align: center;
}

.TSalePrdAddPage .SalePrdAdd-subbox .subBox.date .inputbox .form-control {
    width: 145px;
}

.TSalePrdAddPage .SalePrdAdd-subbox .subBox.time .inputbox .form-control {
    width: 90px;
}

.TSalePrdAddPage .SalePrdAdd-subbox .subBox .SalePrdAdd-inputbox .form-control {
    padding: 0.75rem 1.5rem;
    height: 55px;
}

.demo
    .TSalePrdAddPage
    .SalePrdAdd-subbox
    .subBox
    .form-control.InputType
    ~ .price-txt {
    height: 55px;
    line-height: 55px;
}

.TSalePrdAddPage .SalePrdAdd-Btnbox {
    margin-top: 4rem;
}

.TSalePrdAddPage .SalePrdAdd-Btnbox .btn-default {
    height: 70px;
    font-size: 22px;
}

.TSalePrdAddPage .SalePrdAdd-subbox {
    width: 100%;
    background-color: #f7f7f7;
    padding: 2rem 1.5rem;
    border-radius: 3px;
}
.TSalePrdAddPage .SalePrdAdd-subbox .subBox .tit span {
    word-break: keep-all;
}

@media screen and (max-width: 320px) {
    .TSalePrdAddPage .SalePrdAdd-subbox {
        padding: 1.5rem 1rem;
    }
    .TSalePrdAddPage .SalePrdAdd-subbox .subBox.date .inputbox .form-control {
        width: 105px;
        padding: 0.5rem 0.75rem;
    }
}

/* 모바일 반응형 */
@media screen and (max-width: 599px) {
    .TSalePrdAddPage .SalePrdAddBox.px-4-5 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important;
    }

    .TSalePrdAddPage .SalePrdAdd-tit {
        font-size: 18px;
    }

    .TSalePrdAddPage .SalePrdAdd-radiobox .radioBox {
        padding: 0.5rem 1.25rem;
        font-size: 14px;
    }

    .TSalePrdAddPage .SalePrdAdd-inputbox .form-control {
        padding: 0.85rem 1.25rem;
        height: 50px;
        font-size: 14px;
    }

    .demo .TSalePrdAddPage .form-control.InputType ~ .price-txt {
        height: 50px;
        line-height: 50px;
        margin-right: 1.25rem;
    }

    .TSalePrdAddPage .form-text.info-txt {
        font-size: 14px;
        padding: 0.25rem 1.25rem;
    }

    .TSalePrdAddPage .SalePrdAdd-subbox .subBox .tit {
        font-size: 16px;
    }

    .TSalePrdAddPage .SalePrdAdd-subbox .subBox .inputbox {
        gap: 0 0.5rem;
        font-size: 14px;
    }

    .TSalePrdAddPage .SalePrdAdd-subbox .subBox .inputbox .form-control {
        font-size: 14px;
        padding: 0.5rem 1rem;
    }

    .TSalePrdAddPage .SalePrdAdd-subbox .subBox.date .inputbox .form-control {
        width: 120px;
    }

    .TSalePrdAddPage .SalePrdAdd-subbox .subBox.time .inputbox .form-control {
        width: 75px;
    }

    .TSalePrdAddPage
        .SalePrdAdd-subbox
        .subBox
        .SalePrdAdd-inputbox
        .form-control {
        padding: 0.5rem 1.25rem;
        height: 45px;
    }

    .demo
        .TSalePrdAddPage
        .SalePrdAdd-subbox
        .subBox
        .form-control.InputType
        ~ .price-txt {
        height: 45px;
        line-height: 45px;
    }

    .TSalePrdAddPage .SalePrdAdd-Btnbox .btn-default {
        height: 55px;
        font-size: 16px;
    }
}

.TSalePrdAddPage .SalePrdAdd-sub {
    color: var(--ColorTypeC);
    font-size: 16px;
    margin-top: 0.25rem;
}

.TSalePrdAddPage .SalePrdAdd-subbox {
    border: 1px solid var(--ColorTypeB);
}

.TSalePrdAddPage .SalePrdAdd-subbox .subBox {
    display: flex;
    align-items: center;
    gap: 0 1.5rem;
}

.TSalePrdAddPage .SalePrdAdd-subbox .subBox + .subBox {
    border-top: 1px solid #eeeeee;
    padding-top: 1.5rem;
}

.TSalePrdAddPage .SalePrdAdd-subbox .subBox .check {
    border: 1px solid #d3d3d3;
    border-radius: 0.25rem;
    width: 20px;
    height: 20px;
    margin-top: 0;
    background-color: #fff;
}

.TSalePrdAddPage .SalePrdAdd-subbox .subBox .check:checked[type="checkbox"] {
    background: url(https://www.flexdaycdn.net/public/images/ticket_ver2/checkbox_check.png)
        no-repeat center center / 0.7em #fff;
    border: 1px solid var(--ColorTypeA);
}

.TSalePrdAddPage .SalePrdAdd-subbox .subBox .tit {
    margin-bottom: 0;
}

.TSalePrdAddPage .SalePrdAdd-subbox .subBox .checkContent {
    flex: 1;
}

.TSalePrdAddPage
    .SalePrdAdd-subbox
    .subBox
    .check[type="checkbox"]
    + .checkContent
    .SalePrdAdd-inputbox,
.TSalePrdAddPage
    .SalePrdAdd-subbox
    .subBox
    .check[type="checkbox"]
    + .checkContent
    .inputbox {
    display: none;
    margin-top: 0.75rem;
}

.TSalePrdAddPage
    .SalePrdAdd-subbox
    .subBox
    .check:checked[type="checkbox"]
    + .checkContent
    .SalePrdAdd-inputbox {
    display: block;
}

.TSalePrdAddPage
    .SalePrdAdd-subbox
    .subBox
    .check:checked[type="checkbox"]
    + .checkContent
    .inputbox {
    display: flex;
}

.TSalePrdAddPage .SalePrdAdd-subbox .subBox .inputbox .range {
    align-items: center;
    gap: 0 0.75rem;
    font-size: 17px;
    color: var(--ColorTypeB);
}

.TSalePrdAddPage .SalePrdAdd-subbox .subBox .inputbox .datepicker-box {
    position: relative;
}

.TSalePrdAddPage
    .SalePrdAdd-subbox
    .subBox
    .inputbox
    .form-control.input-sale-datepicker {
    padding: 0.75rem 1rem;
    height: auto;
    padding-left: calc(1.25rem + 20px);
    width: 100%;
    max-width: 165px;
}

.TSalePrdAddPage
    .SalePrdAdd-subbox
    .subBox
    .inputbox
    .datepicker-box
    .calendar {
    z-index: 1;
    position: absolute;
    left: 1rem;
    top: 49%;
    transform: translateY(-50%);
    max-width: 15px;
}

.TSalePrdAddPage
    .SalePrdAdd-subbox
    .subBox
    .inputbox
    .form-control.input-datepicker {
    padding: 0.75rem 1rem;
    height: auto;
    /* padding-left: calc(1.25rem + 20px); */
    width: 100%;
    max-width: 165px;
}

.TSalePrdAddPage .SalePrdAdd-Select {
    border: 1px solid var(--ColorTypeD);
    border-radius: 3px;
    transition: all 0.4s ease;
    box-shadow: none;
}

.TSalePrdAddPage .SalePrdAdd-Select:hover,
.TSalePrdAddPage .SalePrdAdd-Select:focus {
    border-color: var(--ColorTypeB);
}

.TSalePrdAddPage .SalePrdAdd-Select .selectBtn {
    padding: 0.75rem 1.75rem;
    width: 100%;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 17px;
    font-weight: var(--Regular);
    color: var(--ColorTypeB);
}

.TSalePrdAddPage .SalePrdAdd-Select .selectBtn::after {
    content: "";
    background: url(https://www.flexdaycdn.net/public/images/ticket_ver2/accordion_arrow.png)
        no-repeat center right / 1rem;
    width: 1rem;
    height: 1rem;
    transition: all 0.2s ease-in-out;
}

.TSalePrdAddPage .SalePrdAdd-Select .selectBtn.collapsed::after {
    transform: rotate(180deg);
}

.TSalePrdAddPage .SalePrdAdd-Select .selectInputbox > input {
    display: none;
}

.TSalePrdAddPage .SalePrdAdd-Select .selectInputbox > label {
    padding: 0.75rem 1.75rem;
    font-size: 17px;
    font-weight: var(--Regular);
    color: var(--ColorTypeB);
    position: relative;
    width: 100%;
}

.TSalePrdAddPage .SalePrdAdd-Select .selectInputbox > label::before {
    content: "";
    position: absolute;
    left: -1px;
    top: 0;
    background-color: transparent;
    width: 4px;
    height: 100%;
}

.TSalePrdAddPage .SalePrdAdd-Select .selectInputbox > input:checked + label {
    color: var(--ColorTypeA);
}

.TSalePrdAddPage
    .SalePrdAdd-Select
    .selectInputbox
    > input:checked
    + label::before {
    background-color: var(--ColorTypeA);
}

.TSalePrdAddPage .SalePrdAdd-inputbox textarea.form-control.ph {
    padding: 2rem 1.5rem;
    line-height: 2;
}

.demo
    .TSalePrdAddPage
    .SalePrdAdd-inputbox.Input-placeholderbox
    .Input-placeholder {
    padding: 2rem 1.5rem;
    line-height: 2;
    font-weight: 300;
}

.TSalePrdAddPage .SalePrdAdd-radiobox .radioBox .form-check-input {
    border-radius: 50%;
}

.TSalePrdAddPage .SalePrdAdd-radiobox .radioBox .form-check-input:focus {
    border: 1px solid rgba(0, 0, 0, 0.25);
}

.TSalePrdAddPage
    .SalePrdAdd-radiobox
    .radioBox
    .form-check-input:focus:checked {
    border-color: var(--ColorTypeA);
}

.TSalePrdAddPage .SalePrdDes {
    display: none;
}

.TSalePrdAddPage .SalePrdDes.active {
    display: block;
}

.TSalePrdAddPage .prdDes-tabs {
    border: 1px solid var(--ColorTypeB);
    margin-bottom: 1rem;
    border-radius: 3px;
}

.TSalePrdAddPage .prdDes-tabs .nav-link {
    border-radius: 0;
    margin: 0;
    border: 0;
    color: var(--ColorTypeB);
    background-color: #fff;
    padding: 1rem 0.75rem;
    width: 20%;
    font-size: 17px;
    position: relative;
}

.TSalePrdAddPage .prdDes-tabs .nav-link.active {
    background-color: var(--ColorTypeB);
    color: #fff;
}

.TSalePrdAddPage
    .prdDes-tabs
    .nav-link:not(.active)
    + .nav-link:not(.active)::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 1px;
    height: 16px;
    background-color: var(--ColorTypeD);
}

.TSalePrdAddPage .prdDes-prdDes-content {
}

@media screen and (max-width: 599px) {
    .TSalePrdAddPage .SalePrdAdd-sub {
        font-size: 14px;
    }

    .TSalePrdAddPage .SalePrdAdd-subbox .subBox {
        gap: 0 1rem;
    }

    .TSalePrdAddPage
        .SalePrdAdd-subbox
        .subBox
        .check[type="checkbox"]
        + .checkContent
        .SalePrdAdd-inputbox,
    .TSalePrdAddPage
        .SalePrdAdd-subbox
        .subBox
        .check[type="checkbox"]
        + .checkContent
        .inputbox {
        margin-top: 0.5rem;
    }

    .TSalePrdAddPage .SalePrdAdd-subbox .subBox .inputbox .range {
        gap: 0 0.5rem;
        font-size: 14px;
    }

    .TSalePrdAddPage .SalePrdAdd-subbox .subBox .inputbox .datepicker-box img {
        display: none;
    }

    .TSalePrdAddPage
        .SalePrdAdd-subbox
        .subBox
        .inputbox
        .form-control.input-datepicker {
        font-size: 14px;
        padding: 0.75rem 0.5rem;
    }

    .TSalePrdAddPage .SalePrdAdd-Select .selectBtn {
        padding: 0.75rem 1.25rem;
        font-size: 14px;
    }

    .TSalePrdAddPage .SalePrdAdd-Select .selectInputbox > label {
        padding: 0.75rem 1.25rem;
        font-size: 14px;
    }

    .TSalePrdAddPage .SalePrdAdd-Select .selectInputbox > label::before {
        width: 3px;
    }

    .TSalePrdAddPage .SalePrdAdd-inputbox textarea.form-control.ph {
        padding: 1.5rem 1rem;
    }

    .demo
        .TSalePrdAddPage
        .SalePrdAdd-inputbox.Input-placeholderbox
        .Input-placeholder {
        padding: 1.5rem 1rem;
    }

    .TSalePrdAddPage .prdDes-tabs .nav-link {
        font-size: 13px;
        padding: 1rem 0.25rem;
    }
}

@media screen and (max-width: 320px) {
}
