.TSettlementPage .PayCateTab .nav-pills {
    border-bottom: 1px solid var(--ColorTypeE);
}

.TSettlementPage .PayCateBtn {
    font-weight: var(--Regular);
    font-size: 25px;
    color: var(--ColorTypeD);
    border-radius: 0;
    --bs-nav-link-padding-x: 0.5rem;
    position: relative;
}

.TSettlementPage .nav-pills .PayCateBtn.active {
    font-weight: var(--Bold);
    color: var(--ColorTypeB);
    border-radius: 0;
    background-color: transparent;
    border-bottom: 3px solid var(--ColorTypeA);
}

.TSettlementPage .PayCateBtn.active::before {
    content: "";
    position: absolute;
    right: 0;
    top: 0.2rem;
    width: 6px;
    height: 6px;
    background: var(--ColorTypeA);
    border-radius: 50%;
}

.TSettlementPage .SettleList .SettleTop1 {
    border-bottom: 20px solid var(--ColorTypeE);
}

.TSettlementPage .SettleList .SettleTop1 .Settle-sub {
    font-weight: var(--Medium);
    font-size: 27px;
    color: var(--ColorTypeC);
}

.TSettlementPage .SettleList .SettleTop1 .Settle-date {
    font-weight: var(--Medium);
    font-size: 27px;
    color: var(--ColorTypeB);
}

.TSettlementPage .SettleList .SettleTop1 .Settle-price {
    font-weight: var(--Medium);
    font-size: 32px;
    color: var(--ColorTypeB);
    word-break: break-word;
}

.TSettlementPage .SettleList .SettleTop1 .Settle-price span {
    font-weight: var(--Semibold);
    font-size: 60px;
    margin-right: 8px;
}

.TSettlementPage .SettleList .SettleTop1 .btn-default {
    height: 70px;
    font-size: 22px;
    border-radius: 50px;
    margin: 2rem 0 1rem;
}

.TSettlementPage .SettleList .SettleBottom1 .Listitem {
    border-bottom: 1px solid var(--ColorTypeE);
}

.TSettlementPage .SettleList .SettleBottom1 .Listitem:last-child {
    border-bottom: 0px;
}

.TSettlementPage .SettleList .SettleBottom1 .Listitem .time {
    font-weight: var(--Regular);
    font-size: 20px;
    color: var(--ColorTypeC);
    margin-bottom: 0;
}

.TSettlementPage .SettleList .SettleBottom1 .Listitem .cancel-txt {
    font-weight: var(--Regular);
    font-size: 20px;
    color: var(--ColorTypeA);
    margin-bottom: 0;
}

.TSettlementPage .SettleList .SettleBottom1 .Listitem img.logo {
    width: 85px;
    align-self: center;
}

.TSettlementPage .SettleList .SettleBottom1 .Listitem .txtbox {
    width: calc(100% - 115px);
}

.TSettlementPage .SettleList .SettleBottom1 .Listitem .txtbox .name {
    font-weight: var(--Medium);
    font-size: 22px;
    color: var(--ColorTypeC);
    word-break: break-word;
}

.TSettlementPage .SettleList .SettleBottom1 .Listitem .txtbox .t-num {
    font-weight: var(--Medium);
    font-size: 18px;
    color: var(--ColorTypeC);
}

.TSettlementPage .SettleList .SettleBottom1 .Listitem .txtbox .price {
    font-weight: var(--Medium);
    font-size: 25px;
    color: var(--ColorTypeB);
    word-break: break-word;
    margin-bottom: 0;
}

.TSettlementPage .SettleList .SettleBottom1 .Listitem .txtbox .price span.num {
    font-size: 32px;
}

.TSettlementPage .SettleList .SettleBottom1 .Listitem.cancel .txtbox .price{
   color: var(--ColorTypeA);
}

.TSettlementPage .SettleList .SettleBottom1 .NoListitem {
    padding: 15vh 0;
}

.TSettlementPage .SettleList .SettleBottom1 .NoListitem  img.icon{
    width: 50px;
    margin-bottom: 1.5rem;
}

.TSettlementPage .SettleList .SettleBottom1 .NoListitem .desc {
    font-weight: var(--Medium);
    font-size: 25px;
    color: var(--ColorTypeD);
    margin-bottom: 0;
}

.TSettlementPage .SettleList .SettleTop2 {
    border-bottom: 20px solid var(--ColorTypeE);
}

.TSettlementPage .SettleList .SettleTop2 img.smile {
    width: 65px;
}

.TSettlementPage .SettleList .SettleTop2 .PageTit {
    font-size: 27px;
}

.TSettlementPage .SettleList .SettleTop2 .PageTit .color {
    color: var(--ColorTypeA);
}

.TSettlementPage .SettleList .SettleTop2 .accordion {
    --bs-accordion-btn-icon: url(https://www.flexdaycdn.net/public/images/static/login/drop_check2.png);
    --bs-accordion-btn-active-icon: url(https://www.flexdaycdn.net/public/images/static/login/drop_check2.png);
    --bs-accordion-btn-icon-width: 1rem;
    --bs-accordion-btn-icon-transform: rotate(180deg);
    --bs-accordion-btn-icon-transform2: rotate(180deg);
}

.TSettlementPage .SettleList .SettleTop2 .accordion-button {
    color: var(--ColorTypeC);
    font-weight: var(--Medium);
    font-size: 22px;
}

.TSettlementPage .SettleList .SettleTop2 .accordion-button::after {
    background-position: center;
    margin-left: 8px;
}

.TSettlementPage .Calendar {
    border: 1px solid var(--ColorTypeD);
    border-radius: 10px;
    font-weight: var(--Medium);
    font-size: 18px;
    color: var(--ColorTypeB);
}

.TSettlementPage .Calendar .ColorA {
    color: var(--ColorTypeA);
}

.TSettlementPage .Calendar .ColorF {
    color: #293eb7;
}

.TSettlementPage .Calendar .Week-NowDate {
    background-color: rgb(237 21 86 / 10%);
    border-radius: 50px;
}

.TSettlementPage .Calendar .Week-NextDate .active span {
    position: relative;
    color: #fff;
    z-index: 0;
}

.TSettlementPage .Calendar .Week-NextDate .active span::before {
    content: "";
    background-color: rgb(237 21 86 / 10%);
    border-radius: 50%;
    width: 50px;
    height: 50px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
}

.TSettlementPage .Calendar .Week-NextDate .active span::after {
    content: "";
    background-color: var(--ColorTypeA);
    border-radius: 50%;
    width: 35px;
    height: 35px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: -2;
}

.TSettlementPage .SettleList .SettleBottom2 .Listitem {
    border-bottom: 1px solid var(--ColorTypeE);
}

.TSettlementPage .SettleList .SettleBottom2 .Listitem:last-child {
    border-bottom: 0px;
}

.TSettlementPage .SettleList .SettleBottom2 .Listitem .txtbox {
    width: calc(100% - 145px);
    word-break: break-word;
}

.TSettlementPage .SettleList .SettleBottom2 .Listitem .txtbox .date {
    font-weight: var(--Medium);
    font-size: 25px;
    color: var(--ColorTypeC);
}

.TSettlementPage .SettleList .SettleBottom2 .Listitem .txtbox .price {
    font-weight: var(--Medium);
    font-size: 25px;
    color: var(--ColorTypeB);
    margin-bottom: 0;
}

.TSettlementPage .SettleList .SettleBottom2 .Listitem .txtbox .price span {
    font-weight: var(--Semibold);
    font-size: 40px;
}

.TSettlementPage .SettleList .SettleBottom2 .Listitem .btnbox {
    width: 135px;
}

.TSettlementPage .SettleList .SettleBottom2 .Listitem .btnbox span {
    font-weight: var(--Medium);
    font-size: 27px;
    color: var(--ColorTypeB);
}

.TSettlementPage .SettleList .SettleBottom2 .Listitem .btnbox img.arrow {
    width: 15px;
    margin-left: 20px;
    align-self: center;
}

.TSettlementPage .SettleList .SettleBottom2 .Listitem.hold .btnbox span {
    color: #d80c07;
}

.TSettlementPage .SettleList .SettleBottom2 .Listitem.done .btnbox span {
    color: #293eb7;
}


/* 모바일 반응형 */

@media screen and (max-width: 599px) {  

    .TSettlementPage .PayCateBtn {
        font-size: 20px;
    }

    .TSettlementPage .SettleList .SettleTop1 {
        border-bottom: 15px solid var(--ColorTypeE);
    }


    .TSettlementPage .SettleList .SettleTop1 .Settle-sub {
        font-size: 20px;
    }

    .TSettlementPage .SettleList .SettleTop1 .Settle-date {
        font-size: 18px;
    }

    .TSettlementPage .SettleList .SettleTop1 .Settle-price {
        font-size: 20px;
    }

    .TSettlementPage .SettleList .SettleTop1 .Settle-price span {
        font-size: 35px;
        margin-right: 5px;
    }

    .TSettlementPage .SettleList .SettleTop1 .btn-default {
        height: 50px;
        font-size: 14px;
    }

    .TSettlementPage .SettleList .SettleBottom1 .Listitem img.logo {
        width: 50px;
    }

    .TSettlementPage .SettleList .SettleBottom1 .Listitem .txtbox {
        width: calc(100% - 70px);
    }

    .TSettlementPage .SettleList .SettleBottom1 .Listitem .time {
        font-size: 15px;
    }
    
    .TSettlementPage .SettleList .SettleBottom1 .Listitem .cancel-txt {
        font-size: 15px;
    }

    .TSettlementPage .SettleList .SettleBottom1 .Listitem .txtbox .name {
        font-size: 17px;
    }

    .TSettlementPage .SettleList .SettleBottom1 .Listitem .txtbox .t-num {
        font-size: 13px;
    }

    .TSettlementPage .SettleList .SettleBottom1 .Listitem .txtbox .price {
        font-size: 20px;
    }

    .TSettlementPage .SettleList .SettleBottom1 .Listitem .txtbox .price span.num {
        font-size: 25px;
    }

    .TSettlementPage .SettleList .SettleBottom1 .Listitem.cancel .txtbox .price>span::before {
        height: 2px;
    }

    .TSettlementPage .SettleList .SettleBottom1 .NoListitem {
        padding: 8vh 0;
    }
    
    .TSettlementPage .SettleList .SettleBottom1 .NoListitem  img.icon{
        width: 40px;
        margin-bottom: 1rem;
    }
    
    .TSettlementPage .SettleList .SettleBottom1 .NoListitem .desc {
        font-size: 20px;
    }

    .TSettlementPage .SettleList .SettleTop2 {
        border-bottom: 15px solid var(--ColorTypeE);
    }

    .TSettlementPage .SettleList .SettleTop2 img.smile {
        width: 45px;
    }

    .TSettlementPage .SettleList .SettleTop2 .PageTit {
        font-size: 18px;
    }

    .TSettlementPage .SettleList .SettleTop2 .accordion-button {
        font-size: 15px;
    }

    .TSettlementPage .Calendar {
        font-size: 13px;
    }

    .TSettlementPage .Calendar .Week-NextDate .active span::before {
        width: 35px;
        height: 35px;
    }

    .TSettlementPage .Calendar .Week-NextDate .active span::after {
        width: 25px;
        height: 25px;
    }

    .TSettlementPage .SettleList .SettleBottom2 .Listitem .txtbox {
        width: calc(100% - 100px);
    }

    .TSettlementPage .SettleList .SettleBottom2 .Listitem .txtbox .date {
        font-size: 17px;
    }

    .TSettlementPage .SettleList .SettleBottom2 .Listitem .txtbox .price {
        font-size: 18px;
    }

    .TSettlementPage .SettleList .SettleBottom2 .Listitem .txtbox .price span {
        font-size: 27px;
    }

    .TSettlementPage .SettleList .SettleBottom2 .Listitem .btnbox {
        width: 90px;
    }

    .TSettlementPage .SettleList .SettleBottom2 .Listitem .btnbox span {
        font-size: 18px;
    }

    .TSettlementPage .SettleList .SettleBottom2 .Listitem .btnbox img.arrow {
        width: 10px;
        margin-left: 10px;
    }


}

@media screen and (max-width: 320px) {

    .TSettlementPage .SettleList .SettleTop1 .btn-default {
        height: 40px;
    }

}

.TSettlementPage .PayCateBtn {
    line-height: 1.15;
}
.TSettlementPage .SettleList .SettleTop2 .accordion-button {
    line-height: 1.15;
}
.TSettlementPage .tab-content {
    padding-top: 0;
}