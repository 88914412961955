.TSalePrdPage .SalePrdBox .SalePrd-Btnbox {
  gap: 0 10px;
  margin-bottom: 3rem;
}

.TSalePrdPage .SalePrdBox .SalePrd-Item {
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #999999;
  padding-bottom: 2rem;
  margin-bottom: 2rem;
}

.TSalePrdAddPage .SalePrdAdd-subbox .subBox .inputbox.disabled .form-control {
  pointer-events: none;
  background-color: var(--ColorTypeE);
}

/* .TSalePrdPage .SalePrdBox {
  background: #fff;
  border: 1px solid var(--ColorTypeD);
  border-top: 0;
} */

.TSalePrdPage .SalePrdBox .SalePrd-Item:last-child {
  border-bottom: 0;
  padding-bottom: 0;
}

.TSalePrdPage .SalePrdBox .SalePrd-Item .prd-infobox {
  width: 67%;
  padding-right: 10%;
  box-sizing: border-box;
}

.TSalePrdPage .SalePrdBox .SalePrd-Item .prd-infobox .prd-tit {
  font-weight: var(--Medium);
  color: var(--ColorTypeB);
  font-size: 28px;
  margin-bottom: 0.3rem;
  word-break: break-word;
}

.TSalePrdPage .SalePrdBox .SalePrd-Item .prd-infobox .prd-tit .state {
  background-color: #293eb7;
  color: #fff;
  font-size: 18px;
  line-height: 1;
  font-weight: var(--Regular);
  padding: 0.2rem 0.75rem;
  border-radius: 0.2em;
  display: inline-block;
  vertical-align: middle;
  margin-left: 0.35rem;
}

.TSalePrdPage .SalePrdBox .SalePrd-Item.stop .prd-infobox .prd-tit .state {
  background-color: #d80c07;
}

.TSalePrdPage .SalePrdBox .SalePrd-Item .prd-infobox .prd-desc {
  font-weight: var(--Regular);
  color: var(--ColorTypeC);
  font-size: 20px;
  margin-bottom: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.TSalePrdPage .SalePrdBox .SalePrd-Item .prd-btnbox {
  width: 33%;
  gap: 0 0.75rem;
}

.TSalePrdPage .SalePrdBox .SalePrd-Item .prd-btnbox .btn {
  height: auto;
  border-radius: 30px;
  font-weight: var(--Medium);
}

/* 모바일 반응형 */
@media screen and (max-width: 599px) {
  .demo .TSalePrdPage .SalePrdBox.px-4-5 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .TSalePrdPage .SalePrdBox .SalePrd-Btnbox .btn {
    height: 40px;
  }

  .TSalePrdPage .SalePrdBox .SalePrd-Item {
    padding-bottom: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .TSalePrdPage .SalePrdBox .SalePrd-Item .prd-infobox {
    width: calc(100% - 140px);
    padding-right: 0;
  }

  .TSalePrdPage .SalePrdBox .SalePrd-Item .prd-infobox .prd-tit {
    font-size: 22px;
  }

  .TSalePrdPage .SalePrdBox .SalePrd-Item .prd-infobox .prd-tit .state {
    font-size: 15px;
    padding: 0.2rem 0.6rem;
  }

  .TSalePrdPage .SalePrdBox .SalePrd-Item .prd-infobox .prd-desc {
    font-size: 16px;
  }

  .TSalePrdPage .SalePrdBox .SalePrd-Item .prd-btnbox {
    width: 130px;
  }

  .TSalePrdPage .SalePrdBox .SalePrd-Item .prd-btnbox .btn {
    padding: 0.25rem 0.5rem;
  }
}

.TSalePrdPage .SalePrdBox .SalePrd-Btnbox .btn {
  font-size: 22px;
  font-weight: var(--Semibold);
}

.TSalePrdPage .SalePrdBox .SalePrd-Btnbox .btn-light {
  border-color: var(--ColorTypeB);
  border-width: 2px;
}

.TSalePrdPage .SalePrdBox .SalePrd-Item {
  padding-bottom: 1.8rem;
  margin-bottom: 1.8rem;
}

.TSalePrdPage .SalePrdBox .SalePrd-Item:last-child {
  border-bottom: 1px solid #999999;
  padding-bottom: 1.8rem;
}

.TSalePrdPage .SalePrdBox .SalePrd-Item .prd-infobox .prd-tit {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0 0.75rem;
}

.TSalePrdPage .SalePrdBox .SalePrd-Item .prd-infobox .prd-tit .state {
  background-color: #4d56ff;
  display: inline-flex;
  align-items: center;
  margin-left: 0;
}

.TSalePrdPage .SalePrdBox .SalePrd-Item .prd-btnbox .btn {
  padding: 0.25rem 0.75rem;
}

.TSalePrdPage .SalePrdBox .SalePrd-Item .prd-btnbox .btn.btn-light {
  border-color: var(--ColorTypeB);
  border-width: 2px;
}


@media screen and (max-width: 599px) {
  .TSalePrdPage .SalePrdBox .SalePrd-Btnbox .btn {
    font-size: 17px;
    font-weight: var(--Medium);
  }

  .TSalePrdPage .SalePrdBox .SalePrd-Btnbox .btn-light {
    border-width: 1px;
  }

  .TSalePrdPage .SalePrdBox .SalePrd-Item {
    padding-bottom: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .TSalePrdPage .SalePrdBox .SalePrd-Item:last-child {
    padding-bottom: 1.5rem;
  }

  .TSalePrdPage .SalePrdBox .SalePrd-Item .prd-btnbox .btn.btn-light {
    border-width: 1px;
  }
}
