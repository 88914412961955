.TPaymentCancelPage .CancelBox  {
    margin-top: 12vh;
}

.TPaymentCancelPage .CancelBox img {
    max-width: 75px;
}

.TPaymentCancelPage .PageTit2 {
    font-size: 35px;
    line-height: 1.3;
}

.TPaymentCancelPage .PageSub2 {
    font-weight: var(--Medium);
    color: var(--ColorTypeB);
    font-size:18px;
}

.TPaymentCancelPage .PageSub {
    font-weight: var(--Medium);
    color: var(--ColorTypeC);
    font-size:18px;
}

.TPaymentCancelPage .PaymentResult {
    background-color: #f7f7f7;
    border-radius: 3px;
}

.TPaymentCancelPage .PaymentResult-Tit {
    font-weight: var(--Medium);
    font-size: 25px;
    color: var(--ColorTypeB);
    border-bottom: 1px solid var(--ColorTypeC);
    margin-bottom: 30px;
}

.TPaymentCancelPage .PaymentResult-Item .tit {
    font-weight: var(--Medium);
    font-size: 20px;
    color: var(--ColorTypeB);
}

.TPaymentCancelPage .PaymentResult-Item .price {
    font-weight: var(--Medium);
    font-size: 20px;
    color: var(--ColorTypeB);
    word-break: break-word;
}

.TPaymentCancelPage .CancelConfirm {
    gap: 0 10px;
}


/* 모바일 반응형 */

@media screen and (max-width: 599px) {

    .TPaymentCancelPage .CancelBox img {
        max-width: 65px;
    }

    .TPaymentCancelPage .PageTit2 {
        font-size: 30px;
    }

    .TPaymentCancelPage .PageSub {
        font-size:15px;
    }
    .TPaymentCancelPage .PageSub2 {
        font-size:15px;
    }

    .TPaymentCancelPage .PaymentResult-Tit {
        font-size: 18px;
        margin-bottom: 20px;
    }

    .TPaymentCancelPage .PaymentResult-Item .tit {
        font-size: 14px;
    }

    .TPaymentCancelPage .PaymentResult-Item .price {
        font-size: 14px;
    }

    .TPaymentCancelPage .btn-default,
    .TPaymentCancelPage .btn-light {
        height: 45px;
    }

}

@media screen and (max-width: 320px) {

    .TPaymentCancelPage .btn-default,
    .TPaymentCancelPage .btn-light {
        height: 40px;
    }

}