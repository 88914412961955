.TSettleDetailPage .DetailBoxContainer {
  height: 100vh;
  width: 100%;
  background: #fff;
  border: 1px solid var(--ColorTypeD);
  border-top: 0;
  overflow: scroll;
}

.TSettleDetailPage .DetailBoxContainer::-webkit-scrollbar {
  display: none;
}

@media screen and (max-width: 1024px) {
  .TSettleDetailPage .DetailBoxContainer {
    border: none;
  }
}

@media screen and (min-width: 599px) {
  .TSettleDetailPage .DetailBoxContainer {
    height: 854px;
    width: 100%;
  }
}

.TSettleDetailPage .DetailBox .Detail-1 {
  border-bottom: 2px solid var(--ColorTypeE);
}

.TSettleDetailPage .DetailBox .Detail-1 .week {
  font-weight: var(--Medium);
  font-size: 27px;
  color: var(--ColorTypeB);
}

.TSettleDetailPage .DetailBox .Detail-1 .week img.calendar {
  width: 25px;
  margin-right: 15px;
}

.TSettleDetailPage .DetailBox .Detail-1 .period {
  font-weight: var(--Regular);
  font-size: 22px;
  color: var(--ColorTypeC);
}

.TSettleDetailPage .DetailBox .Detail-2 {
  border-bottom: 2px solid var(--ColorTypeE);
}

.TSettleDetailPage .DetailBox .Detail-2 .state span {
  font-weight: var(--Medium);
  font-size: 22px;
  color: var(--ColorTypeB);
  background-color: var(--ColorTypeD);
  border-radius: 5px;
}

.TSettleDetailPage.hold .DetailBox .Detail-2 .state span {
  color: #d80c07;
  background-color: #ffc5c5;
}

.TSettleDetailPage.done .DetailBox .Detail-2 .state span {
  color: #293eb7;
  background-color: #bbcbfc;
}

.TSettleDetailPage .DetailBox .Detail-2 .txt {
  font-weight: var(--Medium);
  font-size: 25px;
  color: var(--ColorTypeB);
}

.TSettleDetailPage .DetailBox .Detail-2 .price {
  font-weight: var(--Medium);
  font-size: 32px;
  color: #293eb7;
  word-break: break-word;
}

.TSettleDetailPage .DetailBox .Detail-2 .price span {
  font-weight: var(--Semibold);
  font-size: 60px;
}

.TSettleDetailPage .DetailBox .Detail-3 .tit {
  font-weight: var(--Medium);
  font-size: 25px;
  color: var(--ColorTypeC);
  width: 150px;
}

.TSettleDetailPage .DetailBox .Detail-3 .tit img.icon {
  width: 12px;
  align-self: center;
  margin-top: -10px;
  margin-right: 8px;
}

.TSettleDetailPage .DetailBox .Detail-3 .desc {
  font-weight: var(--Medium);
  font-size: 22px;
  color: var(--ColorTypeB);
  width: calc(100% - 160px);
  text-align: right;
  word-break: break-word;
}

.TSettleDetailPage .DetailBox .Detail-3 .desc span {
  font-size: 27px;
  font-weight: var(--Semibold);
}

.TSettleDetailPage .DetailBox2 {
  border-top: 20px solid var(--ColorTypeE);
  margin-bottom: 10vh;
}

.TSettleDetailPage.hold .DetailBox2 {
  border-top: 0px;
}

.TSettleDetailPage .DetailInfotxt {
  font-weight: var(--Regular);
  font-size: 22px;
  color: var(--ColorTypeB);
}

.TSettleDetailPage .DetailBox2 .Detail-4 .tit {
  font-weight: var(--Medium);
  font-size: 25px;
  color: var(--ColorTypeC);
}

.TSettleDetailPage .DetailBox2 .Detail-4 .desc {
  font-weight: var(--Medium);
  font-size: 22px;
  color: var(--ColorTypeB);
}

.TSettleDetailPage .DetailBox2 .Detail-4 .btn-default {
  font-size: 18px;
  padding: 0.375rem 0.75rem;
}

.TSettleDetailPage.hold .DetailBox2 .Detail-4 .desc.date {
  color: #d80c07;
}

.TSettleDetailPage .mob320 {
  display: none;
}

.TSettleDetailPage.hold .DetailHoldbox {
  display: block;
  background-color: var(--ColorTypeE);
}

.TSettleDetailPage.hold .DetailHoldbox .tit {
  font-weight: var(--Medium);
  font-size: 25px;
  color: var(--ColorTypeB);
}

.TSettleDetailPage.hold .DetailHoldbox .tit img.icon {
  width: 25px;
  display: block;
  margin: 0 auto 0.5rem;
}

.TSettleDetailPage.hold .DetailHoldbox .desc {
  font-weight: var(--Regular);
  font-size: 20px;
  color: var(--ColorTypeB);
}

/* 모바일 반응형 */

@media screen and (max-width: 599px) {
  .TSettleDetailPage .DetailBox .Detail-1 .week {
    font-size: 20px;
  }

  .TSettleDetailPage .DetailBox .Detail-1 .week img.calendar {
    width: 20px;
    margin-right: 10px;
  }

  .TSettleDetailPage .DetailBox .Detail-1 .period {
    font-size: 15px;
  }

  .TSettleDetailPage .DetailBox .Detail-2 .state span {
    font-size: 15px;
  }

  .TSettleDetailPage .DetailBox .Detail-2 .txt {
    font-size: 17px;
  }

  .TSettleDetailPage .DetailBox .Detail-2 .price {
    font-size: 20px;
  }

  .TSettleDetailPage .DetailBox .Detail-2 .price span {
    font-size: 35px;
  }

  .TSettleDetailPage .DetailBox .Detail-3 .tit img.icon {
    width: 9px;
    margin-top: -9px;
    margin-right: 5px;
  }

  .TSettleDetailPage .DetailBox .Detail-3 .tit {
    font-size: 17px;
    width: 85px;
  }

  .TSettleDetailPage .DetailBox .Detail-3 .desc {
    font-size: 15px;
    width: calc(100% - 95px);
  }

  .TSettleDetailPage .DetailBox .Detail-3 .desc span {
    font-size: 20px;
  }

  .TSettleDetailPage .DetailBox2 .Detail-4 .tit {
    font-size: 17px;
  }

  .TSettleDetailPage .DetailBox2 .Detail-4 .desc {
    font-size: 15px;
  }

  .TSettleDetailPage .DetailBox2 .Detail-4 .btn-default {
    font-size: 14px;
  }

  .TSettleDetailPage .DetailInfotxt {
    font-size: 15px;
  }

  .TSettleDetailPage.hold .DetailHoldbox .tit {
    font-size: 20px;
  }

  .TSettleDetailPage.hold .DetailHoldbox .tit img.icon {
    width: 20px;
    margin: 0 auto 0.25rem;
  }

  .TSettleDetailPage.hold .DetailHoldbox .desc {
    font-size: 15px;
  }
}

@media screen and (max-width: 320px) {
  .TSettleDetailPage .mob320 {
    display: block;
  }
}

/* 23-01-16 수정 */

.TSettleDetailPage .DetailBox .Detail-1 .week {
  font-size: 22px;
}

.TSettleDetailPage .DetailBox .Detail-1 .week img.calendar {
  width: 25px;
  margin-right: 10px;
}

.TSettleDetailPage .DetailBox .Detail-2 .state span {
  font-size: 20px;
}

.TSettleDetailPage .DetailBox .Detail-2 .price span {
  font-size: 48px;
}

.TSettleDetailPage .DetailBox .Detail-3 {
  padding: 1.5rem 0 3rem !important;
}

.TSettleDetailPage .DetailBox .Detail-3 .tit {
  font-size: 20px;
  width: 170px;
}

.TSettleDetailPage .DetailBox .Detail-3 .tit img.icon {
  width: 10px;
  margin-top: -5px;
  margin-right: 6px;
}

.TSettleDetailPage .DetailBox .Detail-3 .desc {
  font-size: 20px;
  width: calc(100% - 180px);
}

.TSettleDetailPage .DetailBox .Detail-3 .desc span {
  font-size: 24px;
}

.TSettleDetailPage .DetailBox2 {
  border-top: 1rem solid #f8f8f8;
  position: relative;
  margin-bottom: 6rem;
}

.TSettleDetailPage .DetailBox2::before {
  content: "";
  position: absolute;
  left: 0;
  top: -1rem;
  width: 100%;
  height: 5px;
  background-color: var(--ColorTypeE);
}

.TSettleDetailPage .DetailBox2 .Detail-4 .tit {
  font-size: 22px;
}

.TSettleDetailPage .DetailBox2 .Detail-4 .desc {
  font-size: 20px;
}

.TSettleDetailPage .DetailInfotxt {
  font-size: 18px;
}

@media screen and (max-width: 599px) {
  .TSettleDetailPage .DetailBox .Detail-1 .week {
    font-size: 20px;
  }

  .TSettleDetailPage .DetailBox .Detail-1 .week img.calendar {
    width: 20px;
    margin-right: 7px;
  }

  .TSettleDetailPage .DetailBox .Detail-2 .state span {
    font-size: 15px;
  }

  .TSettleDetailPage .DetailBox .Detail-2 .price span {
    font-size: 35px;
  }

  .TSettleDetailPage .DetailBox .Detail-3 .tit {
    font-size: 17px;
    width: 125px;
  }

  .TSettleDetailPage .DetailBox .Detail-3 .desc {
    font-size: 15px;
    width: calc(100% - 135px);
  }

  .TSettleDetailPage .DetailBox .Detail-3 .desc span {
    font-size: 22px;
  }

  .TSettleDetailPage .DetailBox2 .Detail-4 .tit {
    font-size: 17px;
  }

  .TSettleDetailPage .DetailBox2 .Detail-4 .desc {
    font-size: 16px;
  }

  .TSettleDetailPage .DetailInfotxt {
    font-size: 16px;
  }

  .TSettleDetailPage .DetailBoxContainer {
    border: none;
  }
}

/* ver2 */

.TSettleDetailPage .DetailBox .Detail-2 .price {
  color: #4d56ff;
}

.TSettleDetailPage.done .DetailBox .Detail-2 .state span {
  color: #4d56ff;
  background-color: #c9d6f7;
}
