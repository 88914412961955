.DatepickerWrapWidth {
    width: 80%;
}

.DatepickerBox .react-datepicker-wrapper {
    width: inherit;
}

.DatepickerBox .react-datepicker__tab-loop {
    position: absolute;
    left: 0;
    top: 0;
    width: inherit;
}

.DatepickerBox .react-datepicker-popper {
    width: inherit;
}

.DatepickerBox .react-datepicker-popper > div {
    width: inherit;
}

.DatepickerBox .react-datepicker {
    font-family: inherit;
    border: 1px solid var(--ColorTypeD);
    padding: 1.5rem 1rem;
    font-size: 14px;
    width: inherit;
}

.DatepickerBox .react-datepicker-popper .react-datepicker__triangle {
    display: none;
}

.DatepickerBox.ver1 > .d-flex {
    gap: 0 0.75rem;
    font-size: 17px;
}

.DatepickerBox.ver1 .react-datepicker__header {
    background-color: #fff;
    border-bottom: 0px;
    padding: 0;
}

.DatepickerBox.ver1 .react-datepicker__header .header {
    margin-bottom: 0.75rem;
}

.DatepickerBox.ver1 .react-datepicker__header .header .month-day {
    font-size: 16px;
    font-weight: var(--Medium);
    margin-left: 0.5em;
}

.DatepickerBox.ver1 .react-datepicker__header .header .btn-group {
    margin-right: 0.25em;
}

.DatepickerBox.ver1 .react-datepicker__header .header .btn-group .btn_month {
    width: 10px;
    margin: 0 8px;
    cursor: pointer;
}

.DatepickerBox.ver1 .react-datepicker__month {
    margin: 0;
}

.DatepickerBox.ver1 .react-datepicker__day-names {
    margin-bottom: 0;
}

.DatepickerBox.ver1 .react-datepicker__day-name,
.DatepickerBox.ver1 .react-datepicker__day,
.DatepickerBox.ver1 .react-datepicker__time-name {
    font-weight: var(--Medium);
    margin: 0.1rem 0.166rem;
}

.DatepickerBox.ver1 .react-datepicker__day--disabled {
    opacity: 0.3;
}

.DatepickerBox.ver1 .react-datepicker__day-name:first-child {
    color: #d80c07;
}

.DatepickerBox.ver1 .react-datepicker__day-name:last-child {
    color: #293eb7;
}

.DatepickerBox.ver1 .react-datepicker__day--outside-month {
    opacity: 0;
}

.DatepickerBox.ver1 .react-datepicker__month--selected,
.DatepickerBox.ver1 .react-datepicker__month--in-selecting-range,
.DatepickerBox.ver1 .react-datepicker__month--in-range,
.DatepickerBox.ver1 .react-datepicker__quarter--selected,
.DatepickerBox.ver1 .react-datepicker__quarter--in-selecting-range,
.DatepickerBox.ver1 .react-datepicker__quarter--in-range {
    border-radius: 0.3rem;
    background-color: #216ba5;
    color: #fff;
}

.DatepickerBox.ver1 .react-datepicker__month--selected:hover,
.DatepickerBox.ver1 .react-datepicker__month--in-selecting-range:hover,
.DatepickerBox.ver1 .react-datepicker__month--in-range:hover,
.DatepickerBox.ver1 .react-datepicker__quarter--selected:hover,
.DatepickerBox.ver1 .react-datepicker__quarter--in-selecting-range:hover,
.DatepickerBox.ver1 .react-datepicker__quarter--in-range:hover {
    background-color: #1d5d90;
}

.DatepickerBox.ver1 .react-datepicker__month--disabled,
.DatepickerBox.ver1 .react-datepicker__quarter--disabled {
    color: #ccc;
    pointer-events: none;
}

.DatepickerBox.ver1 .react-datepicker__day:hover,
.DatepickerBox.ver1 .react-datepicker__month-text:hover,
.DatepickerBox.ver1 .react-datepicker__quarter-text:hover,
.DatepickerBox.ver1 .react-datepicker__year-text:hover {
    border-radius: 50%;
    background-color: #f0f0f0;
}

.DatepickerBox.ver1 .react-datepicker__day--selected,
.DatepickerBox.ver1 .react-datepicker__day--in-selecting-range,
.DatepickerBox.ver1 .react-datepicker__day--in-range,
.DatepickerBox.ver1 .react-datepicker__month-text--selected,
.DatepickerBox.ver1 .react-datepicker__month-text--in-selecting-range,
.DatepickerBox.ver1 .react-datepicker__month-text--in-range,
.DatepickerBox.ver1 .react-datepicker__quarter-text--selected,
.DatepickerBox.ver1 .react-datepicker__quarter-text--in-selecting-range,
.DatepickerBox.ver1 .react-datepicker__quarter-text--in-range,
.DatepickerBox.ver1 .react-datepicker__year-text--selected,
.DatepickerBox.ver1 .react-datepicker__year-text--in-selecting-range,
.DatepickerBox.ver1 .react-datepicker__year-text--in-range {
    border-radius: 50%;
    background-color: #fff;
    color: var(--ColorTypeA);
}

.DatepickerBox.ver1
    .react-datepicker__day--in-selecting-range.react-datepicker__day--selecting-range-start,
.DatepickerBox.ver1
    .react-datepicker__day--in-selecting-range.react-datepicker__day--selecting-range-end {
    border-radius: 50%;
    background-color: var(--ColorTypeA);
    color: #fff;
}

.DatepickerBox.ver1 .react-datepicker__day--selected:hover,
.DatepickerBox.ver1 .react-datepicker__day--in-selecting-range:hover,
.DatepickerBox.ver1 .react-datepicker__day--in-range:hover,
.DatepickerBox.ver1 .react-datepicker__month-text--selected:hover,
.DatepickerBox.ver1 .react-datepicker__month-text--in-selecting-range:hover,
.DatepickerBox.ver1 .react-datepicker__month-text--in-range:hover,
.DatepickerBox.ver1 .react-datepicker__quarter-text--selected:hover,
.DatepickerBox.ver1 .react-datepicker__quarter-text--in-selecting-range:hover,
.DatepickerBox.ver1 .react-datepicker__quarter-text--in-range:hover,
.DatepickerBox.ver1 .react-datepicker__year-text--selected:hover,
.DatepickerBox.ver1 .react-datepicker__year-text--in-selecting-range:hover,
.DatepickerBox.ver1 .react-datepicker__year-text--in-range:hover {
    background-color: var(--ColorTypeA);
}

.DatepickerBox.ver1 .react-datepicker__day--keyboard-selected,
.DatepickerBox.ver1 .react-datepicker__month-text--keyboard-selected,
.DatepickerBox.ver1 .react-datepicker__quarter-text--keyboard-selected,
.DatepickerBox.ver1 .react-datepicker__year-text--keyboard-selected {
    border-radius: 50%;
    background-color: #fff;
    color: var(--ColorTyepB);
}

.DatepickerBox.ver1 .react-datepicker__day--keyboard-selected:hover,
.DatepickerBox.ver1 .react-datepicker__month-text--keyboard-selected:hover,
.DatepickerBox.ver1 .react-datepicker__quarter-text--keyboard-selected:hover,
.DatepickerBox.ver1 .react-datepicker__year-text--keyboard-selected:hover {
    background-color: #1d5d90;
}

.DatepickerBox.ver1
    .react-datepicker__day--in-selecting-range:not(
        .react-datepicker__day--in-range,
        .react-datepicker__month-text--in-range,
        .react-datepicker__quarter-text--in-range,
        .react-datepicker__year-text--in-range
    ),
.DatepickerBox.ver1
    .react-datepicker__month-text--in-selecting-range:not(
        .react-datepicker__day--in-range,
        .react-datepicker__month-text--in-range,
        .react-datepicker__quarter-text--in-range,
        .react-datepicker__year-text--in-range
    ),
.DatepickerBox.ver1
    .react-datepicker__quarter-text--in-selecting-range:not(
        .react-datepicker__day--in-range,
        .react-datepicker__month-text--in-range,
        .react-datepicker__quarter-text--in-range,
        .react-datepicker__year-text--in-range
    ),
.DatepickerBox.ver1
    .react-datepicker__year-text--in-selecting-range:not(
        .react-datepicker__day--in-range,
        .react-datepicker__month-text--in-range,
        .react-datepicker__quarter-text--in-range,
        .react-datepicker__year-text--in-range
    ) {
    background-color: rgb(237 21 86 / 10%);
    color: var(--ColorTyepB);
}

.DatepickerBox .form-control.InputType.input-datepicker {
    text-align: center;
    text-align-last: center;
    -ms-text-align-last: center;
    -moz-text-align-last: center;
}

.DatepickerBox.ver1 .form-control.InputType.input-datepicker.sale-prd-add {
    padding: 0.75rem 1rem;
    height: auto;
    /* padding-left: calc(1.25rem + 20px); */
    width: 100%;
    max-width: 165px;
}

.DatepickerBox.ver1 .form-control.InputType.input-datepicker.prd-admin {
    width: 100%;
    border: 2px solid var(--ColorTypeB);
    padding: 1rem;
    padding-left: calc(1.25rem + 20px);
    border-radius: 3px;
    font-weight: var(--Medium);
    font-size: 18px;
    line-height: 1;
    height: auto;
    background: url(https://www.flexdaycdn.net/public/images/ticket/calendar3.png)
        no-repeat calc(1rem + 2%) center / 1rem;
}

.DatepickerBox.ver1 > .d-flex.ver2 {
    gap: 0 1.5rem;
    font-size: 17px;
}

.DatepickerBox.ver1 .form-control.InputType.input-datepicker.event-add {
    text-align: start;
    text-align-last: start;
    padding: 1rem 1.5rem;
    padding-left: calc(2.5rem + 20px);
    font-size: 18px;
    line-height: 1;
    height: auto;
    background: url(https://www.flexdaycdn.net/public/images/ticket/calendar3.png)
        no-repeat calc(1rem + 2%) center / 1rem;
    /* no-repeat calc(1rem + 4%) center / 1.2rem; */
}

.DatepickerBox.ver1 .form-control.InputType.input-datepicker.storeEdit-add {
    text-align-last: left;
    padding: 0.5rem 1rem;
    font-size: 18px;
    line-height: 1;
    height: auto;
    /* background: url(https://www.flexdaycdn.net/public/images/ticket/calendar3.png)
    no-repeat calc(1rem + 4%) center / 1.2rem; */
    padding-left: calc(1.25rem + 20px);
    background: url(https://www.flexdaycdn.net/public/images/ticket/calendar3.png)
        no-repeat 1rem center / 15px;
}

.DatepickerBox.ver2 {
    width: 100%;
    position: relative;
}

.DatepickerBox.ver2 .form-control.InputType.input-datepicker.prd-admin2 {
    border: 2px solid var(--ColorTypeB);
    padding: 0.75rem 1rem;
    border-radius: 5px;
    font-weight: var(--Medium);
    font-size: 18px;
    line-height: 1;
    height: auto;
    background: url(https://www.flexdaycdn.net/public/images/ticket/arrow_down_b.png)
        no-repeat calc(100% - 1rem) center / 1.25rem;
}

.DatepickerBox.ver2 .react-datepicker {
    padding: 0 1rem 1.5rem;
    border: 2px solid var(--ColorTypeB);
}

.DatepickerBox.ver2 .react-datepicker__month-container {
    width: 100%;
}

.DatepickerBox.ver2 .react-datepicker__header {
    background-color: #fff;
    border-bottom: 0;
    padding: 1rem 0;
    position: sticky;
    top: 0;
}

.DatepickerBox.ver2 .react-datepicker__header .month-day {
    font-size: 18px;
    font-weight: var(--Medium);
}

.DatepickerBox.ver2 .react-datepicker__header .btn_month {
    max-width: 10px;
    opacity: 0.8;
    cursor: pointer;
}

.DatepickerBox.ver2 .react-datepicker__month {
    margin: 0;
    max-height: 300px;
    overflow-y: scroll;
    padding: 0 1rem;
}

.DatepickerBox.ver2 .react-datepicker__month::-webkit-scrollbar {
    width: 3px;
}

.DatepickerBox.ver2 .react-datepicker__month::-webkit-scrollbar-track {
    background: #999999;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    background-clip: padding-box;
}

.DatepickerBox.ver2 .react-datepicker__month::-webkit-scrollbar-thumb {
    -webkit-border-radius: 4px;
    border-radius: 4px;
    background: var(--ColorTypeC);
}

.DatepickerBox.ver2 .react-datepicker__month-text {
    width: 100%;
    margin: 0;
    padding: 0.5rem 0;
    font-size: 18px;
    background-color: #fff;
    color: var(--ColorTyepB);
}

.DatepickerBox.ver2
    .react-datepicker__month-text.react-datepicker__month--disabled {
    color: #ccc;
    pointer-events: none;
}

.DatepickerBox.ver2
    .react-datepicker__month-text.react-datepicker__month--selected {
    background-color: transparent;
    color: var(--ColorTypeA);
    font-weight: bold;
}

.DatepickerBox.ver2
    .react-datepicker__month-text.react-datepicker__month--selected.react-datepicker__month-text--keyboard-selected {
    background-color: transparent;
    color: var(--ColorTypeA);
    font-weight: bold;
}

.DatepickerBox.ver2 .react-datepicker__month-text--today {
    font-weight: normal;
}

.DatepickerBox.ver2
    .react-datepicker__month-text.react-datepicker__month-selected.react-datepicker__month-text--today {
    font-weight: bold;
}

@media screen and (max-width: 599px) {
    .DatepickerWrap.px-4-5 {
        padding-left: 1.5rem !important;
        padding-right: 1.5rem !important;
    }

    .DatepickerBox.ver1 > .d-flex {
        gap: 0 0.5rem;
    }

    .DatepickerBox.ver1 .form-control.InputType.input-datepicker.sale-prd-add {
        width: 120px;
        font-size: 14px;
        padding: 0.5rem 1rem;
    }

    .DatepickerBox.ver1 .form-control.InputType.input-datepicker.prd-admin {
        background: none;
        padding: 0.75rem 1rem;
        width: 100%;
        font-size: 16px;
        border: 1px solid var(--ColorTypeB);
    }

    .DatepickerBox.ver1 .form-control.InputType.input-datepicker.event-add {
        font-size: 15px;
        padding-left: calc(1.5rem + 17px);
    }

    .DatepickerBox.ver2 .form-control.InputType.input-datepicker.prd-admin2 {
        font-size: 16px;
    }

    .DatepickerBox.ver1 > .d-flex.ver2 {
        gap: 0 0.5rem;
        font-size: 14px;
    }

    .DatepickerBox.ver2 .react-datepicker__header .month-day {
        font-size: 16px;
    }

    .DatepickerBox.ver2 .react-datepicker__month {
        max-height: 230px;
    }

    .DatepickerBox.ver2 .react-datepicker__month .react-datepicker__month-text {
        font-size: 16px;
    }
}

@media screen and (max-width: 380px) {
    .DatepickerBox.ver1 .form-control.InputType.input-datepicker.prd-admin {
        /* padding: 0.75rem 0; */
    }

    .DatepickerBox.ver1 .form-control.InputType.input-datepicker.event-add {
        padding-left: calc(1.5rem + 17px);
        padding-right: 0;
        background: url(https://www.flexdaycdn.net/public/images/ticket/calendar3.png)
            no-repeat calc(1rem + 2%) center / 1rem;
    }
}

@media screen and (max-width: 320px) {
    .DatepickerBox.ver1 .form-control.InputType.input-datepicker.sale-prd-add {
        width: 110px;
    }

    .DatepickerBox.ver1 .form-control.InputType.input-datepicker.event-add {
        padding: 1rem 0.5rem;
        background: none;
        text-align: center;
        text-align-last: center;
    }

    .DatepickerBox.ver2 .react-datepicker__month {
        max-height: 160px;
    }
}
@media screen and (max-width: 322px) {
    .DatepickerBox.ver1 .form-control.InputType.input-datepicker.prd-admin {
        background: none;
        text-align: center;
        text-align-last: center;
        padding: 0.75rem 0;
    }
}

/* time-picker */

.DatepickerBox.time .react-datepicker {
    padding: 1rem;
}

.DatepickerBox.time .form-control.InputType.input-datepicker.sale-prd-add {
    width: 90px;
}

.DatepickerBox.time .react-datepicker__header {
    display: none;
}

.DatepickerBox.time
    .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list::-webkit-scrollbar {
    display: none;
}

.DatepickerBox.time
    .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item:hover {
    background-color: #fff;
}

.DatepickerBox.time
    .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    background-color: #fff;
    color: var(--ColorTypeA);
    font-weight: bold;
}

@media screen and (max-width: 599px) {
    .DatepickerBox.ver1.time
        .form-control.InputType.input-datepicker.sale-prd-add {
        /* width: 100%; */
        padding: 1rem;
    }
}

@media screen and (min-width: 599px) {
    .DatepickerBox.ver1 .form-control.InputType.input-datepicker.event-add {
        background: url(https://www.flexdaycdn.net/public/images/ticket/calendar3.png)
            no-repeat calc(1rem + 4%) center / 1.2rem;
    }
}
