
.TLogin2Page .SignUp-title {
    margin: 3rem auto 3.5rem;
    padding-bottom: 1.5rem;
    border-bottom: 10px solid #f7f7f7;
    position: relative;
}

.TLogin2Page .SignUp-title::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    display: block;
    width: 100%;
    height: 4px;
    background-color: #EDEDED;
}

.TLogin2Page .SignUp-title .tit {
    font-weight: var(--Medium);
    color: var(--ColorTypeB);
    font-size: 32px;
}

.TLogin2Page .SignUp-title .tit img {
    max-width: 32px;
    align-self: center;
}

.TLogin2Page .SignUp-title .sub {
    font-weight: var(--Regular);
    color: var(--ColorTypeC);
    font-size: 18px;
}

.TLogin2Page .SignUp-Infobox {
    padding-left: calc(0.75rem + 28px + 0.25rem);
}

.TLogin2Page .SignUp-checkbox-desc {
    font-weight: var(--Regular);
    font-size: 13px;
    color: var(--ColorTypeC);
    word-break: keep-all;
}

.TLogin2Page .SignUp-Infobox .form-check-group {
    gap: 0.5rem 0;
}

.TLogin2Page .SignUp-Infobox .form-check-group .form-check {
    width: 33.333%;
}

.TLogin2Page .SignUp-title .sub {
    color: #000000;
}

.TLogin2Page .ver2 .accordion-body {
    padding: 0rem 0.75rem 0;
}

.TLogin2Page .CheckLabel {
    font-size: 17px;
}

.TLogin2Page .SignUp-checkbox-desc {
    color: #999999;
    font-size: 17px;
}

.TLogin2Page .ver2 .accordion-button {
    font-size: 20px;
    background-color: #fff;
}

.TLogin2Page .ver2 .accordion-button::after {
    margin-right: 0.5rem;
}

.TLogin2Page .CheckInfoBtn {
    font-size: 16px;
}

.demo .TLogin2Page .SignUp-Confirm .btn {
    height: 70px;
    font-size: 22px;
}


/* 모바일 반응형 */
@media screen and (max-width: 599px) {

    .TLogin2Page .SignUp-title {
        margin: 3rem auto 2.5rem;
        padding-bottom: 0.5rem;
    }

    .TLogin2Page .ver2 .accordion-body {
        padding: 0;
    }

    .TLogin2Page .SignUp-Infobox {
        padding-left: calc(28px + 0.25rem);
    }

    .CheckInfoBtn {
        right: 0.5rem;
    }

    .TLogin2Page .SignUp-title .tit {
        font-size: 25px;
    }

    .TLogin2Page .SignUp-title .tit img {
        max-width: 26px;
    }

    .TLogin2Page .SignUp-title .sub {
        font-size: 14px;
    }

    .TLogin2Page .ver2 .accordion-button {
        font-size: 16px;
    }

    .TLogin2Page .CheckLabel {
        font-size: 14px;
    }

    .TLogin2Page .CheckInfoBtn {
        font-size: 14px;
    }

    .TLogin2Page .SignUp-checkbox-desc {
        font-size: 13px;
    }

    .demo .TLogin2Page .SignUp-Confirm .btn {
        height: 55px;
        font-size: 16px;
    }

}

@media screen and (max-width: 320px) {}