.backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index:999
}

.custom-backdrop {
  background-color: transparent;
}
