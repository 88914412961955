.TPopupManagePage .PopupManageBox .PopupManage-Btnbox {
    gap: 0 10px;
    margin-bottom: 3rem;
}

.TPopupManagePage .PopupManageBox .PopupManage-Item {
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #999999;
    padding-bottom: 2rem;
    margin-bottom: 2rem;
}

.TPopupManagePage .PopupManageBox .PopupManage-Item:last-child {
    border-bottom: 0;
    padding-bottom: 0;
}

.TPopupManagePage .PopupManageBox .PopupManage-Item .prd-infobox {
    width: 67%;
    padding-right: 10%;
    box-sizing: border-box;
}

.TPopupManagePage .PopupManageBox .PopupManage-Item .prd-infobox .prd-tit {
    font-weight: var(--Medium);
    color: var(--ColorTypeB);
    font-size: 28px;
    margin-bottom: 0.3rem;
    word-break: break-word;
}

.TPopupManagePage
    .PopupManageBox
    .PopupManage-Item
    .prd-infobox
    .prd-tit
    .state {
    background-color: #293eb7;
    color: #fff;
    font-size: 18px;
    line-height: 1;
    font-weight: var(--Regular);
    padding: 0.2rem 0.75rem;
    border-radius: 0.2em;
    display: inline-block;
    vertical-align: middle;
    margin-left: 0.35rem;
}

.TPopupManagePage
    .PopupManageBox
    .PopupManage-Item.stop
    .prd-infobox
    .prd-tit
    .state {
    background-color: #d80c07;
}

.TPopupManagePage .PopupManageBox .PopupManage-Item .prd-infobox .prd-desc {
    font-weight: var(--Regular);
    color: var(--ColorTypeC);
    font-size: 20px;
    margin-bottom: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.TPopupManagePage .PopupManageBox .PopupManage-Item .prd-btnbox {
    width: 33%;
    gap: 0 0.75rem;
}

.TPopupManagePage .PopupManageBox .PopupManage-Item .prd-btnbox .btn {
    height: auto;
    border-radius: 30px;
    font-weight: var(--Medium);
}

/* 모바일 반응형 */
@media screen and (max-width: 599px) {
    .demo .TPopupManagePage .PopupManageBox.px-4-5 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important;
    }

    .TPopupManagePage .PopupManageBox .PopupManage-Btnbox .btn {
        height: 40px;
    }

    .TPopupManagePage .PopupManageBox .PopupManage-Item {
        padding-bottom: 1.5rem;
        margin-bottom: 1.5rem;
    }

    .TPopupManagePage .PopupManageBox .PopupManage-Item .prd-infobox {
        width: calc(100% - 140px);
        padding-right: 0;
    }

    .TPopupManagePage .PopupManageBox .PopupManage-Item .prd-infobox .prd-tit {
        font-size: 22px;
    }

    .TPopupManagePage
        .PopupManageBox
        .PopupManage-Item
        .prd-infobox
        .prd-tit
        .state {
        font-size: 15px;
        padding: 0.2rem 0.6rem;
    }

    .TPopupManagePage .PopupManageBox .PopupManage-Item .prd-infobox .prd-desc {
        font-size: 16px;
    }

    .TPopupManagePage .PopupManageBox .PopupManage-Item .prd-btnbox {
        width: 130px;
    }

    .TPopupManagePage .PopupManageBox .PopupManage-Item .prd-btnbox .btn {
        padding: 0.25rem 0.5rem;
    }
}

/* ver2 */

.TPopupManagePage .PopupManageBox .PopupManage-Btnbox .btn {
    font-size: 22px;
    font-weight: var(--Semibold);
}

.TPopupManagePage .PopupManageBox .PopupManage-Btnbox .btn-light {
    border-color: var(--ColorTypeB);
    border-width: 2px;
}

.TPopupManagePage .PopupManageBox .PopupManage-Item {
    padding-bottom: 1.8rem;
    margin-bottom: 1.8rem;
}

.TPopupManagePage .PopupManageBox .PopupManage-Item:last-child {
    border-bottom: 1px solid #999999;
    padding-bottom: 1.8rem;
}

.TPopupManagePage .PopupManageBox .PopupManage-Item .prd-infobox .prd-tit {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 0 0.75rem;
}

.TPopupManagePage
    .PopupManageBox
    .PopupManage-Item
    .prd-infobox
    .prd-tit
    .state {
    background-color: #4d56ff;
    display: inline-flex;
    align-items: center;
    margin-left: 0;
}

.TPopupManagePage
    .PopupManageBox
    .PopupManage-Item.done
    .prd-infobox
    .prd-tit
    .state {
    background-color: #c2c2c2;
    color: var(--ColorTypeB);
}

.TPopupManagePage .PopupManageBox .PopupManage-Item .prd-btnbox .btn {
    padding: 0.25rem 0.75rem;
}

.TPopupManagePage .PopupManageBox .PopupManage-Item .prd-btnbox .btn.btn-light {
    border-color: var(--ColorTypeB);
    border-width: 2px;
}

.TPopupManagePage .PopupManageBox .PopupManage-Itembox .prd-blank {
    font-size: 22px;
    font-weight: var(--Medium);
    color: var(--ColorTypeB);
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 60vh;
}

@media screen and (max-width: 599px) {
    .TPopupManagePage .PopupManageBox .PopupManage-Btnbox .btn {
        font-size: 17px;
        font-weight: var(--Medium);
    }

    .TPopupManagePage .PopupManageBox .PopupManage-Btnbox .btn-light {
        border-width: 1px;
    }

    .TPopupManagePage .PopupManageBox .PopupManage-Item {
        padding-bottom: 1.5rem;
        margin-bottom: 1.5rem;
    }

    .TPopupManagePage .PopupManageBox .PopupManage-Item:last-child {
        padding-bottom: 1.5rem;
    }

    .TPopupManagePage
        .PopupManageBox
        .PopupManage-Item
        .prd-btnbox
        .btn.btn-light {
        border-width: 1px;
    }

    .TPopupManagePage .PopupManageBox .PopupManage-Itembox .prd-blank {
        font-size: 17px;
        min-height: 55vh;
    }
}
