.TLoginPage .LogoImg {
  margin-top: 5vh;
  margin-bottom: 7vh;
}

.TLoginPage .LogoImg img {
  width: 100%;
  max-width: 130px;
}

.TLoginPage .InfoSearch {
  font-weight: var(--Regular);
  font-size: 18px;
  color: var(--ColorTypeC);
}

.TLoginPage .LoginBox > .row {
  margin-bottom: 12px;
  --bs-gutter-x: 0;
}

.TLoginPage .LoginBox > .row:nth-child(1) {
  margin-top: 12px;
}

.TLoginPage .InfoSearch > a {
  position: relative;
}

.TLoginPage .InfoSearch > a:hover {
  color: inherit;
}

.TLoginPage .InfoSearch > a::after {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 1px;
  height: 16px;
  background: var(--ColorTypeC);
  margin: 0px 0.7rem;
}

.TLoginPage .InfoSearch > a:last-child::after {
  display: none;
}

.TLoginPage .btn-primary {
  margin-bottom: 12px;
  margin-top: 20px;
}

.TLoginPage .LoginBox > .row {
  margin-bottom: 12px;
  --bs-gutter-x: 0;
}

.TLoginPage .JoinBox > .InfoTxt {
  font-weight: var(--Medium);
  font-size: 18px;
  color: var(--ColorTypeC);
  text-align: center;
  position: relative;
}

/* 모바일 반응형 */
@media screen and (max-width: 599px) {
  .TLoginPage .LogoImg img {
    max-width: 100px;
  }

  .TLoginPage .InfoSearch {
    font-size: 14px;
  }

  .TLoginPage .btn-primary {
    margin-top: 10px;
  }

  .TLoginPage .JoinBox.mt-5 {
    margin-top: 2rem !important;
  }

  .TLoginPage .JoinBox > .InfoTxt {
    font-size: 14px;
  }
}

@media screen and (max-width: 320px) {
  .TLoginPage .LogoImg {
    margin-bottom: 6vh;
  }

  .TLoginPage .LogoImg img {
    max-width: 85px;
  }
}

/* 23-01-09 수정 */

.demo .TLoginPage .Inner {
  display: flex;
  flex-direction: column;
  gap: 5rem 0;
}

.TLoginPage .LogoImg img {
  max-width: 180px;
}

.TLoginPage .LoginBox .btn-light {
  width: 100%;
  height: auto;
  border-radius: 5px;
  font-weight: var(--Medium);
  font-size: 20px;
  border-color: #000000;
  padding: 1rem 1.5rem;
}

.TLoginPage .LoginBox .btn-default {
  width: 100%;
  height: auto;
  border-radius: 5px;
  font-weight: var(--Medium);
  font-size: 20px;
  padding: 1rem 1.5rem;
  position: relative;
}

.TLoginPage .LoginBox .btn-default > img {
  position: absolute;
  left: 1.5rem;
  top: 50%;
  transform: translateY(-50%);
  max-width: 22px;
}

.TLoginPage .LoginBox .btn-kakao {
  width: 100%;
  border-radius: 5px;
  background: #fee500;
  font-weight: var(--Medium);
  font-size: 20px;
  border-color: #fee500;
  position: relative;
  padding: 1rem 1.5rem;
}

.TLoginPage .LoginBox .btn-kakao > img {
  position: absolute;
  left: 1.5rem;
  top: 50%;
  transform: translateY(-50%);
}

.TLoginPage .LoginBox .btn-naver {
  width: 100%;
  border-radius: 5px;
  background: #03c75a;
  font-weight: var(--Medium);
  font-size: 20px;
  color: #fff;
  border-color: #03c75a;
  position: relative;
  padding: 1rem 1.5rem;
}

.TLoginPage .LoginBox .btn-naver > img {
  position: absolute;
  left: 1.5rem;
  top: 50%;
  transform: translateY(-50%);
}

.TLoginPage .LoginBox .btn-kakao:active {
  background: #fee500;
  border-color: #fee500;
}

.TLoginPage .LoginBox .btn-naver:active {
  background: #03c75a;
  border-color: #03c75a;
  color: #fff;
}

/* admin */

.TLoginPage.admin .LogoImg {
  margin-bottom: 5vh;
}

.TLoginPage.admin .LogoImg .txt {
  font-size: 20px;
  color: #999999;
  font-weight: var(--Medium);
  margin-top: 0.5rem;
}

.TLoginPage.admin .LoginBox > .row:nth-child(1) {
  margin-top: 0;
}

.TLoginPage.admin .LoginBox > .row {
  margin-bottom: 0.5rem;
}

.demo .TLoginPage.admin .form-control.InputType {
  height: 55px;
  height: auto;
  font-size: 18px;
  padding: 1rem 1.5rem;
}

.TLoginPage.admin .LoginBox .btn-primary {
  width: 100%;
  height: auto;
  border-radius: 5px;
  font-weight: var(--Medium);
  font-size: 20px;
  padding: 1rem 1.5rem;
  margin-bottom: 0;
  margin-top: 1rem;
}

/* 모바일 반응형 */
@media screen and (max-width: 599px) {
  .TLoginPage .LogoImg img {
    max-width: 130px;
  }

  .TLoginPage .LoginBox .btn-light {
    max-width: 320px;
    font-size: 16px;
    padding: 0.75rem 1.5rem;
  }

  .TLoginPage .LoginBox .btn-default {
    max-width: 320px;
    font-size: 16px;
    padding: 0.75rem 1.5rem;
  }

  .TLoginPage .LoginBox .btn-kakao {
    max-width: 320px;
    font-size: 16px;
    padding: 0.75rem 1.5rem;
  }

  .TLoginPage .LoginBox .btn-naver {
    max-width: 320px;
    font-size: 16px;
    padding: 0.75rem 1.5rem;
  }

  /* admin */
  .TLoginPage.admin .LogoImg .txt {
    font-size: 14px;
  }

  .demo .TLoginPage.admin .form-control.InputType {
    font-size: 14px;
    padding: 0.75rem;
  }

  .TLoginPage.admin .LoginBox .btn-primary {
    font-size: 16px;
    padding: 0.75rem 1.5rem;
    margin-top: 0.5rem;
  }
}

@media screen and (max-width: 320px) {
  .TLoginPage .LogoImg {
    margin-bottom: 0;
  }

  .TLoginPage .LogoImg img {
    max-width: 100px;
  }

  /* admin */
  .TLoginPage.admin .LogoImg {
    margin-bottom: 0;
  }
}

.TLoginPage .LoginBox .btn-light {
  color: #999999;
  border-color: #fff;
}

.TLoginPage .LoginBox .btn-light:focus {
  box-shadow: none;
}
.TLoginPage .LogoImg {
  margin-top: 5vh;
  margin-bottom: 7vh;
}

.TLoginPage .LogoImg img {
  width: 100%;
  max-width: 130px;
}

.TLoginPage .InfoSearch {
  font-weight: var(--Regular);
  font-size: 18px;
  color: var(--ColorTypeC);
}

.TLoginPage .LoginBox > .row {
  margin-bottom: 12px;
  --bs-gutter-x: 0;
}

.TLoginPage .LoginBox > .row:nth-child(1) {
  margin-top: 12px;
}

.TLoginPage .InfoSearch > a {
  position: relative;
}

.TLoginPage .InfoSearch > a:hover {
  color: inherit;
}

.TLoginPage .InfoSearch > a::after {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 1px;
  height: 16px;
  background: var(--ColorTypeC);
  margin: 0px 0.7rem;
}

.TLoginPage .InfoSearch > a:last-child::after {
  display: none;
}

.TLoginPage .btn-primary {
  margin-bottom: 12px;
  margin-top: 20px;
}

.TLoginPage .LoginBox > .row {
  margin-bottom: 12px;
  --bs-gutter-x: 0;
}

.TLoginPage .JoinBox > .InfoTxt {
  font-weight: var(--Medium);
  font-size: 18px;
  color: var(--ColorTypeC);
  text-align: center;
  position: relative;
}

/* 모바일 반응형 */
@media screen and (max-width: 599px) {
  .TLoginPage .LogoImg img {
    max-width: 100px;
  }

  .TLoginPage .InfoSearch {
    font-size: 14px;
  }

  .TLoginPage .btn-primary {
    margin-top: 10px;
  }

  .TLoginPage .JoinBox.mt-5 {
    margin-top: 2rem !important;
  }

  .TLoginPage .JoinBox > .InfoTxt {
    font-size: 14px;
  }
}

@media screen and (max-width: 320px) {
  .TLoginPage .LogoImg {
    margin-bottom: 6vh;
  }

  .TLoginPage .LogoImg img {
    max-width: 85px;
  }
}

/* 23-01-09 수정 */

.demo .TLoginPage .Inner {
  display: flex;
  flex-direction: column;
  gap: 5rem 0;
}

.TLoginPage .LogoImg img {
  max-width: 180px;
}

.TLoginPage .LoginBox .btn-light {
  width: 100%;
  height: auto;
  border-radius: 5px;
  font-weight: var(--Medium);
  font-size: 20px;
  border-color: #000000;
  padding: 1rem 1.5rem;
}

.TLoginPage .LoginBox .btn-default {
  width: 100%;
  height: auto;
  border-radius: 5px;
  font-weight: var(--Medium);
  font-size: 20px;
  padding: 1rem 1.5rem;
  position: relative;
}

.TLoginPage .LoginBox .btn-default > img {
  position: absolute;
  left: 1.5rem;
  top: 50%;
  transform: translateY(-50%);
  max-width: 22px;
}

.TLoginPage .LoginBox .btn-kakao {
  width: 100%;
  border-radius: 5px;
  background: #fee500;
  font-weight: var(--Medium);
  font-size: 20px;
  border-color: #fee500;
  position: relative;
  padding: 1rem 1.5rem;
}

.TLoginPage .LoginBox .btn-kakao > img {
  position: absolute;
  left: 1.5rem;
  top: 50%;
  transform: translateY(-50%);
}

.TLoginPage .LoginBox .btn-naver {
  width: 100%;
  border-radius: 5px;
  background: #03c75a;
  font-weight: var(--Medium);
  font-size: 20px;
  color: #fff;
  border-color: #03c75a;
  position: relative;
  padding: 1rem 1.5rem;
}

.TLoginPage .LoginBox .btn-naver > img {
  position: absolute;
  left: 1.5rem;
  top: 50%;
  transform: translateY(-50%);
}

.TLoginPage .LoginBox .btn-kakao:active {
  background: #fee500;
  border-color: #fee500;
}

.TLoginPage .LoginBox .btn-naver:active {
  background: #03c75a;
  border-color: #03c75a;
  color: #fff;
}

/* admin */

.TLoginPage.admin .LogoImg {
  margin-bottom: 5vh;
}

.TLoginPage.admin .LogoImg .txt {
  font-size: 20px;
  color: #999999;
  font-weight: var(--Medium);
  margin-top: 0.5rem;
}

.TLoginPage.admin .LoginBox > .row:nth-child(1) {
  margin-top: 0;
}

.TLoginPage.admin .LoginBox > .row {
  margin-bottom: 0.5rem;
}

.demo .TLoginPage.admin .form-control.InputType {
  height: 55px;
  height: auto;
  font-size: 18px;
  padding: 1rem 1.5rem;
}

.TLoginPage.admin .LoginBox .btn-primary {
  width: 100%;
  height: auto;
  border-radius: 5px;
  font-weight: var(--Medium);
  font-size: 20px;
  padding: 1rem 1.5rem;
  margin-bottom: 0;
  margin-top: 1rem;
}

/* 모바일 반응형 */
@media screen and (max-width: 599px) {
  .TLoginPage .LogoImg img {
    max-width: 130px;
  }

  .TLoginPage .LoginBox .btn-light {
    max-width: 320px;
    font-size: 16px;
    padding: 0.75rem 1.5rem;
  }

  .TLoginPage .LoginBox .btn-default {
    max-width: 320px;
    font-size: 16px;
    padding: 0.75rem 1.5rem;
  }

  .TLoginPage .LoginBox .btn-kakao {
    max-width: 320px;
    font-size: 16px;
    padding: 0.75rem 1.5rem;
  }

  .TLoginPage .LoginBox .btn-naver {
    max-width: 320px;
    font-size: 16px;
    padding: 0.75rem 1.5rem;
  }

  /* admin */
  .TLoginPage.admin .LogoImg .txt {
    font-size: 14px;
  }

  .demo .TLoginPage.admin .form-control.InputType {
    font-size: 14px;
    padding: 0.75rem;
  }

  .TLoginPage.admin .LoginBox .btn-primary {
    font-size: 16px;
    padding: 0.75rem 1.5rem;
    margin-top: 0.5rem;
  }
}

@media screen and (max-width: 320px) {
  .TLoginPage .LogoImg {
    margin-bottom: 0;
  }

  .TLoginPage .LogoImg img {
    max-width: 100px;
  }

  /* admin */
  .TLoginPage.admin .LogoImg {
    margin-bottom: 0;
  }
}

.TLoginPage .LoginBox .btn-light {
  color: #999999;
  border-color: #fff;
}

.TLoginPage .LoginBox .btn-light:focus {
  box-shadow: none;
}



/* ver3 */

.TLoginPage .LoginBox .form-checkbox {
  gap: 0 0.5rem;
  margin: 1rem 0 1.5rem;
}

.TLoginPage .LoginBox .form-checkbox .form-check-input {
  margin: 0;
  width: 28px;
  height: 28px;
}

.TLoginPage .LoginBox .form-checkbox .form-check-label {
  font-size: 20px;
}

@media screen and (max-width: 599px) {

  .TLoginPage.admin .LogoImg {
    margin-bottom: 3vh;
  }

  .TLoginPage .LoginBox .form-checkbox {
    margin: 0.75rem 0 1.25rem;
  }

  .TLoginPage .LoginBox .form-checkbox .form-check-input {
    width: 22px;
    height: 22px;
  }

  .TLoginPage .LoginBox .form-checkbox .form-check-label {
    font-size: 15px;
  }

}

@media screen and (max-width:320px) {

  .demo .TLoginPage.admin .Inner {
    gap: 4rem 0;
  }

  .TLoginPage.admin .LogoImg {
    margin-bottom: 0vh;
  }

  .TLoginPage .LoginBox .form-checkbox {
    margin: 0rem 0 1rem;
    height: auto;
  }

  .TLoginPage .LoginBox .form-checkbox .form-check-input {
    width: 20px;
    height: 20px;
  }

  .TLoginPage .LoginBox .form-checkbox .form-check-label {
    font-size: 14px;
  }

}