.TSettleHistoryPage .HistoryBox .Listitem {
    border-bottom: 1px solid var(--ColorTypeE);
}

.TSettleHistoryPage .HistoryBox .Listitem:last-child {
    border-bottom: 0px;
}

.TSettleHistoryPage .HistoryBox .Listitem .txtbox {
    width: calc(100% - 145px);
    word-break: break-word;
}

.TSettleHistoryPage .HistoryBox .Listitem .txtbox .date {
    font-weight: var(--Medium);
    font-size: 25px;
    color: var(--ColorTypeC);
}

.TSettleHistoryPage .HistoryBox .Listitem .txtbox .price {
    font-weight: var(--Medium);
    font-size: 25px;
    color: var(--ColorTypeB);
}

.TSettleHistoryPage .HistoryBox .Listitem .txtbox .price span {
    font-weight: var(--Semibold);
    font-size: 40px;
}

.TSettleHistoryPage .HistoryBox .Listitem .btnbox {
    width: 135px;
}

.TSettleHistoryPage .HistoryBox .Listitem .btnbox img.arrow {
    width: 15px;
    margin-left: 20px;
    align-self: center;
}

.TSettleHistoryPage .HistoryBox .Listitem.none .btnbox img.arrow {
    display: none;
}

.TSettleHistoryPage .HistoryYear .yearbox {
    gap: 0 10px;
}

.TSettleHistoryPage .HistoryYear .yearbox .btn-default,
.TSettleHistoryPage .HistoryYear .yearbox .btn-light {
    font-size: 20px;
    border-radius: 30px;
    --bs-btn-padding-y: 0.25rem;
    --bs-btn-padding-x: 1rem;
}

.TSettleHistoryPage .HistoryYear .HistoryFixed {
    width: 100%;
    background: var(--ColorTypeB);
    font-weight: var(--Medium);
    color: #fff;
    font-size: 25px;
    line-height: 65px;
    position: fixed;
    left: 0;
    top: 0;
    opacity: 0;
    z-index: -999;
    transition: opacity 0.35s ease;
}

.TSettleHistoryPage .HistoryYear .HistoryFixed.fixed {
    opacity: 1;
    z-index: 10;
}

.TSettleHistoryPage .HContentWrap .HContent {
    display: none;
}

.TSettleHistoryPage .HContentWrap .HContent:nth-child(1) {
    display: block;
}

/* 모바일 반응형 */

@media screen and (max-width: 599px) {

    .TSettleHistoryPage .HistoryBox .Listitem .txtbox {
        width: calc(100% - 100px);
    }

    .TSettleHistoryPage .HistoryBox .Listitem .txtbox .date {
        font-size: 17px;
    }

    .TSettleHistoryPage .HistoryBox .Listitem .txtbox .price {
        font-size: 18px;
    }

    .TSettleHistoryPage .HistoryBox .Listitem .txtbox .price span {
        font-size: 27px;
    }

    .TSettleHistoryPage .HistoryBox .Listitem .btnbox {
        width: 90px;
    }

    .TSettleHistoryPage .HistoryBox .Listitem .btnbox img.arrow {
        width: 10px;
        margin-left: 10px;
    }

    .TSettleHistoryPage .HistoryYear .yearbox .btn-default,
    .TSettleHistoryPage .HistoryYear .yearbox .btn-light {
        font-size: 13px;
    }

    .TSettleHistoryPage .HistoryYear .HistoryFixed {
        font-size: 17px;
        line-height: 45px;
    }


}

@media screen and (max-width: 320px) {

    .TSettleHistoryPage .btn-default,
    .TSettleHistoryPage .btn-light {
        height: 40px;
    }

}