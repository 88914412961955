.TEventStatusPage .EventStatus-Top {
  justify-content: space-between;
  align-items: center;
}

.TEventStatusPage .EventStatus-Top .detail-box {
  border-bottom: 1px solid #999999;
  padding-bottom: 2.25rem;
  margin-bottom: 2.25rem;
}

.TEventStatusPage .EventStatus-Top .detail-item {
  gap: 0 2rem;
  font-size: 20px;
  margin-bottom: 0.5rem;
}

.TEventStatusPage .EventStatus-Top .detail-item:last-child {
  margin-bottom: 0;
}

.TEventStatusPage .EventStatus-Top .detail-item .tit {
  width: 75px;
  color: var(--ColorTypeC);
  font-weight: var(--Medium);
}

.TEventStatusPage .EventStatus-Top .detail-item .contents {
  color: var(--ColorTypeB);
  font-weight: var(--Medium);
  word-break: break-word;
  width: calc(100% - 75px - 2rem);
}

.TEventStatusPage .EventStatus-Top .ver2 .detail-item .tit {
  width: 130px;
}

.TEventStatusPage .EventStatus-Top .ver2 .detail-item .contents {
  width: calc(100% - 130px - 2rem);
}

.TEventStatusPage .PrdTbl-tit {
  font-size: 24px;
  color: var(--ColorTypeB);
  font-weight: var(--Medium);
}

.TEventStatusPage .PrdTbl-wrap {
  overflow-x: scroll;
}

.TEventStatusPage .PrdTbl-wrap::-webkit-scrollbar {
  display: none;
}

.TEventStatusPage .PrdTbl {
  min-width: 100%;
  width: max-content;
  text-align: center;
  vertical-align: middle;
  margin-right: 3rem;
}

.TEventStatusPage .PrdTbl thead th {
  background-color: #000000;
  color: #fff;
  font-weight: var(--Regular);
  box-shadow: none;
  border-right-width: 2px;
  border-color: var(--ColorTypeC);
  padding: 0.5rem 1.25rem;
  font-size: 18px;
  line-height: 1.1;
  vertical-align: middle;
}

.TEventStatusPage .PrdTbl thead th:last-child {
  border-right: 0;
}

.TEventStatusPage .PrdTbl thead th.sort-th img {
  display: inline-block;
  vertical-align: middle;
  margin-left: 0.25rem;
  max-width: 12px;
  margin-bottom: 0.15rem;
}

.TEventStatusPage .PrdTbl tbody td {
  background-color: #fff;
  border-color: var(--ColorTypeD);
  border-right-width: 2px;
  border-bottom-width: 2px;
  box-shadow: none;
  font-size: 16px;
  line-height: 1.2;
  padding: 1rem 1.5rem;
  min-width: 100px;
  word-break: break-word;
}

.TEventStatusPage .PrdTbl tbody td:last-child {
  border-right: 0;
}

.TEventStatusPage .modal-prdfilter .modal-header {
  position: relative;
  padding: 2rem 0 !important;
}

.TEventStatusPage .modal-prdfilter .modal-header .modal-title {
  font-size: 22px;
  text-align: center;
  color: var(--ColorTypeC);
}

.TEventStatusPage .modal-prdfilter .modal-header .btn {
  max-width: 20px;
  position: absolute;
  right: 3rem;
  top: 50%;
  transform: translateY(-50%);
}

.TEventStatusPage .modal-prdfilter .modal-body {
  padding-top: 0;
}

.TEventStatusPage .modal-prdfilter .modal-content {
  border: none;
  border-radius: 5px;
}

.TEventStatusPage .modal-prdfilter .form-sort {
  margin-bottom: 1.25rem;
  text-align: center;
}

.TEventStatusPage .modal-prdfilter .form-sort .form-radio-input {
  display: none;
}

.TEventStatusPage
  .modal-prdfilter
  .form-sort
  .form-radio-input
  + .form-radio-label {
  font-size: 20px;
  font-weight: var(--Medium);
  color: var(--ColorTypeB);
}

.TEventStatusPage
  .modal-prdfilter
  .form-sort
  .form-radio-input:checked
  + .form-radio-label {
  font-size: 24px;
  font-weight: var(--Semibold);
  color: var(--ColorTypeA);
}

/* 모바일 반응형 */

@media screen and (max-width: 1024px) {
  .TEventStatusPage::before {
    border-left: 0px;
    border-right: 0px;
  }

  .TEventStatusPage .PayCateTab .nav-pills {
    border-left: 0px;
    border-right: 0px;
  }
}

@media screen and (max-width: 599px) {
  .TEventStatusPage .EventStatus-Top {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
    gap: 0 1rem;
  }

  .TEventStatusPage .EventStatus-Top .detail-box {
    padding-bottom: 1.75rem;
    margin-bottom: 1.75rem;
  }

  .TEventStatusPage .EventStatus-Top .detail-item {
    gap: 0 1rem;
    font-size: 16px;
  }

  .TEventStatusPage .EventStatus-Top .detail-item .tit {
    width: 65px;
  }

  .TEventStatusPage .EventStatus-Top .detail-item .contents {
    width: calc(100% - 65px - 1rem);
  }

  .TEventStatusPage .EventStatus-Top .ver2 .detail-item .tit {
    width: 105px;
  }

  .TEventStatusPage .EventStatus-Top .ver2 .detail-item .contents {
    width: calc(100% - 105px - 1rem);
  }

  .TEventStatusPage .EventStatus-Bottom.ps-4-5 {
    padding-left: 1.5rem !important;
  }

  .TEventStatusPage .PrdTbl {
    margin-right: 1.5rem;
  }

  .TEventStatusPage .PrdTbl-tit {
    font-size: 20px;
  }

  .TEventStatusPage .PrdTbl thead th {
    font-size: 16px;
  }

  .TEventStatusPage .PrdTbl tbody td {
    font-size: 14px;
    padding: 0.75rem 1rem;
  }

  .TEventStatusPage .modal-prdfilter .modal-header .modal-title {
    font-size: 18px;
  }

  .TEventStatusPage .modal-prdfilter .modal-header {
    padding: 1.25rem 0 !important;
  }

  .TEventStatusPage .modal-prdfilter .modal-header .btn {
    max-width: 16px;
    right: 2rem;
  }

  .TEventStatusPage .modal-prdfilter .form-sort {
    margin-bottom: 1rem;
  }

  .TEventStatusPage
    .modal-prdfilter
    .form-sort
    .form-radio-input:checked
    + .form-radio-label {
    font-size: 20px;
  }

  .TEventStatusPage
    .modal-prdfilter
    .form-sort
    .form-radio-input
    + .form-radio-label {
    font-size: 16px;
  }
}

@media screen and (max-width: 380px) {
  .demo .TEventStatusPage .PayCateTab .nav-pills.px-4-5 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .TEventStatusPage .PayCateBtn {
    padding: 0 0 1rem;
  }

  .TEventStatusPage .PayCateBtn.active::before {
    top: -8px;
  }

  .TEventStatusPage .modal-sorting .sorting-box .inputbox.date .form-control {
    padding: 0.75rem 0;
  }
}
