/* 매장사진 */
.TicketPage .TicketPhoto {
    position: relative;
    width: 100%;
    height: 40vh;
    z-index: 0;
    -webkit-transform-style: preserve-3d;
    -webkit-transform: translateZ(-5px);
}

.TicketPage .TicketPhoto-Slider {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    overflow: hidden;
}

.TicketPage .TicketPhoto-Content {
    position: absolute;
    left: 0px;
    bottom: 45px;
    width: 100%;
    padding: 0px 2.2rem;
    justify-content: flex-end;
    align-items: flex-end;
}

.TicketPage .PhotoCount {
    position: relative;
    left: auto;
    bottom: auto;
    margin-bottom: 0;
    background: rgba(0, 0, 0, 0.3);
    text-align: center;
    width: 110px;
    height: 50px;
    border-radius: 3px;
    font-weight: var(--Medium);
    color: #fff;
    font-size: 22px;
    line-height: 50px;
}

/* 상점 정보 */
.TicketPage .StoreDetail {
    position: relative;
    background: #fff;
    border-top-left-radius: 36px;
    border-top-right-radius: 36px;
    margin-top: -25px;
    z-index: 5;
    box-shadow: none;
    -webkit-transform-style: preserve-3d;
    -webkit-transform: translateZ(-5px);
}

.TicketPage .StoreTitle {
    margin-bottom: 3rem;
}

.TicketPage .StoreTitle > .logo {
    max-width: 85px;
    margin-right: 20px;
}

.TicketPage .StoreTitle > .tit {
    font-weight: var(--Semibold);
    color: var(--ColorTypeB);
    font-size: 32px;
    line-height: 1.2;
    word-break: break-word;
}

.TicketPage .StoreInfo {
    gap: 0 30px;
}

.TicketPage .StoreInfo .tit {
    font-weight: var(--Medium);
    font-size: 20px;
    color: var(--ColorTypeD);
}

.TicketPage .StoreInfo .desc {
    font-weight: var(--Medium);
    font-size: 20px;
    color: var(--ColorTypeC);
}

.TicketPage .StoreInfo .desc br.Mob {
    display: none;
}

/* 탭 컨텐츠 */

.TicketPage .TicketPhoto-Slider {
    position: relative;
    left: auto;
    top: auto;
    transform: none;
    width: 100%;
    overflow: hidden;
    margin-top: -5px;
}

.TicketPage .tab-content {
    padding-top: 0;
}

.TicketPage .StoreTab-wrap {
    padding-bottom: 0;
}

.TicketPage .StoreTab-wrap.b-top {
    margin-top: 3rem;
    border-top: 1px solid var(--ColorTypeE);
}

.TicketPage .StoreTab {
    background-color: var(--ColorTypeE);
    border-radius: 100px;
    padding: 10px;
    margin-top: 3rem;
}

.TicketPage .StoreTab .nav-tabs {
    justify-content: space-between;
    border-bottom: 0px;
}

.TicketPage .StoreTab .nav-link {
    font-weight: var(--Regular);
    font-size: 25px;
    color: var(--ColorTypeC);
    border: 0px;
    padding: 1rem 0;
    width: calc(50% - 15px);
    border-radius: 100px;
    line-height: 1.15;
}

.TicketPage .StoreTab .nav-link.active {
    font-weight: var(--Medium);
    color: var(--ColorTypeB);
    border: 0px;
    background: #fff;
}

.TicketPage .ProductList {
    margin-top: 3rem;
}

.TicketPage .ProductList .row {
    margin-bottom: 5rem;
}

.TicketPage .ProductList .row .col-7 {
    padding-right: 30px;
}

.TicketPage .ProductList .row .col-5 {
    margin-top: 1.5rem;
}

.TicketPage .prd-tit {
    font-weight: var(--Medium);
    color: var(--ColorTypeB);
    font-size: 32px;
    margin-bottom: 0.3rem;
    word-break: break-word;
}

.TicketPage .prd-sub {
    font-weight: var(--Regular);
    color: var(--ColorTypeC);
    font-size: 17px;
    margin-bottom: 1rem;
    word-break: break-word;
}

.TicketPage .prd-price {
    font-weight: var(--Bold);
    color: var(--ColorTypeB);
    font-size: 24px;
}

.TicketPage .prd-btnbox {
    gap: 10px;
}

.TicketPage .btn.type14,
.TicketPage .btn.type13 {
    font-size: 18px;
}

.TicketPage .StoreQR {
    position: sticky;
    bottom: 1.5rem;
    margin-top: -70px;
    margin-bottom: 1.5rem;
    z-index: 10;
}

.TicketPage .StoreQR img.QR {
    width: 70px;
    border-radius: 50%;
    box-shadow: 3px 3px 10px 2px rgb(0 0 0 / 20%);
}

.TicketPage .StorePay .btn-primary {
    font-size: 30px;
    background: linear-gradient(to right, #ff195a, #ff5864, #ff655b);
    border: 0px;
}

.TicketPage .StorePay .btn-primary img.plus {
    max-width: 25px;
    margin-right: 15px;
}

/* 모바일 반응형 */
@media screen and (max-width: 599px) {
    .TicketPage .TicketPhoto {
        height: 245px;
    }

    .TicketPage .PhotoCount {
        width: 65px;
        height: 25px;
        font-size: 13px;
        line-height: 25px;
    }

    .TicketPage .StoreTitle {
        margin-bottom: 1.5rem;
    }

    .TicketPage .StoreTitle > .logo {
        max-width: 50px;
        margin-right: 15px;
    }

    .TicketPage .StoreTitle > .tit {
        font-size: 22px;
    }

    .TicketPage .StoreInfo .tit {
        width: 60px;
        margin-right: 15px;
        font-size: 17px;
    }

    .TicketPage .StoreInfo .desc {
        font-size: 17px;
        width: calc(100% - 75px);
    }

    .TicketPage .StoreInfo .desc br.Mob {
        display: block;
    }

    .TicketPage .StoreTab {
        padding: 7px;
        margin-top: 2rem;
    }

    .TicketPage .StoreTab .nav-link {
        font-size: 17px;
        padding: 0.5rem 0;
        width: calc(50% - 5px);
    }

    .TicketPage .ProductList {
        margin-top: 2rem;
    }

    .TicketPage .ProductList .row {
        margin-bottom: 3rem;
    }

    .TicketPage .ProductList .row .col-7 {
        padding-right: 10px;
    }

    .TicketPage .prd-tit {
        font-size: 20px;
    }

    .TicketPage .prd-sub {
        font-size: 13px;
        margin-bottom: 0.3rem;
    }

    .TicketPage .prd-price {
        font-size: 18px;
    }

    .TicketPage .btn.type14,
    .TicketPage .btn.type13 {
        font-size: 13px;
        padding: 0.375rem 0.75rem !important;
    }

    .TicketPage .StoreQR img.QR {
        width: 50px;
    }

    .TicketPage .StorePay .btn-primary {
        font-size: 20px;
        padding: 1rem 0 !important;
    }

    .TicketPage .StoreTab-wrap.b-top {
        margin-top: 2rem;
    }

    /* 2022.11.30 */

    .TicketPage .StoreInfo > .row {
        padding: 0 1rem;
    }

    .TicketPage .StoreInfo .tit {
        width: 100%;
        margin-right: 0;
    }

    .TicketPage .StoreInfo .desc {
        width: 100%;
    }
}

@media screen and (max-width: 320px) {
}

/* 23-01-09 수정 */

.TicketPage .TicketPhoto {
    height: auto;
    max-height: 55vh;
}

.TicketPage .TicketPhoto-Content {
    padding: 0px 1.75rem;
}

.TicketPage .StoreInfo .desc {
    font-weight: var(--Regular);
    word-break: break-word;
}

.TicketPage .StoreDetail {
    border-left: 1px solid var(--ColorTypeD);
    border-right: 1px solid var(--ColorTypeD);
}

.TicketPage .StoreTab-wrap.b-top {
    border-top: 5px solid #ededed;
    background-color: #f7f7f7;
}

.TicketPage .ProductList {
    padding-bottom: 9rem;
}

.TicketPage .ProductList .prd-item {
    margin-bottom: 2rem;
    position: relative;
    background-color: #fff;
    padding: 2.5rem;
    border-radius: 1.25rem;
    box-shadow: 5px 5px 5px 3px rgb(0 0 0 / 10%);
}

.TicketPage .ProductList .prd-item:last-child {
    margin-bottom: 0;
}

.TicketPage .prd-tit {
    font-weight: var(--Semibold);
    color: #000000;
    font-size: 28px;
}

.TicketPage .prd-sub {
    font-size: 20px;
}

.TicketPage .prd-price {
    margin-bottom: 0;
    font-weight: var(--Medium);
    font-size: 20px;
    word-break: break-word;
}

.TicketPage .prd-price .price {
    font-size: 30px;
    font-weight: var(--Bold);
}

.TicketPage .quantity-txt {
    position: absolute;
    top: 1.5rem;
    right: -1rem;
    font-size: 15px;
    color: #fff;
    background-color: var(--ColorTypeA);
    padding: 0.4rem 0.75rem;
    display: flex;
    align-items: center;
    gap: 0 0.5rem;
}

.TicketPage .quantity-txt::after {
    content: "";
    position: absolute;
    right: 0;
    bottom: -0.6rem;
    width: 0px;
    height: 0px;
    border-top: 0.6rem solid #8e002f;
    border-left: 0rem solid transparent;
    border-right: 1rem solid transparent;
}

.TicketPage .quantity-txt .icon {
    width: 20px;
}

.TicketPage .StorePay {
    position: fixed;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    z-index: 100;
    width: 100%;
    max-width: 800px;
}

.TicketPage .AddPrdBox {
    background: #fff;
    border-top-left-radius: 36px;
    border-top-right-radius: 36px;
    box-shadow: 3px 3px 10px 2px rgb(0 0 0 / 20%);
    height: 25rem;
    overflow-y: auto;
}

.TicketPage .AddPrdBox::-webkit-scrollbar {
    display: none;
}

.TicketPage .AddPrdBox .prd-slidedown {
    position: sticky;
    top: 0;
    text-align: center;
    background: linear-gradient(to bottom, #fff, transparent);
}

.TicketPage .AddPrdBox .prd-slidedown img {
    width: 30px;
}

.TicketPage .AddPrdBox .prd-item {
    padding-bottom: 1.5rem;
}

.TicketPage .AddPrdBox .prd-item .prd-option .inputbox .btn {
    width: 40px;
}

.TicketPage .AddPrdBox .prd-item .prd-option .inputbox input[type="number"] {
    border: 0;
    width: auto;
    max-width: 65px;
    text-align: center;
    font-size: 30px;
    font-weight: var(--Medium);
    padding: 0;
}

.TicketPage .AddPrdBox .prd-item .prd-option .prd-price {
    font-size: 30px;
    display: inline-flex;
    align-items: center;
    max-width: 60%;
}

.TicketPage .AddPrdBox .prd-item .prd-option .prd-price .price {
    font-weight: var(--Medium);
    max-width: calc(100% - 30px - 1rem);
    line-height: 1.2;
}

.TicketPage .AddPrdBox .prd-item .prd-option .prd-price .closeBtn {
    width: 30px;
    margin-left: 1rem;
}

/* admin */

.TicketPage .StoreTab {
    background-color: #fff;
    border-radius: 0;
    padding: 0;
    margin-top: 0.75rem;
    padding-top: 1.5rem;
    border-bottom: 3px solid #ededed;
}

.TicketPage .StoreTab .nav-tabs {
    justify-content: flex-start;
    gap: 0 2rem;
}

.TicketPage .StoreTab .nav-link {
    font-weight: var(--Medium);
    font-size: 24px;
    color: var(--ColorTypeB);
    border: 0px;
    width: auto;
    border-radius: 0;
    line-height: 1.15;
    padding: 1.25rem 1rem;
    border-bottom: 3px solid transparent;
}

.TicketPage .StoreTab .nav-link.active {
    color: var(--ColorTypeA);
    border: 0px;
    background: #fff;
    border-bottom: 3px solid var(--ColorTypeA);
}

.TicketPage .StoreTab .nav-link span {
    position: relative;
}

.TicketPage .StoreTab .nav-link span::before {
    content: "";
    position: absolute;
    right: -14px;
    top: -10px;
    width: 8px;
    height: 8px;
    background: var(--ColorTypeA);
    border-radius: 50%;
    transition: all 0.4s ease;
    opacity: 0;
}

.TicketPage .StoreTab .nav-link.active span::before {
    opacity: 1;
}

.TicketPage .prd-btnbox {
    gap: 10px;
    margin-top: 2rem;
}

.TicketPage .btn.type14,
.TicketPage .btn.type13 {
    font-size: 22px;
    width: 100%;
    padding: 0.75rem;
    border-radius: 50px;
}

.TicketPage .btn.type13 {
    border-color: #000000;
}

.TicketPage .StoreQR {
    margin-bottom: 0;
    margin-top: -75px;
}

.TicketPage .StoreQR img.QR {
    width: 75px;
}

.TicketPage .no-item {
    padding: 3rem 0;
}

.TicketPage .no-item .no_itemImg {
    max-width: 110px;
    display: block;
    margin: 0 auto 2rem;
}

.TicketPage .no-item .no_txt {
    font-size: 22px;
    text-align: center;
    color: var(--ColorTypeC);
    font-weight: var(--Medium);
}

@media screen and (max-width: 1024px) {
    .TicketPage .TicketPhoto {
        max-height: 50vh;
    }

    .TicketPage .StoreDetail {
        border: 0;
    }
}

@media screen and (max-width: 599px) {
    .TicketPage .no-item {
        padding: 1.5rem 0;
    }

    .TicketPage .no-item .no_itemImg {
        max-width: 75px;
        margin: 0 auto 1rem;
    }

    .TicketPage .no-item .no_txt {
        font-size: 16px;
    }

    .demo .TicketPage .StoreTitle.px-4-5 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important;
    }

    .demo .TicketPage .StoreInfo.px-4-5 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important;
    }

    .demo .TicketPage .StoreTab-content.px-4-5 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important;
    }

    .TicketPage .TicketPhoto {
        max-height: 38vh;
        height: auto;
    }

    .TicketPage .StoreDetail {
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;
    }

    .TicketPage .StoreTitle > .tit {
        font-size: 24px;
    }

    .TicketPage .StoreInfo > .row {
        padding: 0;
        flex-wrap: nowrap;
    }

    .TicketPage .StoreInfo .tit {
        width: 60px;
        margin-right: 15px;
    }

    .TicketPage .StoreInfo .desc {
        width: calc(100% - 75px);
    }

    .TicketPage .StoreDetail {
        border: 0;
    }

    .TicketPage .ProductList {
        padding-bottom: 6.5rem;
    }

    .TicketPage .ProductList .prd-item {
        margin-bottom: 1.5rem;
        padding: 1.5rem 2rem;
        border-radius: 0.75rem;
        box-shadow: 3px 3px 3px 3px rgb(0 0 0 / 10%);
    }

    .TicketPage .prd-tit {
        font-size: 24px;
    }

    .TicketPage .prd-sub {
        font-size: 15px;
    }

    .TicketPage .prd-price {
        font-size: 14px;
    }

    .TicketPage .prd-price .price {
        font-size: 20px;
    }

    .TicketPage .quantity-txt {
        right: -0.8rem;
        font-size: 12px;
        padding: 0.2rem 0.5rem;
        gap: 0 0.25rem;
    }

    .TicketPage .quantity-txt::after {
        bottom: -0.4rem;
        border-top: 0.4rem solid #8e002f;
        border-right: 0.8rem solid transparent;
    }

    .TicketPage .quantity-txt .icon {
        width: 15px;
    }

    .TicketPage .AddPrdBox {
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important;
        height: 18.5rem;
    }

    .TicketPage .AddPrdBox .prd-slidedown img {
        width: 18px;
    }

    .TicketPage .AddPrdBox .prd-item .prd-option .inputbox .btn {
        width: 30px;
    }

    .TicketPage
        .AddPrdBox
        .prd-item
        .prd-option
        .inputbox
        input[type="number"] {
        max-width: 45px;
        font-size: 20px;
    }

    .TicketPage .AddPrdBox .prd-item .prd-option .prd-price {
        font-size: 20px;
    }

    .TicketPage .AddPrdBox .prd-item .prd-option .prd-price .price {
        max-width: calc(100% - 25px - 0.5rem);
    }

    .TicketPage .AddPrdBox .prd-item .prd-option .prd-price .closeBtn {
        width: 25px;
        margin-left: 0.5rem;
    }

    /* admin */

    .demo .TicketPage .StoreTab.px-4-5 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important;
    }

    .TicketPage .StoreTab {
        padding-top: 1rem;
    }

    .TicketPage .StoreTab .nav-tabs {
        gap: 0 1rem;
    }

    .TicketPage .StoreTab .nav-link {
        font-size: 20px;
        padding: 0.75rem 1rem;
        border-bottom: 2px solid transparent;
    }

    .TicketPage .StoreTab .nav-link.active {
        border-bottom: 2px solid var(--ColorTypeA);
    }

    .TicketPage .StoreTab .nav-link span::before {
        right: -12px;
        top: -8px;
        width: 6px;
        height: 6px;
    }

    .TicketPage .btn.type14,
    .TicketPage .btn.type13 {
        font-size: 16px;
        padding: 0.5rem 0.75rem !important;
    }

    .demo .TicketPage .StoreQR.px-4-5 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important;
    }

    .TicketPage .StoreQR {
        margin-top: -60px;
    }

    .TicketPage .StoreQR img.QR {
        width: 60px;
    }
}

@media screen and (max-width: 320px) {
    .TicketPage .StoreTitle > .tit {
        font-size: 20px;
    }

    .TicketPage .AddPrdBox .prd-item .prd-option .prd-price {
        max-width: 55%;
    }
}

/* 23-02-03 */

.TicketPage {
    position: relative;
}

.TicketPage .StorePay {
    position: sticky;
    left: auto;
    transform: none;
}

/* 23-02-15 */

.TicketPage .ProductList {
    padding-bottom: 5rem;
}

.TicketPage .AddPrdBox {
    height: auto;
    max-height: 25rem;
}

.TicketPage .AddPrdBox .prd-slidedown img {
    transition: all 0.2s ease-in-out;
}

.TicketPage .AddPrdBox .prd-slidedown.collapsed img {
    transform: rotate(180deg);
}

@media screen and (max-width: 599px) {
    .TicketPage .ProductList {
        padding-bottom: 3.5rem;
    }
}

/* 23-03-21 */

.TicketPage .StorePay .btn-primary {
    border-radius: 0;
    position: relative;
    z-index: 10;
}

/* iOS 노치 */

.TicketPage .StorePay .btn-primary {
    padding-bottom: calc(constant(safe-area-inset-bottom) + 1.5rem) !important;
    padding-bottom: calc(env(safe-area-inset-bottom) + 1.5rem) !important;
}

@media screen and (max-width: 599px) {
    .TicketPage .StorePay .btn-primary {
        padding-bottom: calc(
            constant(safe-area-inset-bottom) + 1rem
        ) !important;
        padding-bottom: calc(env(safe-area-inset-bottom) + 1rem) !important;
    }
}

/* ver2 */

.TicketPage .PhotoCount {
    width: 100px;
    height: 40px;
    font-size: 17px;
    line-height: 40px;
    font-weight: 300;
    letter-spacing: -1px;
}

.TicketPage .StoreTitle > .logo {
    max-width: 75px;
}

.TicketPage .StoreInfo > .row {
    gap: 0 35px;
}

.TicketPage .StoreInfo .tit {
    width: auto;
}

.TicketPage .StoreInfo .desc {
    width: auto;
    flex-grow: 1;
}

.TicketPage .StoreAppInfo {
    width: calc(100% - 6rem);
    margin: 3rem auto 0;
    background-color: #f7f7f7;
    border: 2px solid #ededed;
    border-radius: 0.75rem;
    padding: 2.25rem 3rem;
}

.TicketPage .StoreAppInfo .title {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

.TicketPage .StoreAppInfo .title .speaker {
    max-width: 28px;
    margin-right: 0.25rem;
}

.TicketPage .StoreAppInfo .title span {
    font-size: 20px;
    color: #000000;
    font-weight: var(--Medium);
}

.TicketPage .StoreAppInfo .txtbox {
    font-size: 17px;
    color: #4c4c4c;
    margin-bottom: 0.5rem;
}

.TicketPage .StoreTab .nav-tabs {
    justify-content: space-between;
    gap: 0;
}

.TicketPage .StoreTab .nav-link {
    width: 50%;
}

.TicketPage .prd-tit {
    margin-bottom: 1rem;
}

.TicketPage .quantity-txt::after {
    bottom: -0.5rem;
}

.TicketPage .prd-time {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 17px;
    color: var(--ColorTypeB);
    font-weight: var(--Medium);
    margin-bottom: 1.5rem;
    padding-left: 10px;
}

.TicketPage .prd-price {
    text-align: right;
}

.TicketPage .prd-time > p {
    margin-bottom: 0;
    text-decoration: underline;
    text-decoration-thickness: 2px;
    text-underline-position: from-font;
    text-underline-offset: 5px;
    line-height: 30px;
    position: relative;
}

.TicketPage .prd-time > p + p {
    margin-top: 0.25rem;
}

.TicketPage .prd-time > p.operating::before {
    content: "#";
    font-size: 20px;
}

.TicketPage .prd-time > p.operating::after {
    content: "";
    width: 26px;
    height: 26px;
    position: absolute;
    left: -14px;
    top: 12px;
    background-color: #000000;
    opacity: 0.08;
    border-radius: 50%;
}

.TicketPage .btn.type14,
.TicketPage .btn.type13 {
    padding: 0.5rem 0.75rem;
}

.TicketPage .ProductList .no-item {
    padding-top: 3rem;
}

.TicketPage .ProductList .no-item .no_itemImg {
    max-width: 110px;
    display: block;
    margin: 0 auto 2rem;
}

.TicketPage .ProductList .no-item .no_txt {
    font-size: 22px;
    text-align: center;
    color: var(--ColorTypeC);
    font-weight: var(--Medium);
}

.TicketPage .StoreTab-wrap {
    min-height: 60vh;
}


@media screen and (max-width: 599px) {
    .TicketPage .PhotoCount {
        width: 65px;
        height: 25px;
        font-size: 13px;
        line-height: 25px;
        font-weight: 300;
        letter-spacing: -0.5px;
    }

    .TicketPage .StoreTitle > .logo {
        max-width: 50px;
    }

    .TicketPage .StoreInfo > .row {
        gap: 0 25px;
    }

    .TicketPage .StoreInfo .tit {
        margin-right: 0;
    }

    .TicketPage .StoreAppInfo {
        width: calc(100% - 3rem);
        margin: 2rem auto 0;
        padding: 1.8rem 1.5rem;
    }

    .TicketPage .StoreAppInfo .title {
        margin-bottom: 0.75rem;
    }

    .TicketPage .StoreAppInfo .title .speaker {
        max-width: 24px;
    }

    .TicketPage .StoreAppInfo .title span {
        font-size: 17px;
    }

    .TicketPage .StoreAppInfo .txtbox {
        word-break: break-word;
        font-size: 14px;
    }

    .TicketPage .ProductList .prd-item {
        padding: 1.5rem;
    }

    .TicketPage .quantity-txt::after {
        bottom: -0.3rem;
    }

    .TicketPage .prd-time {
        font-size: 14px;
        margin-bottom: 1rem;
    }

    .TicketPage .prd-time > p + p {
        margin-top: 0;
    }

    .TicketPage .prd-time > p.operating::before {
        font-size: 16px;
    }

    .TicketPage .prd-time > p.operating::after {
        width: 22px;
        height: 22px;
        left: -12px;
    }
    
    .TicketPage .ProductList .no-item {
        padding-top: 1.5rem;
    }

    .TicketPage .ProductList .no-item .no_itemImg {
        max-width: 75px;
        margin: 0 auto 1rem;
    }

    .TicketPage .ProductList .no-item .no_txt {
        font-size: 16px;
    }

    .TicketPage .StoreTab-wrap {
        min-height: 50vh;
    }
}

@media screen and (max-width: 320px) {
    .TicketPage .StoreAppInfo .txtbox p br {
        display: none;
    }
}



/* 23-05-04 수정 */

.TicketPage .TicketPhoto {
    height: 55vh;
}

.TicketPage .TicketPhoto-Slider {
    height: 100%;
}

.TicketPage .TicketPhoto-Slider .swiper {
    height: 100%;
}

.TicketPage .TicketPhoto-Slider .swiper-slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.TicketPage .StoreInfo .desc {
    flex: 1;
}

@media screen and (max-width: 1024px) {

    .TicketPage .TicketPhoto {
        height: 42vh;
    }
    
}

@media screen and (max-width: 599px) {

    .TicketPage .TicketPhoto {
        height: 42vh;
        max-height: 42vh;
    }
    
}

/* 23.05.08 수정 */

.TicketPage .prd-tit {
    margin-bottom: 1rem;
    padding-right: 75px;
}

@media screen and (max-width:599px) {
    .TicketPage .prd-tit {
        padding-right: 60px;
    }
}

/* ver3 사용자 */

.TicketPage .StoreTitle {
    margin-bottom: 2rem;
}

.TicketPage .ProductList .prd-item {
    padding: 2.5rem 2rem;
}

.TicketPage .ProductList .prd-item .prd-tit {
    margin-bottom: 0.25rem;
}

.TicketPage .ProductList .prd-item .prd-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.TicketPage .ProductList .prd-item .prd-price {
    text-align: left;
    width: 70%;
}

.TicketPage .ProductList .prd-item .prd-order {
    font-weight: 500;
    font-size: 16px;
    color: var(--ColorTypeB);
    background: #F7F7F7;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0 0.5rem;
    padding: 0.35rem 0.5rem;
}

.TicketPage .ProductList .prd-item .prd-order.off {
    color: #999999;
}

.TicketPage .ProductList .prd-item .prd-order .dot {
    width: 16px;
    height: 16px;
    background: #E6E6E6;
    border-radius: 50%;
}

.TicketPage .ProductList .prd-item .prd-order.on .dot {
    background: #58D678;
}

.TicketPage .ProductList .prd-item .prd-order.off .dot {
    background: url(https://www.flexdaycdn.net/public/images/ticket_ver3/order_off.png) no-repeat center / 100%;
}



@media screen and (max-width: 599px) {

    .TicketPage .StoreTitle {
        margin-bottom: 1.5rem;
    }


    .TicketPage .StoreInfo .desc {
        flex: 1;
    }

    .TicketPage .ProductList .prd-item {
        padding: 1.5rem;
    }

    .TicketPage .ProductList .prd-item .prd-tit {
        font-size: 22px;
    }

    .TicketPage .ProductList .prd-item .prd-price {
        width: 60%;
    }

    .TicketPage .ProductList .prd-item .prd-price .price {
        font-size: 22px;
    }

    .TicketPage .ProductList .prd-item .prd-order {
        font-size: 12px;
        gap: 0 0.25rem;
        padding: 0.25rem 0.5rem;
    }

    .TicketPage .ProductList .prd-item .prd-order .dot {
        width: 12px;
        height: 12px;

    }

}


@media screen and (max-width: 320px) {}


/* ver3 가맹점 PC */

.TicketPage .btn.type14, .TicketPage .btn.type13 {
    border-radius: 6px;
    padding: 0.75rem;
}