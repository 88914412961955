.TPopupAddPage .PopupAdd-tit {
  font-weight: var(--Medium);
  color: var(--ColorTypeB);
  font-size: 22px;
}

.TPopupAddPage .PopupAdd-tit > span {
  color: var(--ColorTypeA);
  font-weight: var(--Semibold);
}

.TPopupAddPage .PopupAdd-radiobox {
  gap: 0 1rem;
}

.TPopupAddPage .PopupAdd-radiobox .radioBox {
  border: 1px solid var(--ColorTypeD);
  padding: 0.75rem 1.75rem;
  font-size: 17px;
  font-weight: var(--Regular);
  color: var(--ColorTypeB);
  border-radius: 3px;
  transition: all 0.4s ease;
}

.TPopupAddPage .PopupAdd-radiobox .radioBox:focus-within {
  border-color: var(--ColorTypeB);
}

.TPopupAddPage .PopupAdd-radiobox .radioBox .form-check-input {
  margin: 0;
  vertical-align: middle;
  margin-right: 0.25em;
  width: 16px;
  height: 16px;
}

.TPopupAddPage .PopupAdd-radiobox .radioBox .form-check-input:checked {
  background-color: #fff;
  border-color: var(--ColorTypeA);
  background-image: none;
  position: relative;
}

.TPopupAddPage .PopupAdd-radiobox .radioBox .form-check-input:checked::before {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--ColorTypeA);
  border-radius: 50%;
}

.TPopupAddPage .PopupAdd-inputbox .form-control {
  padding: 1.25rem 1.5rem;
  height: 65px;
  font-size: 17px;
}

.demo .TPopupAddPage .form-control.InputType ~ .price-txt {
  height: 65px;
  line-height: 65px;
  font-weight: var(--Medium);
  margin-right: 1.5rem;
}

.TPopupAddPage .form-text.info-txt {
  font-size: 17px;
  padding: 0.25rem 1.5rem;
}

.TPopupAddPage .PopupAdd-inputbox textarea.form-control {
  -ms-overflow-style: none;
}

.demo .TPopupAddPage .PopupAdd-inputbox .Input-placeholder {
  display: none;
}

.demo
  .TPopupAddPage
  .PopupAdd-inputbox.Input-placeholderbox
  .Input-placeholder {
  display: block;
  padding: 1.25rem 1.5rem;
}

.TPopupAddPage .PopupAdd-inputbox textarea.form-control.ph {
  height: auto;
  min-height: 7em;
}

.TPopupAddPage .PopupAdd-inputbox textarea.form-control::-webkit-scrollbar {
  display: none;
}

.TPopupAddPage .PopupAdd-subbox {
  display: none;
  margin-top: 1.5rem;
  width: 85%;
}

.TPopupAddPage .PopupAdd-subbox.active {
  display: block;
}

.TPopupAddPage .PopupAdd-subbox .subBox .tit {
  font-weight: var(--Medium);
  color: var(--ColorTypeB);
  font-size: 18px;
}

.TPopupAddPage .PopupAdd-subbox .subBox {
  margin-bottom: 1.5rem;
}

.TPopupAddPage .PopupAdd-subbox .subBox .inputbox {
  align-items: center;
  gap: 0 0.75rem;
  font-size: 17px;
  color: var(--ColorTypeB);
}

.TPopupAddPage .PopupAdd-subbox .subBox .inputbox .form-control {
  padding: 0.75rem 1rem;
  height: auto;
  text-align: center;
}

.TPopupAddPage .PopupAdd-subbox .subBox.date .inputbox .form-control {
  width: 145px;
}

.TPopupAddPage .PopupAdd-subbox .subBox.time .inputbox .form-control {
  width: 90px;
}

.TPopupAddPage .PopupAdd-subbox .subBox .PopupAdd-inputbox .form-control {
  padding: 0.75rem 1.5rem;
  height: 55px;
}

.demo
  .TPopupAddPage
  .PopupAdd-subbox
  .subBox
  .form-control.InputType
  ~ .price-txt {
  height: 55px;
  line-height: 55px;
}

.TPopupAddPage .PopupAdd-Btnbox {
  margin-top: 4rem;
}

.TPopupAddPage .PopupAdd-Btnbox .btn-default {
  height: 70px;
  font-size: 22px;
}

/* 모바일 반응형 */
@media screen and (max-width: 599px) {
  .TPopupAddPage .PopupAddBox.px-4-5 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .TPopupAddPage .PopupAdd-tit {
    font-size: 18px;
  }

  .TPopupAddPage .PopupAdd-radiobox .radioBox {
    padding: 0.5rem 1.25rem;
    font-size: 14px;
  }

  .TPopupAddPage .PopupAdd-inputbox .form-control {
    padding: 0.85rem 1.25rem;
    height: 50px;
    font-size: 14px;
  }

  .demo .TPopupAddPage .form-control.InputType ~ .price-txt {
    height: 50px;
    line-height: 50px;
    margin-right: 1.25rem;
  }

  .TPopupAddPage .form-text.info-txt {
    font-size: 14px;
    padding: 0.25rem 1.25rem;
  }

  .TPopupAddPage .PopupAdd-subbox .subBox .tit {
    font-size: 16px;
  }

  .TPopupAddPage .PopupAdd-subbox .subBox .inputbox {
    gap: 0 0.5rem;
    font-size: 14px;
  }

  .TPopupAddPage .PopupAdd-subbox .subBox .inputbox .form-control {
    font-size: 14px;
    padding: 0.5rem 1rem;
  }

  .TPopupAddPage .PopupAdd-subbox .subBox.date .inputbox .form-control {
    width: 120px;
  }

  .TPopupAddPage .PopupAdd-subbox .subBox.time .inputbox .form-control {
    width: 75px;
  }

  .TPopupAddPage .PopupAdd-subbox .subBox .PopupAdd-inputbox .form-control {
    padding: 0.5rem 1.25rem;
    height: 45px;
  }

  .demo
    .TPopupAddPage
    .PopupAdd-subbox
    .subBox
    .form-control.InputType
    ~ .price-txt {
    height: 45px;
    line-height: 45px;
  }

  .TPopupAddPage .PopupAdd-Btnbox .btn-default {
    height: 55px;
    font-size: 16px;
  }
}

/* 23-02-03 */

.TPopupAddPage .PopupAdd-subbox .subBox .inputbox.disabled .form-control {
  pointer-events: none;
  background-color: var(--ColorTypeE);
}

/* 23-02-14 */

.TPopupAddPage .PopupAdd-subbox {
  width: 100%;
  background-color: #f7f7f7;
  padding: 2rem 1.5rem;
  border-radius: 3px;
}

.TPopupAddPage .PopupAdd-subbox .subBox .tit span {
  word-break: keep-all;
}

@media screen and (max-width: 320px) {
  .TPopupAddPage .PopupAdd-subbox {
    padding: 1.5rem 1rem;
  }

  .TPopupAddPage .PopupAdd-subbox .subBox.date .inputbox .form-control {
    width: 105px;
    padding: 0.5rem 0.75rem;
  }
}

/* ver2 */

.TPopupAddPage .PopupAdd-sub {
  color: var(--ColorTypeC);
  font-size: 16px;
  margin-top: 0.25rem;
}

.TPopupAddPage .PopupAdd-datebox .range {
  align-items: center;
  gap: 0 1.5rem;
  font-size: 17px;
  color: var(--ColorTypeB);
}

.TPopupAddPage .PopupAdd-datebox .datepicker-box {
  position: relative;
}

.TPopupAddPage .PopupAdd-datebox .datepicker-box img {
  position: absolute;
  left: 1.5rem;
  top: 49%;
  transform: translateY(-50%);
  max-width: 20px;
}

.TPopupAddPage .PopupAdd-datebox .form-control.input-datepicker {
  padding: 0.75rem 1rem;
  height: auto;
  padding-left: calc(2.25rem + 20px);
  width: 100%;
  max-width: 180px;
}

.TPopupAddPage .PopupAdd-radiobox.ver2 {
  gap: 1rem;
  flex-wrap: wrap;
}

.TPopupAddPage .PopupAdd-radiobox.ver2 .radioBox {
  padding: 0.75rem 0.75rem;
  flex: 1;
  text-align: center;
}

.TPopupAddPage .PopupAdd-radiobox.ver2 .Pop-filebox {
  width: 100%;
  display: none;
}

.TPopupAddPage .PopupAdd-radiobox.ver2 .Pop-filebox.show {
  display: block;
}

.TPopupAddPage .PopupAdd-radiobox.ver2 .Pop-filebox label {
  padding: 1.25rem 1.5rem;
  height: 65px;
  font-size: 17px;
  color: var(--ColorTypeD);
  display: flex;
  align-items: center;
  position: relative;
  padding-right: calc(1.5rem + 30px);
}

.TPopupAddPage .PopupAdd-radiobox.ver2 .Pop-filebox input:hover + label {
  border-color: var(--ColorTypeB);
}

.TPopupAddPage .PopupAdd-radiobox.ver2 .Pop-filebox label .file_icon {
  width: 18px;
  margin-right: 0.5rem;
  display: none;
}

.TPopupAddPage .PopupAdd-radiobox.ver2 .Pop-filebox label .file_text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.TPopupAddPage .PopupAdd-radiobox.ver2 .Pop-filebox label .file_delete {
  width: 17px;
  position: absolute;
  right: 1.5rem;
  top: 50%;
  transform: translateY(-50%);
  display: none;
}

.TPopupAddPage .PopupAdd-radiobox.ver2 .Pop-filebox label.on {
  color: var(--ColorTypeB);
}

.TPopupAddPage .PopupAdd-radiobox.ver2 .Pop-filebox label.on .file_icon {
  display: block;
}

.TPopupAddPage .PopupAdd-radiobox.ver2 .Pop-filebox label.on .file_delete {
  display: block;
}

.TPopupAddPage .PopupAdd-inputbox textarea.form-control.ph {
  padding: 2rem 1.5rem;
  line-height: 2;
}

.demo
  .TPopupAddPage
  .PopupAdd-inputbox.Input-placeholderbox
  .Input-placeholder {
  padding: 2rem 1.5rem;
  line-height: 2;
  font-weight: 300;
}

.TPopupAddPage .PopupAdd-inputbox.Input-placeholderbox .small {
  font-size: 10px;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 0.5px;
  margin-right: 1px;
}

.TPopupAddPage .PopupAdd-subbox {
  border: 1px solid var(--ColorTypeB);
}

.TPopupAddPage .PopupAdd-subbox .subBox .tit {
  margin-bottom: 0.75rem;
}

.TPopupAddPage .PopupAdd-subbox .subBox .tit span {
  color: var(--ColorTypeA);
}

.TPopupAddPage .PopupAdd-subbox .subBox .checkContent {
  flex: 1;
}

.TPopupAddPage .PopupGuide {
  background-color: #f7f7f7;
  padding: 3rem 0 1.5rem;
  text-align: right;
  position: relative;
}

.TPopupAddPage .PopupGuide .Guide-wrap {
  position: absolute;
  left: 0;
  top: 6rem;
  width: 100%;
  z-index: 10;
  pointer-events: none;
}

.TPopupAddPage .PopupGuide .Guide-wrap .Guide-Box {
  position: relative;
  background: rgba(237, 21, 86, 0.08);
}

.TPopupAddPage .PopupGuide .Guide-wrap .Guide-Box::before {
  content: "";
  background: url(https://www.flexdaycdn.net/public/images/ticket_ver2/border_dashed.png)
    no-repeat center / 100%;
  width: 100%;
  height: 1px;
  position: absolute;
  left: 0;
  top: 0;
}
.TPopupAddPage .PopupGuide .Guide-wrap .Guide-Box::after {
  content: "";
  background: url(https://www.flexdaycdn.net/public/images/ticket_ver2/border_dashed.png)
    no-repeat center / 100%;
  width: 100%;
  height: 1px;
  position: absolute;
  left: 0;
  bottom: 0;
}

.TPopupAddPage .PopupGuide .Guide-wrap .Guide-Box .txt {
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  font-size: 17px;
  line-height: 1.2;
  color: var(--ColorTypeA);
  font-weight: var(--Semibold);
}

.TPopupAddPage .PopupGuide .Guide-wrap .Guide-Box .txt .small {
  font-size: 10px;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 0.5px;
  margin-right: 1px;
}

.TPopupAddPage .PopupGuide .Guide-wrap .Guide-Box .space1 {
  font-size: 40px;
  line-height: 1.2;
  margin-bottom: 0.35rem;
  margin-top: -0.25rem;
  padding-top: 0.5rem;
  color: transparent;
  pointer-events: none;
}

.TPopupAddPage .PopupGuide .Guide-wrap .Guide-Box .space2 {
  font-size: 18px;
  line-height: 1.4;
  padding: 0.25rem 0;
  color: transparent;
  pointer-events: none;
}

.TPopupAddPage .PopupGuide .Guide-Slider {
  text-align: left;
}

.TPopupAddPage .PopupGuide .Guide-Slider .swiper-slide {
  height: 365px;
  background: no-repeat center / cover;
  padding: 3rem 3rem 3.5rem;
  border-radius: 2.5rem 2.5rem 0 0;
  overflow: hidden;
}

.TPopupAddPage .PopupGuide .Guide-Slider .swiper-slide .tit {
  font-size: 40px;
  line-height: 1.2;
  margin-bottom: 1rem;
  color: #313131;
  font-weight: var(--Medium);
  overflow: hidden;
  -webkit-line-clamp: 2;
  white-space: normal;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  word-break: break-word;
}

.TPopupAddPage .PopupGuide .Guide-Slider .swiper-slide .tit .color {
  color: #495bc9;
}

.TPopupAddPage .PopupGuide .Guide-Slider .swiper-slide .sub {
  font-size: 18px;
  margin-bottom: 1.5rem;
  color: #313131;
  line-height: 1.4;
  overflow: hidden;
  -webkit-line-clamp: 3;
  white-space: normal;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  word-break: break-word;
}

.TPopupAddPage .PopupGuide .Guide-Slider .swiper-slide .button {
  font-size: 18px;
  color: #fff;
  background-color: #495bc9;
  display: inline-block;
  border-radius: 30px;
  padding: 0.4rem 3rem;
  font-family: "NanumSquareNeo", "Pretendard", sans-serif;
}

.TPopupAddPage .PopupGuide .Guide-Slider .swiper-slide.slide1 {
  background-image: url(https://www.flexdaycdn.net/public/images/ticket_ver2/pop_img1.png);
}

.TPopupAddPage .PopupGuide .Guide-Slider .swiper-slide.slide1 .tit,
.TPopupAddPage .PopupGuide .Guide-Slider .swiper-slide.slide1 .sub {
  font-family: "NanumSquareNeo", "Pretendard", sans-serif;
}

.TPopupAddPage .PopupGuide .Guide-Slider .swiper-slide.slide2 {
  background-image: url(https://www.flexdaycdn.net/public/images/ticket_ver2/pop_img2.png);
  font-family: "SBAggro", "Pretendard", sans-serif;
}

.TPopupAddPage .PopupGuide .Guide-Slider .swiper-slide.slide2 .tit {
  font-weight: var(--Bold);
  color: var(--ColorTypeB);
}

.TPopupAddPage .PopupGuide .Guide-Slider .swiper-slide.slide2 .tit .color {
  color: #0038fd;
}

.TPopupAddPage .PopupGuide .Guide-Slider .swiper-slide.slide2 .sub {
  color: var(--ColorTypeB);
}

.TPopupAddPage .PopupGuide .Guide-Slider .swiper-slide.slide2 .button {
  background-color: #0038fd;
}

.TPopupAddPage .PopupGuide .Guide-pag {
  position: relative;
  left: auto;
  bottom: auto;
  width: auto;
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 30px;
  padding: 0.15rem 1rem;
  color: #fff;
  font-size: 15px;
  font-weight: 300;
  margin-top: 2rem;
  margin-right: 3rem;
}

.TPopupAddPage .PopupGuide .Guide-pag .swiper-pagination-current {
  font-weight: var(--Medium);
}

.TPopupAddPage .DatepickerBox.ver1 .form-control.InputType.input-datepicker.event-add {
  padding-left: calc(2.25rem + 20px);
  width: 100%;
  max-width: 180px;
  font-size: 17px;
  background-size: 20px;
  background-position-x: 1.5rem;
}

@media screen and (max-width: 599px) {
  .TPopupAddPage .PopupAdd-datebox .range {
    gap: 0 1.25rem;
    font-size: 14px;
  }

  .TPopupAddPage .PopupAdd-datebox .datepicker-box img {
    left: 1rem;
    max-width: 17px;
  }

  .TPopupAddPage .PopupAdd-datebox .form-control.input-datepicker {
    font-size: 14px;
    padding: 0.75rem 1rem;
    padding-left: calc(1.5rem + 17px);
    max-width: 140px;
  }

  .TPopupAddPage .PopupAdd-radiobox.ver2 .radioBox {
    padding: 0.75rem 1.25rem;
    flex: initial;
  }

  .TPopupAddPage .PopupAdd-radiobox.ver2 .Pop-filebox label {
    padding: 0.85rem 1.25rem;
    padding-right: calc(1.25rem + 30px);
    height: 50px;
    font-size: 14px;
  }

  .TPopupAddPage .PopupAdd-radiobox.ver2 .Pop-filebox label .file_icon {
    width: 15px;
  }

  .TPopupAddPage .PopupAdd-radiobox.ver2 .Pop-filebox label .file_delete {
    width: 15px;
  }

  .TPopupAddPage .PopupAdd-sub {
    font-size: 14px;
  }

  .TPopupAddPage .PopupAdd-inputbox textarea.form-control.ph {
    padding: 1.5rem;
  }

  .demo
    .TPopupAddPage
    .PopupAdd-inputbox.Input-placeholderbox
    .Input-placeholder {
    padding: 1.5rem;
  }

  .TPopupAddPage .PopupGuide .Guide-wrap {
    top: 5.5rem;
  }

  .TPopupAddPage .PopupGuide .Guide-wrap .Guide-Box .txt {
    left: 0.75rem;
    font-size: 14px;
  }

  .TPopupAddPage .PopupGuide .Guide-wrap .Guide-Box .space1 {
    font-size: 30px;
    margin-bottom: 0.2rem;
  }

  .TPopupAddPage .PopupGuide .Guide-wrap .Guide-Box .space2 {
    font-size: 14px;
  }

  .TPopupAddPage .PopupGuide .Guide-Slider .swiper-slide {
    height: 275px;
    padding: 2.5rem 2rem 3rem;
    border-radius: 1.75rem 1.75rem 0 0;
  }

  .TPopupAddPage .PopupGuide .Guide-Slider .swiper-slide .tit {
    font-size: 30px;
    margin-bottom: 0.75rem;
  }

  .TPopupAddPage .PopupGuide .Guide-Slider .swiper-slide .sub {
    font-size: 14px;
    margin-bottom: 1.25rem;
  }

  .TPopupAddPage .PopupGuide .Guide-Slider .swiper-slide .button {
    font-size: 14px;
    padding: 0.4rem 2.5rem;
  }

  .TPopupAddPage .PopupGuide .Guide-pag {
    font-size: 13px;
    margin-top: 1.5rem;
    margin-right: 1.5rem;
  }

  .TPopupAddPage .DatepickerBox.ver1 > .d-flex.ver2 {
    gap: 0 1.25rem;
  }

  .TPopupAddPage .DatepickerBox.ver1 .form-control.InputType.input-datepicker.event-add {
    padding-left: calc(1.5rem + 17px);
    max-width: 140px;
    font-size: 14px;
    background-size: 17px;
    background-position-x: 1rem;
  }
}

@media screen and (max-width: 320px) {
  .TPopupAddPage .PopupAdd-datebox .range {
    gap: 0 1rem;
  }

  .TPopupAddPage .PopupAdd-datebox .datepicker-box img {
    display: none;
  }

  .TPopupAddPage .PopupAdd-datebox .form-control.input-datepicker {
    padding: 0.75rem 0.5rem;
    text-align: center;
  }

  .TPopupAddPage .PopupAdd-radiobox.ver2 .radioBox {
    padding: 0.75rem 0.75rem;
  }

  .TPopupAddPage .PopupGuide .Guide-wrap .Guide-Box .space1 {
    font-size: 28px;
  }

  .TPopupAddPage .PopupGuide .Guide-wrap .Guide-Box .space2 {
    font-size: 12px;
  }

  .TPopupAddPage .PopupGuide .Guide-Slider .swiper-slide {
    padding: 2.5rem 1.5rem 3rem;
  }

  .TPopupAddPage .PopupGuide .Guide-Slider .swiper-slide .tit {
    font-size: 28px;
  }

  .TPopupAddPage .PopupGuide .Guide-Slider .swiper-slide .sub {
    font-size: 12px;
  }

  .TPopupAddPage .DatepickerBox.ver1 > .d-flex.ver2 {
    gap: 0 1rem;
  }

  .TPopupAddPage .DatepickerBox.ver1 .form-control.InputType.input-datepicker.event-add {
    padding: 0.75rem 0.5rem;
    text-align: center;
  }
}
