.TBasicInfoPage .BasicInfo-tit {
  font-weight: var(--Medium);
  color: var(--ColorTypeB);
  font-size: 25px;
}

.TBasicInfoPage .PhotoList .col-4 {
  width: calc((100% - 2.6rem) / 3);
  padding: 0px;
  position: relative;
  padding-bottom: calc((100% - 2.6rem) / 3);
  height: 0;
  border-radius: 5px;
  overflow: hidden;
}

.TBasicInfoPage .PhotoList .File-Box {
  border: 2px dashed var(--ColorTypeD);
}

.TBasicInfoPage .PhotoList .File-Box img {
  max-width: 18px;
  margin: 0px auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.TBasicInfoPage .PhotoList .PhotoImgbox {
  height: auto;
}

.TBasicInfoPage .PhotoList .col-4 .PhotoImgbox > img {
  position: absolute;
  left: 0;
  top: 0;
}

.TBasicInfoPage .BasicInfo-inputbox .form-control {
  padding: 1.25rem 1.5rem;
  height: 65px;
  font-size: 17px;
}

.TBasicInfoPage .BasicInfo-inputbox textarea.form-control {
  -ms-overflow-style: none;
}

.TBasicInfoPage .BasicInfo-inputbox textarea.form-control::-webkit-scrollbar {
  display: none;
}

.TBasicInfoPage .BasicInfo-Btnbox {
  margin-top: 4rem;
}

.TBasicInfoPage .BasicInfo-Btnbox .btn-default {
  height: 70px;
  font-size: 22px;
}

/* 모바일 반응형 */
@media screen and (max-width: 599px) {
  .TBasicInfoPage .BasicInfoBox.px-4-5 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .TBasicInfoPage .BasicInfo-tit {
    font-size: 18px;
  }

  .TBasicInfoPage .PhotoList .File-Box {
    border: 1px dashed var(--ColorTypeD);
  }

  .TBasicInfoPage .PhotoList .File-Box img {
    max-width: 14px;
  }

  .TBasicInfoPage .BasicInfo-inputbox .form-control {
    padding: 0.85rem 1.25rem;
    height: 50px;
    font-size: 14px;
  }

  .TBasicInfoPage .BasicInfo-Btnbox .btn-default {
    height: 55px;
    font-size: 16px;
  }
}

/* 23-02-15 */

.TBasicInfoPage .PhotoList .PhotoImgbox .deleteBtn {
  position: absolute;
  right: 0.75rem;
  top: 0.75rem;
  width: 1.5em;
}

@media screen and (max-width: 599px) {
  .TBasicInfoPage .PhotoList .PhotoImgbox .deleteBtn {
    right: 0.5rem;
    top: 0.5rem;
    width: 1em;
  }
}

/* ver2 */

.TBasicInfoPage .BasicInfo-tit .colorA {
  color: var(--ColorTypeA);
}

.TBasicInfoPage .BasicInfo-inputbox .PhotoList {
  padding: 0 1rem;
}

.TBasicInfoPage .BasicInfo-inputbox textarea.form-control {
  overflow: hidden;
  text-overflow: ellipsis;
}

.TBasicInfoPage .BasicInfo-inputbox textarea.form-control::placeholder {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.TBasicInfoPage .BasicInfo-inputbox .checkbox {
  margin-top: 0.75rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  flex-wrap: wrap;
}

/* .TBasicInfoPage .BasicInfoBox {
  background: #fff;
  border: 1px solid var(--ColorTypeD);
  border-top: 0;
} */

.TBasicInfoPage .BasicInfo-inputbox .checkbox .form-check-input {
  border: 2px solid #d3d3d3;
  border-radius: 0.25rem;
  width: 20px;
  height: 20px;
  margin-top: 0;
  background-color: #fff;
}

.TBasicInfoPage
  .BasicInfo-inputbox
  .checkbox
  .form-check-input:checked[type="checkbox"] {
  background: url(https://www.flexdaycdn.net/public/images/ticket_ver2/checkbox_check.png)
    no-repeat center center / 0.7em;
  border: 2px solid var(--ColorTypeA);
}

.TBasicInfoPage .BasicInfo-inputbox .checkbox label {
  font-size: 17px;
  color: var(--ColorTypeB);
}

.TBasicInfoPage .BasicInfo-inputbox .checkbox .non_openDatebox {
  display: none;
}

.TBasicInfoPage
  .BasicInfo-inputbox
  .checkbox
  .form-check-input:checked[type="checkbox"]
  ~ .non_openDatebox {
  display: block;
}

.TBasicInfoPage .BasicInfo-inputbox .checkbox .non_openDate {
  display: flex;
  align-items: center;
  gap: 0 0.75rem;
  margin-top: 0.5rem;
}

.TBasicInfoPage .BasicInfo-inputbox .checkbox .non_openDate + .non_openDate {
  margin-top: 1rem;
}

.TBasicInfoPage .BasicInfo-inputbox .range {
  align-items: center;
  gap: 0 0.75rem;
  font-size: 17px;
  color: var(--ColorTypeB);
}

.TBasicInfoPage .BasicInfo-inputbox .datepicker-box {
  position: relative;
}

.TBasicInfoPage .BasicInfo-inputbox .datepicker-box img {
  position: absolute;
  left: 1rem;
  top: 49%;
  transform: translateY(-50%);
  max-width: 15px;
}

.TBasicInfoPage .BasicInfo-inputbox .form-control.input-datepicker {
  padding: 0.5rem 1rem;
  height: auto;
  padding-left: calc(1.25rem + 20px);
}

.TBasicInfoPage
  .BasicInfo-inputbox
  .form-control.input-datepicker::placeholder {
  font-size: 17px;
}

.TBasicInfoPage .BasicInfo-inputbox .button {
  width: auto;
  height: auto;
  padding: 0.5rem 1rem;
  white-space: nowrap;
  border-radius: 35px;
  border: 1px solid var(--ColorTypeB);
}

.TBasicInfoPage .BasicInfo-inputbox .btn-light {
  color: var(--ColorTypeB);
}

.TBasicInfoPage
  .BasicInfo-inputbox
  .checkbox
  .non_openDatebox
  .non_openDate:nth-child(1):last-child
  .btn-default {
  border-color: #c3c3c3;
  background-color: #c3c3c3;
  pointer-events: none;
}

.TBasicInfoPage .BasicInfo_Switch {
  position: relative;
}

.TBasicInfoPage .BasicInfo_Switch .form-switch {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  padding: 0;
  margin: 0;
}

.TBasicInfoPage .BasicInfo_Switch .form-switch .form-check-input {
  background-color: var(--ColorTypeD);
  border-color: var(--ColorTypeD);
  width: 104px;
  height: 34px;
  margin: 0;
  float: none;
  background-image: url(https://www.flexdaycdn.net/public/images/ticket_ver2/switch2.png);
  background-size: auto calc(100% - 4px);
  background-position: 2px center;
  transition: background-position 0.3s ease-in-out;
}

.TBasicInfoPage
  .BasicInfo_Switch
  .form-switch
  .form-check-input:checked[type="checkbox"] {
  background-color: var(--ColorTypeA);
  border-color: var(--ColorTypeA);
  background-position: calc(100% - 2px) center;
}

.TBasicInfoPage .BasicInfo_Switch .form-switch .form-check-label {
  position: absolute;
  font-size: 15px;
  color: #fff;
  transition: all 0.2s ease-in-out;
  pointer-events: none;
}

.TBasicInfoPage .BasicInfo_Switch .form-switch .form-check-label.on {
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
}

.TBasicInfoPage .BasicInfo_Switch .form-switch .form-check-label.off {
  right: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
}

.TBasicInfoPage
  .BasicInfo_Switch
  .form-switch
  .form-check-input:checked[type="checkbox"]
  ~ .form-check-label.on {
  opacity: 1;
}

.TBasicInfoPage
  .BasicInfo_Switch
  .form-switch
  .form-check-input:checked[type="checkbox"]
  ~ .form-check-label.off {
  opacity: 0;
}

.TBasicInfoPage .inputbox-switch {
  display: none;
}

.TBasicInfoPage .inputbox-switch.checked {
  display: block;
}

.TBasicInfoPage .form-text.info-txt {
  font-size: 17px;
  word-break: keep-all;
}

.TBasicInfoPage .DatepickerBox.ver1 > .d-flex.ver2 {
  gap: 0 0.75rem;
}

.TBasicInfoPage .DatepickerBox.ver1 .form-control.InputType.input-datepicker.storeEdit-add {
 font-size: 17px;
}


@media screen and (max-width: 599px) {
  .TBasicInfoPage .BasicInfo-inputbox .PhotoList {
    padding: 0 0.5rem;
  }

  .TBasicInfoPage .BasicInfo-inputbox .checkbox label {
    font-size: 14px;
  }

  .TBasicInfoPage .BasicInfo-inputbox .checkbox .non_openDate {
    gap: 0.5rem 0.75rem;
    flex-wrap: wrap;
    justify-content: center;
  }

  .TBasicInfoPage .BasicInfo-inputbox .checkbox .non_openDate + .non_openDate {
    margin-top: 1.5rem;
  }

  .TBasicInfoPage .BasicInfo-inputbox .range {
    gap: 0 0.5rem;
    font-size: 14px;
  }

  .TBasicInfoPage .BasicInfo-inputbox .datepicker-box img {
    left: 1rem;
    max-width: 17px;
  }

  .TBasicInfoPage .BasicInfo-inputbox .form-control.input-datepicker {
    padding-left: calc(1.5rem + 17px);
    padding-right: 0;
    font-size: 15px;
  }

  .TBasicInfoPage
    .BasicInfo-inputbox
    .form-control.input-datepicker::placeholder {
    font-size: 14px;
  }

  .TBasicInfoPage .BasicInfo-inputbox .button {
    width: 45%;
    margin-top: 0.25rem;
  }

  .TBasicInfoPage .BasicInfo_Switch .form-switch .form-check-input {
    width: 90px;
    height: 28px;
  }

  .TBasicInfoPage .BasicInfo_Switch .form-switch .form-check-label {
    font-size: 13px;
  }

  .TBasicInfoPage .BasicInfo-Btnbox.px-4-5 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .TBasicInfoPage .form-text.info-txt {
    font-size: 14px;
  }

  .TBasicInfoPage .DatepickerBox.ver1 > .d-flex.ver2 {
    gap: 0 0.5rem;
  }

  .TBasicInfoPage .DatepickerBox.ver1 .form-control.InputType.input-datepicker.storeEdit-add {
    font-size: 15px;
}
  
  
}

@media screen and (max-width: 320px) {
  .TBasicInfoPage .BasicInfo-inputbox .datepicker-box img {
    display: none;
  }

  .TBasicInfoPage .BasicInfo-inputbox .form-control.input-datepicker {
    text-align: center;
    text-align-last: center;
    -ms-text-align-last: center;
    -moz-text-align-last: center;
    padding-left: 0;
  }

  .TBasicInfoPage .DatepickerBox.ver1 .form-control.InputType.input-datepicker.storeEdit-add {
    font-size: 15px;
    text-align: center;
    text-align-last: center;
    -ms-text-align-last: center;
    -moz-text-align-last: center;
    padding-left: 0;
    padding-right: 0;
    background-image: none;
}
}
