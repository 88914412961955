.TBasicInfoPage .BasicInfo-tit {
    font-weight: var(--Medium);
    color: var(--ColorTypeB);
    font-size: 25px;
}

.TBasicInfoPage .PhotoList .File-Box {
    border: 2px dashed var(--ColorTypeD);
}

.TBasicInfoPage .PhotoList .File-Box img {
    max-width: 18px;
    margin: 0px auto;
}

.TBasicInfoPage .PhotoList .PhotoImgbox {
    border-radius: 5px;
    overflow: hidden;
}

.TBasicInfoPage .BasicInfo-inputbox .form-control {
    padding: 1.25rem 1.5rem;
    height: 65px;
    font-size: 17px;
}

.TBasicInfoPage .BasicInfo-inputbox textarea.form-control {
    -ms-overflow-style: none;
}

.TBasicInfoPage .BasicInfo-inputbox textarea.form-control::-webkit-scrollbar {
    display: none;
}

.TBasicInfoPage .BasicInfo-Btnbox {
    margin-top: 4rem;
}

.TBasicInfoPage .BasicInfo-Btnbox .btn-default {
    height: 70px;
    font-size: 22px;
}


/* 모바일 반응형 */
@media screen and (max-width: 599px) {

    .TBasicInfoPage .BasicInfoBox.px-4-5 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important;
    }

    .TBasicInfoPage .BasicInfo-tit {
        font-size: 18px;
    }

    .TBasicInfoPage .PhotoList .File-Box {
        border: 1px dashed var(--ColorTypeD);
    }

    .TBasicInfoPage .PhotoList .File-Box img {
        max-width: 14px;
    }

    .TBasicInfoPage .BasicInfo-inputbox .form-control {
        padding: 0.85rem 1.25rem;
        height: 50px;
        font-size: 14px;
    }

    .TBasicInfoPage .BasicInfo-Btnbox .btn-default {
        height: 55px;
        font-size: 16px;
    }

}