/* .TSettlementPage .PayCateTab .nav-pills {
    border-bottom: 1px solid var(--ColorTypeE);
}

.TSettlementPage .PayCateBtn {
    font-weight: var(--Regular);
    font-size: 25px;
    color: var(--ColorTypeD);
    border-radius: 0;
    --bs-nav-link-padding-x: 0.5rem;
    position: relative;
}

.TSettlementPage .nav-pills .PayCateBtn.active {
    font-weight: var(--Bold);
    color: var(--ColorTypeB);
    border-radius: 0;
    background-color: transparent;
    border-bottom: 3px solid var(--ColorTypeA);
}

.TSettlementPage .PayCateBtn.active::before {
    content: "";
    position: absolute;
    right: 0;
    top: 0.2rem;
    width: 6px;
    height: 6px;
    background: var(--ColorTypeA);
    border-radius: 50%;
}

.TSettlementPage .SettleList .SettleTop1 {
    border-bottom: 20px solid var(--ColorTypeE);
}

.TSettlementPage .SettleList .SettleTop1 .Settle-sub {
    font-weight: var(--Medium);
    font-size: 27px;
    color: var(--ColorTypeC);
}

.TSettlementPage .SettleList .SettleTop1 .Settle-date {
    font-weight: var(--Medium);
    font-size: 27px;
    color: var(--ColorTypeB);
}

.TSettlementPage .SettleList .SettleTop1 .Settle-price {
    font-weight: var(--Medium);
    font-size: 32px;
    color: var(--ColorTypeB);
    word-break: break-word;
}

.TSettlementPage .SettleList .SettleTop1 .Settle-price span {
    font-weight: var(--Semibold);
    font-size: 60px;
    margin-right: 8px;
}

.TSettlementPage .SettleList .SettleTop1 .btn-default {
    height: 70px;
    font-size: 22px;
    border-radius: 50px;
    margin: 2rem 0 1rem;
}

.TSettlementPage .SettleList .SettleBottom1 .Listitem {
    border-bottom: 1px solid var(--ColorTypeE);
}

.TSettlementPage .SettleList .SettleBottom1 .Listitem:last-child {
    border-bottom: 0px;
}

.TSettlementPage .SettleList .SettleBottom1 .Listitem .time {
    font-weight: var(--Regular);
    font-size: 20px;
    color: var(--ColorTypeC);
    margin-bottom: 0;
}

.TSettlementPage .SettleList .SettleBottom1 .Listitem .cancel-txt {
    font-weight: var(--Regular);
    font-size: 20px;
    color: var(--ColorTypeA);
    margin-bottom: 0;
}

.TSettlementPage .SettleList .SettleBottom1 .Listitem img.logo {
    width: 85px;
    align-self: center;
}

.TSettlementPage .SettleList .SettleBottom1 .Listitem .txtbox {
    width: calc(100% - 115px);
}

.TSettlementPage .SettleList .SettleBottom1 .Listitem .txtbox .name {
    font-weight: var(--Medium);
    font-size: 22px;
    color: var(--ColorTypeC);
    word-break: break-word;
}

.TSettlementPage .SettleList .SettleBottom1 .Listitem .txtbox .t-num {
    font-weight: var(--Medium);
    font-size: 18px;
    color: var(--ColorTypeC);
}

.TSettlementPage .SettleList .SettleBottom1 .Listitem .txtbox .price {
    font-weight: var(--Medium);
    font-size: 25px;
    color: var(--ColorTypeB);
    word-break: break-word;
    margin-bottom: 0;
}

.TSettlementPage .SettleList .SettleBottom1 .Listitem .txtbox .price span.num {
    font-size: 32px;
}

.TSettlementPage .SettleList .SettleBottom1 .Listitem.cancel .txtbox .price{
   color: var(--ColorTypeA);
}

.TSettlementPage .SettleList .SettleBottom1 .NoListitem {
    padding: 15vh 0;
}

.TSettlementPage .SettleList .SettleBottom1 .NoListitem  img.icon{
    width: 50px;
    margin-bottom: 1.5rem;
}

.TSettlementPage .SettleList .SettleBottom1 .NoListitem .desc {
    font-weight: var(--Medium);
    font-size: 25px;
    color: var(--ColorTypeD);
    margin-bottom: 0;
}

.TSettlementPage .SettleList .SettleTop2 {
    border-bottom: 20px solid var(--ColorTypeE);
}

.TSettlementPage .SettleList .SettleTop2 img.smile {
    width: 65px;
}

.TSettlementPage .SettleList .SettleTop2 .PageTit {
    font-size: 27px;
}

.TSettlementPage .SettleList .SettleTop2 .PageTit .color {
    color: var(--ColorTypeA);
}

.TSettlementPage .SettleList .SettleTop2 .accordion {
    --bs-accordion-btn-icon: url(https://www.flexdaycdn.net/public/images/static/login/drop_check2.png);
    --bs-accordion-btn-active-icon: url(https://www.flexdaycdn.net/public/images/static/login/drop_check2.png);
    --bs-accordion-btn-icon-width: 1rem;
    --bs-accordion-btn-icon-transform: rotate(180deg);
    --bs-accordion-btn-icon-transform2: rotate(180deg);
}

.TSettlementPage .SettleList .SettleTop2 .accordion-button {
    color: var(--ColorTypeC);
    font-weight: var(--Medium);
    font-size: 22px;
}

.TSettlementPage .SettleList .SettleTop2 .accordion-button::after {
    background-position: center;
    margin-left: 8px;
}

.TSettlementPage .Calendar {
    border: 1px solid var(--ColorTypeD);
    border-radius: 10px;
    font-weight: var(--Medium);
    font-size: 18px;
    color: var(--ColorTypeB);
}

.TSettlementPage .Calendar .ColorA {
    color: var(--ColorTypeA);
}

.TSettlementPage .Calendar .ColorF {
    color: #293eb7;
}

.TSettlementPage .Calendar .Week-NowDate {
    background-color: rgb(237 21 86 / 10%);
    border-radius: 50px;
}

.TSettlementPage .Calendar .Week-NextDate .active span {
    position: relative;
    color: #fff;
    z-index: 0;
}

.TSettlementPage .Calendar .Week-NextDate .active span::before {
    content: "";
    background-color: rgb(237 21 86 / 10%);
    border-radius: 50%;
    width: 50px;
    height: 50px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
}

.TSettlementPage .Calendar .Week-NextDate .active span::after {
    content: "";
    background-color: var(--ColorTypeA);
    border-radius: 50%;
    width: 35px;
    height: 35px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: -2;
}

.TSettlementPage .SettleList .SettleBottom2 .Listitem {
    border-bottom: 1px solid var(--ColorTypeE);
}

.TSettlementPage .SettleList .SettleBottom2 .Listitem:last-child {
    border-bottom: 0px;
}

.TSettlementPage .SettleList .SettleBottom2 .Listitem .txtbox {
    width: calc(100% - 145px);
    word-break: break-word;
}

.TSettlementPage .SettleList .SettleBottom2 .Listitem .txtbox .date {
    font-weight: var(--Medium);
    font-size: 25px;
    color: var(--ColorTypeC);
}

.TSettlementPage .SettleList .SettleBottom2 .Listitem .txtbox .price {
    font-weight: var(--Medium);
    font-size: 25px;
    color: var(--ColorTypeB);
    margin-bottom: 0;
}

.TSettlementPage .SettleList .SettleBottom2 .Listitem .txtbox .price span {
    font-weight: var(--Semibold);
    font-size: 40px;
}

.TSettlementPage .SettleList .SettleBottom2 .Listitem .btnbox {
    width: 135px;
}

.TSettlementPage .SettleList .SettleBottom2 .Listitem .btnbox span {
    font-weight: var(--Medium);
    font-size: 27px;
    color: var(--ColorTypeB);
}

.TSettlementPage .SettleList .SettleBottom2 .Listitem .btnbox img.arrow {
    width: 15px;
    margin-left: 20px;
    align-self: center;
}

.TSettlementPage .SettleList .SettleBottom2 .Listitem.hold .btnbox span {
    color: #d80c07;
}

.TSettlementPage .SettleList .SettleBottom2 .Listitem.done .btnbox span {
    color: #293eb7;
}
*/

/* 모바일 반응형 */

/*
@media screen and (max-width: 599px) {  

    .TSettlementPage .PayCateBtn {
        font-size: 20px;
    }

    .TSettlementPage .SettleList .SettleTop1 {
        border-bottom: 15px solid var(--ColorTypeE);
    }


    .TSettlementPage .SettleList .SettleTop1 .Settle-sub {
        font-size: 20px;
    }

    .TSettlementPage .SettleList .SettleTop1 .Settle-date {
        font-size: 18px;
    }

    .TSettlementPage .SettleList .SettleTop1 .Settle-price {
        font-size: 20px;
    }

    .TSettlementPage .SettleList .SettleTop1 .Settle-price span {
        font-size: 35px;
        margin-right: 5px;
    }

    .TSettlementPage .SettleList .SettleTop1 .btn-default {
        height: 50px;
        font-size: 14px;
    }

    .TSettlementPage .SettleList .SettleBottom1 .Listitem img.logo {
        width: 50px;
    }

    .TSettlementPage .SettleList .SettleBottom1 .Listitem .txtbox {
        width: calc(100% - 70px);
    }

    .TSettlementPage .SettleList .SettleBottom1 .Listitem .time {
        font-size: 15px;
    }
    
    .TSettlementPage .SettleList .SettleBottom1 .Listitem .cancel-txt {
        font-size: 15px;
    }

    .TSettlementPage .SettleList .SettleBottom1 .Listitem .txtbox .name {
        font-size: 17px;
    }

    .TSettlementPage .SettleList .SettleBottom1 .Listitem .txtbox .t-num {
        font-size: 13px;
    }

    .TSettlementPage .SettleList .SettleBottom1 .Listitem .txtbox .price {
        font-size: 20px;
    }

    .TSettlementPage .SettleList .SettleBottom1 .Listitem .txtbox .price span.num {
        font-size: 25px;
    }

    .TSettlementPage .SettleList .SettleBottom1 .Listitem.cancel .txtbox .price>span::before {
        height: 2px;
    }

    .TSettlementPage .SettleList .SettleBottom1 .NoListitem {
        padding: 8vh 0;
    }
    
    .TSettlementPage .SettleList .SettleBottom1 .NoListitem  img.icon{
        width: 40px;
        margin-bottom: 1rem;
    }
    
    .TSettlementPage .SettleList .SettleBottom1 .NoListitem .desc {
        font-size: 20px;
    }

    .TSettlementPage .SettleList .SettleTop2 {
        border-bottom: 15px solid var(--ColorTypeE);
    }

    .TSettlementPage .SettleList .SettleTop2 img.smile {
        width: 45px;
    }

    .TSettlementPage .SettleList .SettleTop2 .PageTit {
        font-size: 18px;
    }

    .TSettlementPage .SettleList .SettleTop2 .accordion-button {
        font-size: 15px;
    }

    .TSettlementPage .Calendar {
        font-size: 13px;
    }

    .TSettlementPage .Calendar .Week-NextDate .active span::before {
        width: 35px;
        height: 35px;
    }

    .TSettlementPage .Calendar .Week-NextDate .active span::after {
        width: 25px;
        height: 25px;
    }

    .TSettlementPage .SettleList .SettleBottom2 .Listitem .txtbox {
        width: calc(100% - 100px);
    }

    .TSettlementPage .SettleList .SettleBottom2 .Listitem .txtbox .date {
        font-size: 17px;
    }

    .TSettlementPage .SettleList .SettleBottom2 .Listitem .txtbox .price {
        font-size: 18px;
    }

    .TSettlementPage .SettleList .SettleBottom2 .Listitem .txtbox .price span {
        font-size: 27px;
    }

    .TSettlementPage .SettleList .SettleBottom2 .Listitem .btnbox {
        width: 90px;
    }

    .TSettlementPage .SettleList .SettleBottom2 .Listitem .btnbox span {
        font-size: 18px;
    }

    .TSettlementPage .SettleList .SettleBottom2 .Listitem .btnbox img.arrow {
        width: 10px;
        margin-left: 10px;
    }


}

@media screen and (max-width: 320px) {

    .TSettlementPage .SettleList .SettleTop1 .btn-default {
        height: 40px;
    }

}

.TSettlementPage .PayCateBtn {
    line-height: 1.15;
}
.TSettlementPage .SettleList .SettleTop2 .accordion-button {
    line-height: 1.15;
}
.TSettlementPage .tab-content {
    padding-top: 0;
} */

/* 23-01-09 추가 */

.TPrdAdminPage {
  position: relative;
}

.TPrdAdminPage::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background: #f7f7f7;
  width: 100%;
  height: 100%;
  z-index: -1;
  min-height: 100vh;
}

.TPrdAdminPage .tab-content {
  padding-top: 0;
}

.TPrdAdminPage .PayCateTab .nav-pills {
  background-color: #fff;
  border-bottom: 2px solid var(--ColorTypeE);
  padding-top: 2rem;
  border-left: 1px solid var(--ColorTypeD);
  border-right: 1px solid var(--ColorTypeD);
}

.TPrdAdminPage .PayCateTab .nav-pills .nav-item {
  width: 33.333%;
}
.TPrdAdminPage .PayCateTab .nav-pills .nav-item2 {
  width: 25%;
}

.TPrdAdminPage .PayCateBtn {
  font-weight: var(--Medium);
  font-size: 25px;
  color: var(--ColorTypeB);
  border-radius: 0;
  --bs-nav-link-padding-x: 0.5rem;
  position: relative;
  line-height: 1.15;
  width: 100%;
  padding-bottom: 1.25rem;
  margin-bottom: -2px;
}

.TPrdAdminPage .PayCateBtn a:hover {
  color: inherit;
}

.TPrdAdminPage .nav-pills .PayCateBtn.active {
  color: var(--ColorTypeA);
  border-radius: 0;
  background-color: transparent;
  border-bottom: 2px solid var(--ColorTypeA);
  position: relative;
}

.TPrdAdminPage .PayCateBtn.active::before {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 6px;
  height: 6px;
  background: var(--ColorTypeA);
  border-radius: 50%;
}

/* 결제/사용현황 + 매출내역 */
.TPrdAdminPage .PrdAdmin-Top {
  justify-content: space-between;
  align-items: center;
}

.PrdAdmin-Top .btn-sort {
  border: 1px solid #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0 0.5rem;
  padding: 0.25rem 1rem;
  white-space: nowrap;
}

.TPrdAdminPage .PrdAdmin-Top .btn-sort img {
  max-width: 17px;
}

.TPrdAdminPage .PrdTbl-wrap {
  overflow-x: scroll;
}

.TPrdAdminPage .PrdTbl-down {
  display: flex;
  /* align-items: center; */
  /* justify-content: center; */
  width: 100%;
  gap: 0 0.2rem;
}
.TPrdAdminPage .PrdTbl-down-center {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 0 0.2rem;
}

.TPrdAdminPage .PrdTbl-down-center img {
  max-width: 26px;
}

.TPrdAdminPage .PrdTbl-wrap::-webkit-scrollbar {
  display: none;
}

.TPrdAdminPage .PrdTbl {
  min-width: 100%;
  width: max-content;
  text-align: center;
  vertical-align: middle;
  margin-right: 3rem;
}

.TPrdAdminPage .PrdTbl thead th {
  background-color: #000000;
  color: #fff;
  font-weight: var(--Regular);
  box-shadow: none;
  border-right-width: 2px;
  border-color: var(--ColorTypeC);
  padding: 0.5rem 1.25rem;
  font-size: 18px;
}

.TPrdAdminPage .PrdTbl thead th:last-child {
  border-right: 0;
}

.TPrdAdminPage .PrdTbl thead th.sort-th img {
  display: inline-block;
  vertical-align: middle;
  margin-left: 0.5rem;
  max-width: 12px;
  margin-bottom: 0.15rem;
}

.TPrdAdminPage .PrdTbl tbody td {
  background-color: #fff;
  border-color: var(--ColorTypeD);
  border-right-width: 2px;
  border-bottom-width: 2px;
  box-shadow: none;
  font-size: 16px;
  line-height: 1.2;
  padding: 0.4rem 0.25rem;
  min-width: 100px;
  word-break: break-word;
}

.TPrdAdminPage .PrdTbl tbody td:last-child {
  border-right: 0;
  /* border-color: var(--ColorTypeD);
    border-right-width: 2px; */
}

.TPrdAdminPage .PaymentList .PrdTbl tbody td[data-txt="결제 금액"],
.TPrdAdminPage .PaymentList .PrdTbl tbody td[data-txt="구매 식권"] {
  max-width: 250px;
}

.TPrdAdminPage .PaymentList .PrdTbl tbody td[data-txt="결제 일시"],
.TPrdAdminPage .PaymentList .PrdTbl tbody td[data-txt="픽업 완료 일시"],
.TPrdAdminPage .PaymentList .PrdTbl tbody td[data-txt="취소 일시"],
.TPrdAdminPage .PaymentList .PrdTbl tbody td[data-txt="사용 일시"] {
  width: 120px;
}

/* .TPrdAdminPage .PaymentList .PrdTbl tbody td[data-txt="결제 취소 상태"],
  .TPrdAdminPage .PaymentList .PrdTbl tbody td[data-txt="취소 일시"] {
    background-color: #f5f7fc;
  } */

/* .TPrdAdminPage .PaymentList .PrdTbl tbody td[data-txt="사용 상태"],
  .TPrdAdminPage .PaymentList .PrdTbl tbody td[data-txt="사용 일시"] {
    background-color: #fcf2f2;
  } */

.TPrdAdminPage .PaymentList .PrdTbl tbody td[data-txt="사용 상태"] .btn {
  height: auto;
  width: auto;
  font-size: 15px;
  line-height: 1;
  padding: 0.4rem 0.5rem;
  font-weight: var(--Regular);
}

.TPrdAdminPage .PaymentList .PrdTbl tbody td[data-txt="사용 상태"] .btn:focus {
  box-shadow: none;
}

.TPrdAdminPage .PaymentList .PrdTbl tbody td[data-txt="결제 취소 상태"] .btn {
  height: auto;
  width: auto;
  font-size: 15px;
  line-height: 1;
  padding: 0.4rem 0.5rem;
  font-weight: var(--Regular);
}

.TPrdAdminPage .PaymentList .PrdTbl tbody td[data-txt="취소"] {
  min-width: 100px;
}

.TPrdAdminPage .PaymentList .PrdTbl tbody td[data-txt="취소"] .btn {
  height: auto;
  width: auto;
  font-size: 15px;
  line-height: 1;
  padding: 0.4rem 0.5rem;
  font-weight: var(--Regular);
}

.TPrdAdminPage .PaymentList .PrdTbl tbody td[data-txt="취소"] .btn:focus {
  box-shadow: none;
}

.TPrdAdminPage .SalesList .PrdTbl {
  margin-right: 0;
  width: 100%;
}

.TPrdAdminPage .SalesList .PrdTbl tbody tr.total td {
  font-size: 18px;
  background-color: #dddddd;
  font-weight: var(--Medium);
}

.TPrdAdminPage .SalesList .PrdTbl tbody td {
  padding: 0.6rem 0.25rem;
  font-size: 15px;
}

.TPrdAdminPage .SalesList .PrdTbl tbody td[data-txt="메뉴"],
.TPrdAdminPage .SalesList .PrdTbl tbody td[data-txt="사용 현황"] {
  width: 130px;
}

.TPrdAdminPage .SalesList .PrdTbl tbody td[data-txt="결제 금액"] {
  text-align: right;
  padding-right: 1rem;
  word-break: break-all;
  max-width: 250px;
}

.TPrdAdminPage .modal-prdfilter .modal-header {
  position: relative;
  padding: 2rem 0 !important;
}

.TPrdAdminPage .modal-prdfilter .modal-header .modal-title {
  font-size: 22px;
  text-align: center;
  color: var(--ColorTypeC);
}

.TPrdAdminPage .modal-prdfilter .modal-header .btn {
  max-width: 20px;
  position: absolute;
  right: 3rem;
  top: 50%;
  transform: translateY(-50%);
}

.TPrdAdminPage .modal-prdfilter .modal-body {
  padding-top: 0;
}

.TPrdAdminPage .modal-prdfilter .modal-content {
  border: none;
  border-radius: 5px;
}

.TPrdAdminPage .modal-prdfilter .form-sort {
  margin-bottom: 1.25rem;
  text-align: center;
}

.TPrdAdminPage .modal-prdfilter .form-sort .form-radio-input {
  display: none;
}

.TPrdAdminPage
  .modal-prdfilter
  .form-sort
  .form-radio-input
  + .form-radio-label {
  font-size: 20px;
  font-weight: var(--Medium);
  color: var(--ColorTypeB);
}

.TPrdAdminPage
  .modal-prdfilter
  .form-sort
  .form-radio-input:checked
  + .form-radio-label {
  font-size: 24px;
  font-weight: var(--Semibold);
  color: var(--ColorTypeA);
}

.TPrdAdminPage .modal-sorting .sroting-tit {
  font-weight: var(--Medium);
  color: var(--ColorTypeB);
  font-size: 24px;
  margin-bottom: 0.75rem;
}

.TPrdAdminPage .modal-sorting .sorting-pills {
  gap: 0 0.75rem;
  margin-bottom: 2.5rem;
  width: 80%;
  flex-wrap: nowrap;
}

.TPrdAdminPage .modal-sorting .sorting-pills .nav-item {
  width: 33.333%;
}
.TPrdAdminPage .modal-sorting .sorting-pills .nav-item2 {
  width: 25%;
}

.TPrdAdminPage .modal-sorting .sorting-pills .nav-link {
  background-color: #fff;
  color: var(--ColorTypeB);
  border: 2px solid var(--ColorTypeB);
  padding: 0.75rem 1rem;
  width: 100%;
  border-radius: 5px;
  font-weight: var(--Medium);
  font-size: 18px;
  transition: all 0.4s ease;
}

.TPrdAdminPage .modal-sorting .sorting-pills.nav-link:focus,
.TPrdAdminPage .modal-sorting .sorting-pills.nav-link:hover {
  color: var(--ColorTypeB);
}

.TPrdAdminPage .modal-sorting .sorting-pills .nav-link.active {
  background-color: var(--ColorTypeB);
  color: #fff;
}

.TPrdAdminPage .modal-sorting .sorting-box {
  min-height: 25rem;
}

.TPrdAdminPage .modal-sorting .sorting-box .inputbox {
  align-items: center;
  gap: 0 0.75rem;
  font-size: 17px;
  color: var(--ColorTypeB);
  width: 80%;
}

.TPrdAdminPage .modal-sorting .sorting-box .inputbox .form-control {
  color: var(--ColorTypeB);
  border: 2px solid var(--ColorTypeB);
  padding: 0.75rem 1rem;
  border-radius: 5px;
  font-weight: var(--Medium);
  font-size: 18px;
  line-height: 1;
  height: auto;
  text-align: center;
  text-align-last: center;
  -ms-text-align-last: center;
  -moz-text-align-last: center;
}

.TPrdAdminPage .modal-sorting .sorting-box .inputbox.time .form-control {
  width: 130px;
}

.TPrdAdminPage .modal-sorting .sorting-box .inputbox.date .form-control {
  width: 160px;
}

.TPrdAdminPage .modal-sorting .sorting-box .inputbox2 {
  width: 80%;
  position: relative;
}

.TPrdAdminPage .modal-sorting .sorting-box .inputbox2 .form-control {
  color: var(--ColorTypeB);
  border: 2px solid var(--ColorTypeB);
  padding: 0.75rem 1rem;
  border-radius: 5px;
  font-weight: var(--Semibold);
  font-size: 18px;
  line-height: 1;
  height: auto;
  text-align: center;
  text-align-last: center;
  text-align: center;
  -ms-text-align-last: center;
  -moz-text-align-last: center;
}

.TPrdAdminPage .modal-sorting .sorting-box .inputbox2 .yearTarget + .icon {
  position: absolute;
  right: 1.5rem;
  top: 53%;
  transform: translateY(-50%);
  max-width: 18px;
  transition: all 0.4s ease;
}

.TPrdAdminPage
  .modal-sorting
  .sorting-box
  .inputbox2
  .yearTarget.active
  + .icon {
  top: 51%;
  transform: translateY(-50%) rotate(-180deg);
}

.TPrdAdminPage .inputbox2-list {
  width: 80%;
  color: var(--ColorTypeB);
  border: 2px solid var(--ColorTypeB);
  padding: 1.5rem 1rem;
  border-radius: 5px;
  font-weight: var(--Medium);
  font-size: 18px;
  line-height: 1;
  height: 18rem;
  text-align: center;
  margin-top: 1.25rem;
  background-color: #fff;
  opacity: 0;
  pointer-events: none;
  transition: all 0.4s ease;
}

.TPrdAdminPage .inputbox2-list.active {
  opacity: 1;
  pointer-events: initial;
}

.TPrdAdminPage .inputbox2-list .scroll-wrap {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.TPrdAdminPage .inputbox2-list .scroll-wrap::-webkit-scrollbar {
  width: 3px;
}

.TPrdAdminPage .inputbox2-list .scroll-wrap::-webkit-scrollbar-track {
  background: #999999;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  background-clip: padding-box;
}

.TPrdAdminPage .inputbox2-list .scroll-wrap::-webkit-scrollbar-thumb {
  -webkit-border-radius: 4px;
  border-radius: 4px;
  background: var(--ColorTypeC);
}

.TPrdAdminPage .inputbox2-list .radioBox {
  margin-bottom: 1.1rem;
}

.TPrdAdminPage .inputbox2-list .radioBox:last-child {
  margin-bottom: 0;
}

.TPrdAdminPage .inputbox2-list .yearItem {
  display: none;
}

.TPrdAdminPage .inputbox2-list .yearItem:checked + .RadioLabel {
  color: var(--ColorTypeA);
}

.TPrdAdminPage .sorting-confirm .btn-default {
  height: 70px;
  font-size: 22px;
}

/* 정산내역 */
.TPrdAdminPage .SettleList .SettleTop2 {
  border-bottom: 5px solid var(--ColorTypeE);
  margin-bottom: 0.75rem;
}

.TPrdAdminPage .SettleList .SettleTop2 .PageTit .color {
  color: var(--ColorTypeA);
}

.TPrdAdminPage .SettleList .SettleTop2 .accordion {
  --bs-accordion-btn-icon: url(https://www.flexdaycdn.net/public/images/static/login/drop_check2.png);
  --bs-accordion-btn-active-icon: url(https://www.flexdaycdn.net/public/images/static/login/drop_check2.png);
  --bs-accordion-btn-icon-width: 1rem;
  --bs-accordion-btn-icon-transform: rotate(180deg);
  --bs-accordion-btn-icon-transform2: rotate(180deg);
}

.TPrdAdminPage .SettleList .SettleTop2 .accordion-button {
  color: var(--ColorTypeC);
  font-weight: var(--Medium);
  font-size: 18px;
  line-height: 1.15;
}

.TPrdAdminPage .SettleList .SettleTop2 .accordion-button::after {
  background-position: center;
  margin-left: 8px;
}

.TPrdAdminPage .Calendar {
  border: 1px solid var(--ColorTypeD);
  border-radius: 10px;
  font-weight: var(--Medium);
  font-size: 18px;
  color: var(--ColorTypeB);
  background-color: #fff;
}

.TPrdAdminPage .Calendar .ColorA {
  color: var(--ColorTypeA);
}

.TPrdAdminPage .Calendar .ColorF {
  color: #293eb7;
}

.TPrdAdminPage .Calendar .Week-NowDate {
  background-color: rgb(237 21 86 / 10%);
  border-radius: 50px;
}

.TPrdAdminPage .Calendar .Week-NextDate .active span {
  position: relative;
  color: #fff;
  z-index: 0;
}

.TPrdAdminPage .Calendar .Week-NextDate .active span::before {
  content: "";
  background-color: rgb(237 21 86 / 10%);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}

.TPrdAdminPage .Calendar .Week-NextDate .active span::after {
  content: "";
  background-color: var(--ColorTypeA);
  border-radius: 50%;
  width: 35px;
  height: 35px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: -2;
}

.TPrdAdminPage .SettleList .SettleBottom2 .Listbox {
  border-bottom: 2px solid var(--ColorTypeE);
}

.TPrdAdminPage .SettleList .SettleBottom2 .Listbox:last-child {
  border-bottom: 0;
}

.TPrdAdminPage .SettleList .SettleBottom2 .Listbox:last-child::after {
  content: "";
  display: block;
  height: 3rem;
  background-color: #fff;
  border-top: 2px solid var(--ColorTypeE);
}

.TPrdAdminPage .SettleList .SettleBottom2 .Listyear {
  text-align: center;
  padding: 0.25rem 0;
  font-weight: var(--Medium);
  font-size: 20px;
  color: #000000;
}

.TPrdAdminPage .SettleList .SettleBottom2 .Listitem {
  border-bottom: 1px solid var(--ColorTypeE);
  background-color: #fff;
}

.TPrdAdminPage .SettleList .SettleBottom2 .Listitem:last-child {
  border-bottom: 0px;
}

.TPrdAdminPage .SettleList .SettleBottom2 .Listitem .txtbox {
  width: calc(100% - 145px);
  word-break: break-word;
}

.TPrdAdminPage .SettleList .SettleBottom2 .Listitem .txtbox .date {
  font-weight: var(--Medium);
  font-size: 20px;
  color: var(--ColorTypeC);
}

.TPrdAdminPage .SettleList .SettleBottom2 .Listitem .txtbox .price {
  font-weight: var(--Medium);
  font-size: 22px;
  color: var(--ColorTypeB);
  margin-bottom: 0;
}

.TPrdAdminPage .SettleList .SettleBottom2 .Listitem .txtbox .price span {
  font-weight: var(--Semibold);
  font-size: 35px;
}

.TPrdAdminPage .SettleList .SettleBottom2 .Listitem .btnbox span {
  font-weight: var(--Medium);
  font-size: 22px;
  color: var(--ColorTypeB);
}

.TPrdAdminPage .SettleList .SettleBottom2 .Listitem .btnbox .arrow {
  width: 10px;
  height: 25px;
  margin-left: 20px;
  background: url(https://www.flexdaycdn.net/public/images/ticket/more_info.png)
    no-repeat center/100%;
}

.TPrdAdminPage .SettleList .SettleBottom2 .Listitem.done .btnbox .arrow {
  background-image: url(https://www.flexdaycdn.net/public/images/ticket/more_info2.png);
}

.TPrdAdminPage .SettleList .SettleBottom2 .Listitem.hold .btnbox span {
  color: #d80c07;
}

.TPrdAdminPage .SettleList .SettleBottom2 .Listitem.done .btnbox span {
  color: #293eb7;
}

/* 모바일 반응형 */

@media screen and (max-width: 1024px) {
  .TPrdAdminPage::before {
    border-left: 0px;
    border-right: 0px;
  }

  .TPrdAdminPage .PayCateTab .nav-pills {
    border-left: 0px;
    border-right: 0px;
  }
}

@media screen and (max-width: 599px) {
  .demo .TPrdAdminPage .PayCateTab .nav-pills.px-4-5 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .TPrdAdminPage .PayCateBtn {
    font-size: 17px;
    padding-bottom: 1rem;
  }

  /* 결제/사용현황 + 매출내역 */
  .TPrdAdminPage .PrdAdmin-Top {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
    gap: 0 1rem;
  }

  .TPrdAdminPage .PrdAdmin-Top .PageTit {
    font-size: 18px;
  }

  .TPrdAdminPage .PrdAdmin-Bottom.ps-4-5 {
    padding-left: 1.5rem !important;
  }

  .TPrdAdminPage .PrdTbl {
    margin-right: 1.5rem;
  }

  .TPrdAdminPage .PrdTbl thead th {
    font-size: 16px;
  }

  .TPrdAdminPage .PrdTbl tbody td {
    font-size: 14px;
  }

  .TPrdAdminPage .PaymentList .PrdTbl tbody td[data-txt="결제 금액"],
  .TPrdAdminPage .PaymentList .PrdTbl tbody td[data-txt="구매 식권"] {
    max-width: 200px;
  }

  .TPrdAdminPage .modal-prdfilter .modal-header .modal-title {
    font-size: 18px;
  }

  .TPrdAdminPage .modal-prdfilter .modal-header {
    padding: 1.25rem 0 !important;
  }

  .TPrdAdminPage .modal-prdfilter .modal-header .btn {
    max-width: 16px;
    right: 2rem;
  }

  .TPrdAdminPage .modal-prdfilter .form-sort {
    margin-bottom: 1rem;
  }

  .TPrdAdminPage
    .modal-prdfilter
    .form-sort
    .form-radio-input:checked
    + .form-radio-label {
    font-size: 20px;
  }

  .TPrdAdminPage
    .modal-prdfilter
    .form-sort
    .form-radio-input
    + .form-radio-label {
    font-size: 16px;
  }

  .TPrdAdminPage .SalesList .PrdAdmin-Bottom.px-4-5 {
    padding-right: 0 !important;
    padding-left: 1.5rem !important;
  }

  .TPrdAdminPage .SalesList .PrdTbl {
    width: max-content;
    margin-right: 1.5rem;
  }

  .TPrdAdminPage .SalesList .PrdTbl tbody tr.total td {
    font-size: 16px;
  }

  .TPrdAdminPage .SalesList .PrdTbl tbody td[data-txt="결제 금액"] {
    min-width: 200px;
    max-width: 250px;
  }

  .demo .TPrdAdminPage .modal-sorting .modal-body {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .TPrdAdminPage .modal-sorting .sroting-tit {
    font-size: 20px;
  }

  .TPrdAdminPage .modal-sorting .sorting-pills {
    margin-bottom: 1.5rem;
    width: 100%;
  }

  .TPrdAdminPage .modal-sorting .sorting-pills .nav-link {
    padding: 0.5rem;
    font-size: 16px;
  }

  .TPrdAdminPage .modal-sorting .sorting-box {
    min-height: 20rem;
  }

  .TPrdAdminPage .modal-sorting .sorting-box .inputbox {
    width: 100%;
  }

  .TPrdAdminPage .modal-sorting .sorting-box .inputbox .form-control {
    font-size: 16px;
  }

  .TPrdAdminPage .modal-sorting .sorting-box .inputbox.time .form-control {
    width: 100%;
  }

  .TPrdAdminPage .modal-sorting .sorting-box .inputbox.date .form-control {
    width: 100%;
  }

  .TPrdAdminPage .modal-sorting .sorting-box .inputbox2 {
    width: 100%;
  }

  .TPrdAdminPage .inputbox2-list {
    width: 100%;
  }

  .TPrdAdminPage .sorting-confirm .btn-default {
    height: 55px;
    font-size: 16px;
  }

  /* 정산내역 */
  .demo .TPrdAdminPage .SettleList .SettleTop2.px-4-5 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .TPrdAdminPage .SettleList .SettleTop2 {
    margin-bottom: 0.5rem;
  }

  .TPrdAdminPage .SettleList .SettleTop2 .PageTit {
    font-size: 18px;
  }

  .TPrdAdminPage .SettleList .SettleTop2 .accordion-button {
    font-size: 15px;
  }

  .TPrdAdminPage .Calendar {
    font-size: 13px;
  }

  .TPrdAdminPage .Calendar .Week-NextDate .active span::before {
    width: 35px;
    height: 35px;
  }

  .TPrdAdminPage .Calendar .Week-NextDate .active span::after {
    width: 25px;
    height: 25px;
  }

  .TPrdAdminPage .SettleList .SettleBottom2 .Listyear {
    font-size: 16px;
  }

  .demo .TPrdAdminPage .SettleList .SettleBottom2 .Listitem.px-4-5 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .TPrdAdminPage .SettleList .SettleBottom2 .Listitem .txtbox {
    width: calc(100% - 100px);
  }

  .TPrdAdminPage .SettleList .SettleBottom2 .Listitem .txtbox .date {
    font-size: 17px;
  }

  .TPrdAdminPage .SettleList .SettleBottom2 .Listitem .txtbox .price {
    font-size: 18px;
  }

  .TPrdAdminPage .SettleList .SettleBottom2 .Listitem .txtbox .price span {
    font-size: 27px;
  }

  .TPrdAdminPage .SettleList .SettleBottom2 .Listitem .btnbox span {
    font-size: 18px;
  }

  .TPrdAdminPage .SettleList .SettleBottom2 .Listitem .btnbox .arrow {
    width: 8px;
    height: 16px;
    margin-left: 10px;
  }
}

@media screen and (max-width: 380px) {
  .demo .TPrdAdminPage .PayCateTab .nav-pills.px-4-5 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .TPrdAdminPage .PayCateBtn {
    padding: 0 0 1rem;
  }

  .TPrdAdminPage .PayCateBtn.active::before {
    top: -8px;
  }

  .TPrdAdminPage .modal-sorting .sorting-box .inputbox.date .form-control {
    padding: 0.75rem 0;
  }
}

@media screen and (max-width: 320px) {
  .TPrdAdminPage .modal-sorting .sorting-box {
    min-height: 15rem;
  }
}

/* Datepicker */

.DatepickerBox.ver1.time .form-control.InputType.input-datepicker.prd-admin {
  width: 130px;
}

.DatepickerBox.ver2.h-ver2 .react-datepicker-popper[data-placement^="bottom"] {
  padding-top: 1.25rem;
}

.DatepickerBox.ver2.h-ver2 .react-datepicker__month {
  max-height: 13rem;
}

.DatepickerBox.ver2
  .react-datepicker__month-text--keyboard-selected.react-datepicker__month--disabled {
  font-weight: normal;
  color: #ccc;
}

@media screen and (max-width: 599px) {
  .DatepickerBox.ver2.h-ver2 .react-datepicker__month {
    max-height: 10rem;
  }

  .DatepickerBox.ver2.h-ver2
    .react-datepicker__month
    .react-datepicker__month-text {
    padding-bottom: 0;
  }
}

@media screen and (max-width: 320px) {
  .DatepickerBox.ver1.time .form-control.InputType.input-datepicker.prd-admin {
    width: 100%;
  }

  .DatepickerBox.ver2.h-ver2 .react-datepicker__month {
    max-height: 6rem;
  }
}

/* 23-02-03 */

@media screen and (min-width: 1280px) {
  .TPrdAdminPage .PrdTbl {
    min-width: auto;
    width: calc(100% - 3rem);
    margin-right: 0;
  }
}

.PaymentSearchContainer {
  margin-right: 1.5rem;
  padding-left: 1.5rem;
  padding-bottom: 3rem;
  text-align: center;
  vertical-align: middle;
}

.PaymentSearchContainer .PaymentSearch {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 0.25rem 0.25rem 1.2rem;
  background-color: #fff;
}

.PaymentSearchContainer .PaymentSearch .search-icon {
  display: flex;
  align-items: center;
  height: 32px;
}

.PaymentSearchContainer .PaymentSearch .search-bar {
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 10px;
  border: none;
  color: var(--ColorTypeB);
  padding: 0.55rem 0.95rem 0.55rem 1.2rem;
  height: 57.85px;
  font-size: 14px;
}

.PaymentSearchContainer .PaymentSearch .search-btn {
  background-color: var(--ColorTypeB);
  color: #fff;
  padding: 0.8rem 2rem;
  font-size: 14px;
  white-space: nowrap;
}

@media screen and (min-width: 599px) {
  .PaymentSearchContainer {
    margin-right: 0;
    padding: 0 3rem 3rem 3rem;
    text-align: center;
    vertical-align: middle;
  }

  .PaymentSearchContainer .PaymentSearch .search-bar {
    font-size: 18px;
  }

  .PaymentSearchContainer .PaymentSearch .search-btn {
    font-size: 18px;
  }
}

.TPrdAdminPage .PageTit {
  font-size: 22px;
}

.TPrdAdminPage .PrdAdmin-Schbox .inner {
  display: flex;
  border: 1px solid var(--ColorTypeB);
  padding: 0.3rem;
  padding-left: 0;
  border-radius: 3px;
  background-color: #fff;
}

.TPrdAdminPage .PrdAdmin-Schbox .selectSch {
  font-size: 20px;
  width: 120px;
  text-align: left;
  padding: 0 1.5rem;
  position: relative;
}

.TPrdAdminPage .PrdAdmin-Schbox .selectSch::after {
  content: "";
  background: url(https://www.flexdaycdn.net/public/images/ticket_ver2/accordion_arrow.png)
    no-repeat center right / 1rem;
  width: 1rem;
  height: 1rem;
  transition: all 0.2s ease-in-out;
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
}

.TPrdAdminPage .PrdAdmin-Schbox .sch-menu {
  border-radius: 0;
  border: 0;
  width: 120px;
  min-width: auto;
  padding: 0;
}

.TPrdAdminPage .PrdAdmin-Schbox .sch-menu li > input {
  display: none;
}

.TPrdAdminPage .PrdAdmin-Schbox .sch-menu li > label {
  padding: 0.5rem 1.5rem;
  font-size: 20px;
  font-weight: var(--Regular);
  color: var(--ColorTypeB);
  position: relative;
  width: 100%;
  cursor: pointer;
}

.TPrdAdminPage .PrdAdmin-Schbox .sch-menu li > label::before {
  content: "";
  position: absolute;
  left: -1px;
  top: 0;
  background-color: transparent;
  width: 4px;
  height: 100%;
}

.TPrdAdminPage .PrdAdmin-Schbox .sch-menu li > input:checked + label {
  color: var(--ColorTypeA);
}

.TPrdAdminPage .PrdAdmin-Schbox .sch-menu li > input:checked + label::before {
  background-color: var(--ColorTypeA);
}

.TPrdAdminPage .PrdAdmin-Schbox .sch-input {
  max-width: calc(100% - 90px - 120px);
  padding-left: 0;
  border: 0;
  font-size: 17px;
  color: var(--ColorTypeB);
  box-shadow: none;
}

.TPrdAdminPage .PrdAdmin-Schbox .sch-btn {
  width: 100%;
  max-width: 90px;
  height: auto;
  font-size: 17px;
}

.TPrdAdminPage .PaymentList .PrdTbl tbody td[data-txt="취소하기"],
.TPrdAdminPage .PaymentList .PrdTbl tbody td[data-txt="픽업 완료"],
.TPrdAdminPage .PaymentList .PrdTbl tbody td[data-txt="사용하기"] {
  min-width: 120px;
}

.TPrdAdminPage .PaymentList .PrdTbl tbody td[data-txt="취소하기"] .btn,
.TPrdAdminPage .PaymentList .PrdTbl tbody td[data-txt="픽업 완료"] .btn,
.TPrdAdminPage .PaymentList .PrdTbl tbody td[data-txt="사용하기"] .btn {
  height: auto;
  width: 85%;
  font-size: 15px;
  line-height: 1;
  padding: 0.5rem 0.5rem;
  margin: 0.5rem 0;
  font-weight: var(--Regular);
}

.TPrdAdminPage
  .PaymentList
  .PrdTbl
  tbody
  td[data-txt="취소하기"]
  .btn.btn-light,
.TPrdAdminPage
  .PaymentList
  .PrdTbl
  tbody
  td[data-txt="픽업 완료"]
  .btn.btn-light,
.TPrdAdminPage
  .PaymentList
  .PrdTbl
  tbody
  td[data-txt="사용하기"]
  .btn.btn-light {
  border-color: var(--ColorTypeB);
}

.TPrdAdminPage .PaymentList .PrdTbl tbody td[data-txt="취소하기"] .btn:focus,
.TPrdAdminPage .PaymentList .PrdTbl tbody td[data-txt="픽업 완료"] .btn:focus,
.TPrdAdminPage .PaymentList .PrdTbl tbody td[data-txt="사용하기"] .btn:focus {
  box-shadow: none;
}

.TPrdAdminPage .PaymentList .PrdTbl tbody td.bgA {
  background-color: #fcf2f2;
}

.TPrdAdminPage .PaymentList .PrdTbl tbody td.bgB {
  background-color: #f5f7fc;
}

.TPrdAdminPage .modal-sorting .sorting-pills {
  width: 100%;
}

.TPrdAdminPage .modal-sorting .sorting-pills .nav-link {
  border-radius: 3px;
}

.TPrdAdminPage .modal-sorting .sorting-box .inputbox {
  width: 100%;
}

.TPrdAdminPage .modal-sorting .sorting-box .inputbox .form-control {
  padding: 1rem;
  border-radius: 3px;
}

.TPrdAdminPage .modal-sorting .sorting-box .inputbox.time .form-control {
  width: 100%;
}

.TPrdAdminPage .modal-sorting .sorting-box .inputbox2 {
  width: 100%;
}

.TPrdAdminPage .modal-sorting .sorting-box .inputbox2 .form-control.active {
  border-radius: 3px;
  border-bottom: 0px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  padding-bottom: 0;
}

.TPrdAdminPage
  .modal-sorting
  .sorting-box
  .inputbox2
  .yearTarget.active
  + .icon {
  top: 60%;
  right: 1.25rem;
}

.TPrdAdminPage .inputbox2-list {
  width: 100%;
  margin-top: 0;
  border-top: 0;
  border-radius: 3px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  padding: 1.5rem;
}

.TPrdAdminPage .SettleList .SettleBottom2 .Listitem.done .btnbox span {
  color: #4d56ff;
}

.TPrdAdminPage .SettleList .SettleBottom2 .Listitem.done .btnbox .arrow {
  background-image: url(https://www.flexdaycdn.net/public/images/ticket_ver2/more_info2.png);
}

@media screen and (max-width: 599px) {
  .TPrdAdminPage .PrdAdmin-Schbox .inner {
    flex-wrap: wrap;
    padding: 0.5rem;
    padding-left: 0;
  }

  .TPrdAdminPage .PrdAdmin-Schbox .selectSch {
    font-size: 16px;
    width: 100px;
    padding: 0 1.25rem;
  }

  .TPrdAdminPage .PrdAdmin-Schbox .sch-menu {
    width: 100px;
  }

  .TPrdAdminPage .PrdAdmin-Schbox .sch-menu li > label {
    padding: 0.5rem 1.25rem;
    font-size: 16px;
  }

  .TPrdAdminPage .PrdAdmin-Schbox .sch-menu li > label::before {
    width: 3px;
  }

  .TPrdAdminPage .PrdAdmin-Schbox .sch-input {
    max-width: calc(100% - 115px);
    padding: 0.5rem;
    padding-left: 0;
    flex: auto;
    font-size: 15px;
    text-overflow: ellipsis;
  }

  .TPrdAdminPage .PrdAdmin-Schbox .sch-btn {
    max-width: calc(100% - 0.5rem);
    font-size: 15px;
    margin-left: 0.5rem;
    margin-top: 0.5rem;
  }

  .TPrdAdminPage .modal-sorting .sorting-pills .nav-link {
    border: 1px solid var(--ColorTypeB);
  }

  .TPrdAdminPage .modal-sorting .sorting-box .inputbox .form-control {
    border: 1px solid var(--ColorTypeB);
  }

  .TPrdAdminPage .modal-sorting .sorting-box .inputbox2 .form-control {
    border-width: 1px;
  }

  .TPrdAdminPage .inputbox2-list {
    border-width: 1px;
  }
}

/* ver2 */

.TPrdAdminPage .PageTit {
  font-size: 22px;
}

.TPrdAdminPage .PrdAdmin-Schbox .inner {
  display: flex;
  border: 1px solid var(--ColorTypeB);
  padding: 0.3rem;
  padding-left: 0;
  border-radius: 3px;
  background-color: #fff;
}

.TPrdAdminPage .PrdAdmin-Schbox .selectSch {
  font-size: 20px;
  width: 120px;
  text-align: left;
  padding: 0 1.5rem;
  position: relative;
}

.TPrdAdminPage .PrdAdmin-Schbox .selectSch::after {
  content: "";
  background: url(https://www.flexdaycdn.net/public/images/ticket_ver2/accordion_arrow.png)
    no-repeat center right / 1rem;
  width: 1rem;
  height: 1rem;
  transition: all 0.2s ease-in-out;
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
}

.TPrdAdminPage .PrdAdmin-Schbox .sch-menu {
  border-radius: 0;
  border: 0;
  width: 120px;
  min-width: auto;
  padding: 0;
}

.TPrdAdminPage .PrdAdmin-Schbox .sch-menu li > input {
  display: none;
}

.TPrdAdminPage .PrdAdmin-Schbox .sch-menu li > label {
  padding: 0.5rem 1.5rem;
  font-size: 20px;
  font-weight: var(--Regular);
  color: var(--ColorTypeB);
  position: relative;
  width: 100%;
  cursor: pointer;
}

.TPrdAdminPage .PrdAdmin-Schbox .sch-menu li > label::before {
  content: "";
  position: absolute;
  left: -1px;
  top: 0;
  background-color: transparent;
  width: 4px;
  height: 100%;
}

.TPrdAdminPage .PrdAdmin-Schbox .sch-menu li > input:checked + label {
  color: var(--ColorTypeA);
}

.TPrdAdminPage .PrdAdmin-Schbox .sch-menu li > input:checked + label::before {
  background-color: var(--ColorTypeA);
}

.TPrdAdminPage .PrdAdmin-Schbox .sch-input {
  max-width: calc(100% - 90px - 120px);
  padding-left: 0;
  border: 0;
  font-size: 17px;
  color: var(--ColorTypeB);
  box-shadow: none;
}

.TPrdAdminPage .PrdAdmin-Schbox .sch-btn {
  width: 100%;
  max-width: 90px;
  height: auto;
  font-size: 17px;
}

.TPrdAdminPage .PaymentList .PrdTbl tbody td[data-txt="취소하기"],
.TPrdAdminPage .PaymentList .PrdTbl tbody td[data-txt="픽업 완료"],
.TPrdAdminPage .PaymentList .PrdTbl tbody td[data-txt="사용하기"] {
  min-width: 120px;
}

.TPrdAdminPage .PaymentList .PrdTbl tbody td[data-txt="취소하기"] .btn,
.TPrdAdminPage .PaymentList .PrdTbl tbody td[data-txt="픽업 완료"] .btn,
.TPrdAdminPage .PaymentList .PrdTbl tbody td[data-txt="사용하기"] .btn {
  height: auto;
  width: 85%;
  font-size: 15px;
  line-height: 1;
  padding: 0.5rem 0.5rem;
  margin: 0.5rem 0;
  font-weight: var(--Regular);
}

.TPrdAdminPage
  .PaymentList
  .PrdTbl
  tbody
  td[data-txt="취소하기"]
  .btn.btn-light,
.TPrdAdminPage
  .PaymentList
  .PrdTbl
  tbody
  td[data-txt="픽업 완료"]
  .btn.btn-light,
.TPrdAdminPage
  .PaymentList
  .PrdTbl
  tbody
  td[data-txt="사용하기"]
  .btn.btn-light {
  border-color: var(--ColorTypeB);
}

.TPrdAdminPage .PaymentList .PrdTbl tbody td[data-txt="취소하기"] .btn:focus,
.TPrdAdminPage .PaymentList .PrdTbl tbody td[data-txt="픽업 완료"] .btn:focus,
.TPrdAdminPage .PaymentList .PrdTbl tbody td[data-txt="사용하기"] .btn:focus {
  box-shadow: none;
}

.TPrdAdminPage .PaymentList .PrdTbl tbody td.bgA {
  background-color: #fcf2f2;
}

.TPrdAdminPage .PaymentList .PrdTbl tbody td.bgB {
  background-color: #f5f7fc;
}

.TPrdAdminPage .modal-sorting .sorting-pills {
  width: 100%;
}

.TPrdAdminPage .modal-sorting .sorting-pills .nav-link {
  border-radius: 3px;
}

.TPrdAdminPage .modal-sorting .sorting-box .inputbox {
  width: 100%;
}

.TPrdAdminPage .modal-sorting .sorting-box .inputbox .form-control {
  padding: 1rem;
  border-radius: 3px;
}

.TPrdAdminPage .modal-sorting .sorting-box .inputbox.time .form-control {
  width: 100%;
}

.TPrdAdminPage .modal-sorting .sorting-box .inputbox2 {
  width: 100%;
}

.TPrdAdminPage .modal-sorting .sorting-box .inputbox2 .form-control.active {
  border-radius: 3px;
  border-bottom: 0px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  padding-bottom: 0;
}

.TPrdAdminPage
  .modal-sorting
  .sorting-box
  .inputbox2
  .yearTarget.active
  + .icon {
  top: 60%;
  right: 1.25rem;
}

.TPrdAdminPage .inputbox2-list {
  width: 100%;
  margin-top: 0;
  border-top: 0;
  border-radius: 3px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  padding: 1.5rem;
}

.TPrdAdminPage .SettleList .SettleBottom2 .Listitem.done .btnbox span {
  color: #4d56ff;
}

.TPrdAdminPage .SettleList .SettleBottom2 .Listitem.done .btnbox .arrow {
  background-image: url(https://www.flexdaycdn.net/public/images/ticket_ver2/more_info2.png);
}

.TPrdAdminPage .modal-sorting .DatepickerBox.ver1 > .d-flex {
  gap: 0 0.75rem;
}

.TPrdAdminPage
  .modal-sorting
  .DatepickerBox.ver1
  .form-control.InputType.input-datepicker.sale-prd-add {
  padding: 1rem;
  max-width: 100%;
}

.TPrdAdminPage
  .modal-sorting
  .DatepickerBox.ver1
  .form-control.InputType.input-datepicker.prd-admin {
  background-size: 20px;
  background-position-x: 1.25rem;
  padding: 1rem;
  padding-left: calc(1.25rem + 20px);
}

.TPrdAdminPage
  .modal-sorting
  .DatepickerBox.ver2
  .form-control.InputType.input-datepicker.prd-admin2 {
  padding: 1rem;
}

.TPrdAdminPage .modal-sorting .DatepickerBox.year .react-datepicker__tab-loop {
  position: relative;
  left: auto;
  top: auto;
}

.TPrdAdminPage .modal-sorting .DatepickerBox.year .react-datepicker-popper {
  position: relative !important;
  transform: none !important;
}

@media screen and (max-width: 599px) {
  .TPrdAdminPage .PrdAdmin-Schbox .inner {
    flex-wrap: wrap;
    padding: 0.5rem;
    padding-left: 0;
  }

  .TPrdAdminPage .PrdAdmin-Schbox .selectSch {
    font-size: 16px;
    width: 100px;
    padding: 0 1.25rem;
  }

  .TPrdAdminPage .PrdAdmin-Schbox .sch-menu {
    width: 100px;
  }

  .TPrdAdminPage .PrdAdmin-Schbox .sch-menu li > label {
    padding: 0.5rem 1.25rem;
    font-size: 16px;
  }

  .TPrdAdminPage .PrdAdmin-Schbox .sch-menu li > label::before {
    width: 3px;
  }

  .TPrdAdminPage .PrdAdmin-Schbox .sch-input {
    max-width: calc(100% - 115px);
    padding: 0.5rem;
    padding-left: 0;
    flex: auto;
    font-size: 15px;
    text-overflow: ellipsis;
  }

  .TPrdAdminPage .PrdAdmin-Schbox .sch-btn {
    max-width: calc(100% - 0.5rem);
    font-size: 15px;
    margin-left: 0.5rem;
    margin-top: 0.5rem;
  }

  .TPrdAdminPage .modal-sorting .sorting-pills .nav-link {
    border: 1px solid var(--ColorTypeB);
  }

  .TPrdAdminPage .modal-sorting .sorting-box .inputbox .form-control {
    border: 1px solid var(--ColorTypeB);
  }

  .TPrdAdminPage .modal-sorting .sorting-box .inputbox2 .form-control {
    border-width: 1px;
  }

  .TPrdAdminPage .inputbox2-list {
    border-width: 1px;
  }

  .TPrdAdminPage
    .modal-sorting
    .DatepickerBox.ver1
    .form-control.InputType.input-datepicker.sale-prd-add {
    font-size: 16px;
  }

  .TPrdAdminPage
    .modal-sorting
    .DatepickerBox.ver1
    .form-control.InputType.input-datepicker.prd-admin {
    background-size: 20px;
    background-position-x: 1.25rem;
    padding: 1rem;
  }

  .TPrdAdminPage
    .modal-sorting
    .DatepickerBox.ver2
    .form-control.InputType.input-datepicker.prd-admin2 {
    padding: 0.75rem 1rem;
    border-width: 1px;
  }

  .TPrdAdminPage .modal-sorting .DatepickerBox.ver2 .react-datepicker {
    border-width: 1px;
  }
}

/* 23.05.11 */

.TPrdAdminPage .modal {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.TPrdAdminPage .modal::-webkit-scrollbar {
  display: none;
}

.TPrdAdminPage .modal .demo::before {
  display: none;
}

/* ver3 */

.TPrdAdminPage .PayCateTab .nav-pills {
  white-space: nowrap;
  overflow: auto hidden;
  flex-wrap: nowrap;
  gap: 0 2rem;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding-bottom: 1px;
}

.TPrdAdminPage .PayCateTab .nav-pills::-webkit-scrollbar {
  display: none;
}

.TPrdAdminPage .PrdTbl {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  overflow: hidden;
}

.TPrdAdminPage .PrdTbl thead th {
  vertical-align: middle;
  white-space: nowrap;
}

.TPrdAdminPage
  .PaymentList
  .PrdTbl
  tbody
  td[data-txt="픽업 완료"]
  .btn.btn-light {
  border-color: var(--ColorTypeB);
}

.TPrdAdminPage .PaymentList .PrdTbl tbody td[data-txt="픽업 완료"] .btn {
  height: auto;
  width: 85%;
  font-size: 15px;
  line-height: 1;
  padding: 0.5rem 0.5rem;
  margin: 0.5rem 0;
  font-weight: var(--Regular);
}

.TPrdAdminPage .PaymentList .PrdTbl tbody td[data-txt="픽업 완료일시"] {
  width: 140px;
}

.TPrdAdminPage .SalesList .PrdTbl tbody tr.total td {
  background-color: #f5f7fc;
}

.TPrdAdminPage .Taxbill .PrdTbl thead tr th {
  padding: 0.75rem 1.25rem;
}

.TPrdAdminPage .Taxbill .PrdTbl tbody tr td {
  padding: 0.75rem 0.25rem;
}

.TPrdAdminPage .Taxbill .PrdTbl tbody tr td[data-txt="No"] {
  min-width: initial;
}

.TPrdAdminPage .Taxbill .PrdTbl tbody tr td[data-txt="발행 일자"] {
  min-width: 125px;
}

.TPrdAdminPage .Taxbill .PrdTbl tbody tr td[data-txt="발행 기준일자"] {
  min-width: 220px;
}

.TPrdAdminPage .Taxbill .PrdTbl tbody tr td[data-txt="공급가액"],
.TPrdAdminPage .Taxbill .PrdTbl tbody tr td[data-txt="부가세액"],
.TPrdAdminPage .Taxbill .PrdTbl tbody tr td[data-txt="합계 금액"] {
  min-width: 175px;
  text-align: right;
  padding-right: 1rem;
}

.TPrdAdminPage .Taxbill .PrdTbl tbody tr td[data-txt="다운로드"] {
  text-align: center;
  text-align-last: center;
  -ms-text-align-last: center;
  -moz-text-align-last: center;
}

@media screen and (max-width: 599px) {
  .TPrdAdminPage
    .Taxbill
    .PrdTbl
    tbody
    tr
    td[data-txt="다운로드"]
    .PrdTbl-excelbox
    .PrdTbl-down {
    padding-right: 0;
  }
}
